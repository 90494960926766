import Vue from "vue";
import VueRouter from "vue-router";
import login from "../views/login";
import home from "../views/home";
import order from "../components/main/order";
import community from "../components/main/community";
import tele from "../components/main/tele";
import users from "../components/main/users";
import roles from "../components/main/roles";
import authority from "../components/main/authority";
import project from "../components/main/project"
import projectItem from "../components/main/projectItem"
import owner from "../components/main/owner"
import welcome from "../components/main/welcome";
import { Message } from "element-ui";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "login",
		component: login,
	},
	{
		path: "/home",
		name: "home",
		component: home,
		redirect: "/home/welcome",
		children: [
			{
				name: "welcome",
				path: "welcome",
				component: welcome,
			},
			{
				name: "order",
				path: "order",
				component: order,
			},
			{
				name: "community",
				path: "community",
				component: community,
			},
			{
				name: "tele",
				path: "tele",
				component: tele,
			},
			{
				name: "users",
				path: "users",
				component: users,
			},
			{
				name: "roles",
				path: "roles",
				component: roles,
			},
			{
				name: "authority",
				path: "authority",
				component: authority,
			},
			{
				name: "project",
				path: "project",
				component: project,
			},
			{
				name: "projectItem",
				path: "projectItem",
				component: projectItem,
			},
			{
				name: "owner",
				path: "owner",
				component: owner,
			}
		],
	},
	{
		path: "*",
		redirect: "/login",
	},
];

// const routes = [
// 	{
// 		path: "/",
// 		name: "Home",
// 		component: Home,
// 	},
// 	{
// 		path: "/about",
// 		name: "About",
// 		// route level code-splitting
// 		// this generates a separate chunk (about.[hash].js) for this route
// 		// which is lazy-loaded when the route is visited.
// 		component: () =>
// 			import(/* webpackChunkName: "about" */ "../views/About.vue"),
// 	},
// ];

const router = new VueRouter({
	mode: "hash",
	base: process.env.BASE_URL,
	routes,
});

// 全局导航守卫
router.beforeEach((to, from, next) => {
	if (to.path !== "/") {
		if (!window.localStorage.getItem("user")) {
			Message.error({ message: "登录状态过期,请重新登录" });
			return next("/");
		} else {
			return next();
		}
	} else {
		return next();
	}
});

export default router;
