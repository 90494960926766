<template>
	<div class="container">
		<el-aside>
			<div class="logo-wrap">
				<img src="../../assets/img/logo2.png" alt="" class="logo" />
			</div>
			<el-menu
				:default-active="menuActive"
				class="el-menu"
				background-color="#001529"
				text-color="#ccc"
				active-text-color="#ffee00"
				@select="menuClick"
				router
			>
				<el-menu-item
					v-for="item of menu"
					:key="item.id"
					:index="item.component"
				>
					<i :class="item.iconCls"></i>
					<span slot="title">{{ item.name }}</span>
				</el-menu-item>
				<!-- <el-menu-item index="community">
					<i class="el-icon-menu"></i>
					<span slot="title">社区管理</span>
				</el-menu-item>
				<el-menu-item index="tele">
					<i class="el-icon-phone"></i>
					<span slot="title">电话管理</span>
				</el-menu-item>
				<el-menu-item index="users">
					<i class="el-icon-user-solid"></i>
					<span slot="title">用户管理</span>
				</el-menu-item>
				<el-menu-item index="roles">
					<i class="el-icon-s-cooperation"></i>
					<span slot="title">运营人员管理</span>
				</el-menu-item>
				<el-menu-item index="authority">
					<i class="el-icon-setting"></i>
					<span slot="title">权限管理</span>
				</el-menu-item> -->
			</el-menu>
		</el-aside>
	</div>
</template>

<script>
export default {
	props: {},

	data() {
		return {
			menuActive: "order",
			menu: [],
		};
	},

	computed: {},

	methods: {
		menuClick(a, b) {
			this.$emit("menu-change", a);
		},
	},

	created() {
		this.axios.get("/system/config/menu").then((r) => {
			this.menu = r.data.data[0].children;
		});

		this.menuActive = this.$route.name;
		this.$emit("menu-change", this.$route.name);
	},

	mounted() {},

	destroyed() {},
};
</script>

<style scoped>
.container {
	background-color: #001529;
	width: 13%;
}

.logo-wrap {
	width: 100%;
	height: 65px;
	background-color: #03407c;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.logo {
	width: 189px;
	height: 41px;
}

.el-menu {
	border-right-width: 0;
}

.el-aside {
	width: 100% !important;
	background-color: #001529;
	overflow: hidden;
}
</style>
