<template>
	<div class="container">
		<el-header>
			<div class="user">
				<img src="../../assets/img/avator.png" class="avatar" />
				<span class="username" @click="logout">{{
					user.username
				}}</span>
			</div>
		</el-header>
		<div class="page-title">{{ title }}</div>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: "标题",
		},

		user: {
			type: Object,
		},
	},

	data() {
		return {};
	},

	computed: {},

	methods: {
		logout() {
			let that = this;
			this.$alert(`该操作将退出登录，确定吗？`, {
				showCancelButton: true,
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				callback: (action) => {
					if (action === "confirm") {
						localStorage.clear();
						this.$router.replace("/");
						this.$message.success("退出登录成功");
						this.axios.post("logout").then((r) => {});
					} else {
					}
				},
			});
		},
	},

	created() {},

	mounted() {},

	destroyed() {},
};
</script>

<style scoped>
.el-header {
	display: flex;
	border-bottom: 1px solid #eee;
	align-items: center;
	justify-content: flex-end;
}

.user {
	display: flex;
	align-items: center;
}

.avatar {
	width: 30px;
	height: 30px;
	border-radius: 500px;
	margin-right: 10px;
}

.page-title {
	padding: 8px 20px;
	font-size: 20px;
	font-weight: bold;
}
</style>
