<template>
	<div
		class="container"
		v-loading="tableLoading"
		element-loading-text="请稍等"
		element-loading-background="rgba(0, 0, 0, 0.5)"
	>
		<!-- query bar -->
		<div class="table-query-bar">
			<el-input
				v-model="queryOpts.ordernum"
				placeholder="请输入工单编号"
				size="mini"
			></el-input>
			<el-input
				v-model="queryOpts.phone"
				type="number"
				placeholder="请输入手机号码"
				size="mini"
			></el-input>
			<el-select
				v-model="queryOpts.ordertype"
				clearable
				placeholder="请选择工单类型"
				size="mini"
			>
				<el-option label="留言工单" :value="0"></el-option>
				<el-option label="电话工单" :value="1"></el-option>
			</el-select>
			<el-select
				v-model="queryOpts.mattertype"
				clearable
				placeholder="请选择问题类型"
				size="mini"
			>
				<el-option
					v-for="item of mattertype"
					:key="item.id"
					:label="item.name"
					:value="item.id"
				></el-option>
			</el-select>
			<el-cascader
				v-model="queryOpts.city"
				:options="citylist"
				placeholder="请选择省市"
				@change="queryCityChange"
				size="mini"
				:props="{ expandTrigger: 'hover' }"
			></el-cascader>
			<el-select
				v-model="queryOpts.community"
				clearable
				placeholder="请选择所在小区"
				size="mini"
			>
				<el-option
					v-for="item of communitylist"
					:key="item.id"
					:label="item.name"
					:value="item.name"
				></el-option>
			</el-select>
			<el-select
				v-model="queryOpts.status"
				clearable
				placeholder="请选择工单状态"
				size="mini"
			>
				<el-option label="待处理" value="0"></el-option>
				<el-option label="已完成" value="1"></el-option>
				<el-option label="已评价" value="2"></el-option>
				<el-option label="已取消" value="3"></el-option>
			</el-select>
			<el-select
				v-model="queryOpts.processman"
				clearable
				placeholder="请选择处理人"
				size="mini"
			>
				<el-option
					v-for="item of processmanlist"
					:key="item.id"
					:label="item.name"
					:value="item.name"
				></el-option>
			</el-select>

			<div class="e-date-wrap">
				<span class="e-date-label">提交时间</span>
				<el-date-picker
					v-model="queryOpts.asktime"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					size="mini"
				></el-date-picker>
			</div>
			<div class="e-date-wrap">
				<span class="e-date-label">回复时间</span>
				<el-date-picker
					v-model="queryOpts.replytime"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					size="mini"
				></el-date-picker>
			</div>
			<div class="query-btn-wrap">
				<el-button type="primary" size="mini" @click="query">
					查询
				</el-button>
				<el-button size="mini" @click="clearQuery">重置</el-button>
				<el-button
					type="primary"
					size="mini"
					@click="teleDialogShow = true"
				>
					创建电话工单
				</el-button>
			</div>
		</div>

		<!-- table -->
		<div class="table-wrap">
			<el-table :data="tableData" border stripe style="width: 99%">
				<el-table-column
					prop="id"
					label="工单编号"
					width="80"
				></el-table-column>
				<el-table-column prop="type" label="工单类型" width="92">
					<template slot-scope="scope">
						<el-tag
							effect="dark"
							type="success"
							v-if="scope.row.type === 0"
						>
							留言工单
						</el-tag>
						<el-tag effect="dark" v-if="scope.row.type === 1">
							电话工单
						</el-tag>
					</template>
				</el-table-column>
				<el-table-column
					prop="consultType"
					label="问题类型"
					width="80"
				></el-table-column>
				<el-table-column
					prop="userName"
					label="用户昵称"
					width="80"
				></el-table-column>
				<el-table-column
					prop="userPhone"
					label="手机号码"
					width="80"
				></el-table-column>
				<el-table-column
					prop="city"
					label="所在城市"
					width="80"
				></el-table-column>
				<el-table-column
					prop="communityName"
					label="所在小区"
					width="80"
				></el-table-column>
				<el-table-column prop="consultTime" label="提交时间">
					<template slot-scope="scope">
						<span>{{ scope.row.consultTime | timeformat }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="status" label="工单状态" width="80">
					<template slot-scope="scope">
						<el-tag type="success" v-if="scope.row.status == 1">
							已完成
						</el-tag>
						<el-tag type="info" v-if="scope.row.status == 3">
							已取消
						</el-tag>
						<el-tag type="danger" v-if="scope.row.status == 0">
							待处理
						</el-tag>
						<el-tag
							type="info"
							effect="dark"
							v-if="scope.row.status == 2"
						>
							已评价
						</el-tag>
					</template>
				</el-table-column>
				<el-table-column
					prop="operatorName"
					label="处理人"
					width="80"
				></el-table-column>
				<el-table-column prop="replyTime" label="回复时间">
					<template slot-scope="scope">
						<span>{{ scope.row.replyTime | timeformat }}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" fixed="right">
					<template slot-scope="scope">
						<el-button
							size="mini"
							@click="rowEdit(scope.$index, scope.row)"
						>
							编辑
						</el-button>
						<el-button
							size="mini"
							type="danger"
							@click="rowDelete(scope.$index, scope.row)"
						>
							删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="table-pagination">
			<el-pagination
				@size-change="tableSizeChange"
				@current-change="tableCrtPageChange"
				:current-page="pageNum"
				:page-sizes="[10, 20, 30, 40]"
				:page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="totalSize"
			></el-pagination>
		</div>

		<!-- order dialog -->
		<el-dialog
			title="工单详情"
			:visible.sync="orderDialogShow"
			:close-on-press-escape="false"
			:close-on-click-modal="false"
		>
			<div class="order-read-only">
				<div class="or-wrap-left">
					<div class="or-label-wrap">
						<span class="or-label-text">工单类型：</span>
						<div class="or-label-content">
							<el-tag
								effect="dark"
								type="success"
								v-if="orderDialog.type === 0"
							>
								留言工单
							</el-tag>
							<el-tag effect="dark" v-if="orderDialog.type === 1">
								电话工单
							</el-tag>
						</div>
					</div>
					<div class="or-label-wrap">
						<span class="or-label-text">用户昵称：</span>
						<div class="or-label-content">
							{{ orderDialog.userPhone }}
						</div>
					</div>
					<div class="or-label-wrap">
						<span class="or-label-text">所在城市：</span>
						<div class="or-label-content">
							{{ orderDialog.province + orderDialog.city }}
						</div>
					</div>
					<div class="or-label-wrap">
						<span class="or-label-text">工单状态：</span>
						<div class="or-label-content">
							<el-tag
								type="danger"
								v-if="orderDialog.status == 0"
							>
								待处理
							</el-tag>
							<el-tag
								type="success"
								v-if="orderDialog.status == 1"
							>
								已完成
							</el-tag>
							<el-tag
								type="info"
								effect="dark"
								v-if="orderDialog.status == 2"
							>
								已评价
							</el-tag>
							<el-tag type="info" v-if="orderDialog.status == 3">
								已取消
							</el-tag>
						</div>
					</div>
				</div>

				<div class="or-wrap-right">
					<div class="or-label-wrap">
						<span class="or-label-text">问题类型：</span>
						<div class="or-label-content">
							{{ orderDialog.consultCategory }}
						</div>
					</div>
					<div class="or-label-wrap">
						<span class="or-label-text">手机号码：</span>
						<div class="or-label-content">
							{{ orderDialog.userPhone }}
						</div>
					</div>
					<div class="or-label-wrap">
						<span class="or-label-text">所在小区：</span>
						<div class="or-label-content">
							{{ orderDialog.communityName }}
						</div>
					</div>
					<div class="or-label-wrap">
						<span class="or-label-text">提交时间：</span>
						<div class="or-label-content">
							{{ orderDialog.consultTime | timeformat }}
						</div>
					</div>
				</div>

				<div class="edit-bar">
					<span class="edit-bar-label">咨询问题：</span>
					<el-input
						type="textarea"
						:rows="4"
						placeholder="请输入内容"
						v-model="orderDialog.question"
						readonly
					></el-input>
				</div>

				<div
					class="askimg-bar"
					v-if="
						orderDialog.materials &&
							orderDialog.materials.length !== 0
					"
				>
					<el-image
						style="width: 100px; height: 100px"
						v-for="item of orderDialog.materials"
						:key="item.id"
						:src="item.path"
						:preview-src-list="[item.path]"
						lazy
					></el-image>
				</div>

				<div class="des-bar" v-if="orderDialog.status !== 3">
					<div class="des-bar-left">
						<span class="des-bar-label">回复律师：</span>
						<span class="des-bar-content">{{
							orderDialog.operatorName
						}}</span>
					</div>
					<div class="des-bar-right">
						<span class="des-bar-label">回复时间：</span>
						<span class="des-bar-content">
							{{ orderDialog.replyTime | timeformat }}
						</span>
					</div>
				</div>

				<div class="edit-bar" v-if="orderDialog.status !== 3">
					<span class="edit-bar-label">回复内容：</span>
					<el-input
						type="textarea"
						:rows="4"
						placeholder="请输入内容"
						v-model="orderDialog.reply"
					></el-input>
				</div>

				<div class="des-bar" v-if="orderDialog.status === 2">
					<div class="des-bar-left">
						<span class="des-bar-label">评价星级：</span>
						<span class="des-bar-content">
							<el-rate v-model="orderDialog.starts"></el-rate>
						</span>
					</div>
					<div class="des-bar-right">
						<span class="des-bar-label">评价时间：</span>
						<span class="des-bar-content">
							{{ orderDialog.evaluationTime | timeformat }}
						</span>
					</div>
				</div>

				<div class="edit-bar" v-if="orderDialog.status === 2">
					<span class="edit-bar-label">评价内容：</span>
					<el-input
						type="textarea"
						:rows="3"
						placeholder="请输入内容"
						v-model="orderDialog.evaluation"
					></el-input>
				</div>
			</div>

			<div slot="footer" class="dialog-footer">
				<el-button @click="orderDialogShow = false" size="medium">
					取消
				</el-button>
				<el-button
					type="primary"
					@click="modifyOrder"
					size="medium"
					v-if="orderDialog.status !== 3"
				>
					保存修改
				</el-button>
			</div>
		</el-dialog>

		<!-- tele dialog -->
		<el-dialog
			title="创建电话工单"
			:visible.sync="teleDialogShow"
			:close-on-press-escape="false"
			:close-on-click-modal="false"
		>
			<div class="tel-dialog-body">
				<el-form>
					<el-form-item label="问题类型">
						<el-select
							v-model="teleDialog.consultCategory"
							clearable
							placeholder="请选择问题类型"
							size="medium"
						>
							<el-option
								v-for="item of mattertype"
								:key="item.id"
								:label="item.name"
								:value="item.id"
							></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="工单状态">
						<el-select
							v-model="teleDialog.status"
							clearable
							placeholder="请选择工单状态"
							size="medium"
						>
							<el-option label="待处理" value="0"></el-option>
							<el-option label="已完成" value="1"></el-option>
							<el-option label="已评价" value="2"></el-option>
							<el-option label="已取消" value="3"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="用户姓名">
						<el-input
							v-loading="userLoading"
							v-model="teleDialog.nickname"
						></el-input>
					</el-form-item>
					<el-form-item label="手机号码">
						<el-input
							v-model="teleDialog.userPhone"
							type="number"
							@input="searchUserName"
						></el-input>
					</el-form-item>
					<el-form-item label="所在城市">
						<el-cascader
							v-model="teleDialog.cityCode"
							:options="citylist"
							placeholder="请选择省市"
							@change="crtCityChange"
							:props="{ expandTrigger: 'hover' }"
						></el-cascader>
					</el-form-item>
					<el-form-item label="所在小区">
						<el-select
							v-model="teleDialog.communityId"
							clearable
							placeholder="请选择所在小区"
							size="medium"
						>
							<el-option
								v-for="item of variableCommunitylist"
								:key="item.id"
								:label="item.name"
								:value="item.id"
							></el-option>
						</el-select>
					</el-form-item>
					<div class="tel-dialog-textarea">
						<span class="tel-dia-label">咨询问题</span>
						<el-input
							type="textarea"
							:rows="4"
							placeholder="请输入内容"
							v-model="teleDialog.question"
						></el-input>
					</div>
					<div class="tel-dialog-textarea">
						<span class="tel-dia-label">回复内容</span>
						<el-input
							type="textarea"
							:rows="4"
							placeholder="请输入内容"
							v-model="teleDialog.reply"
						></el-input>
					</div>
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="teleDialogShow = false" size="medium">
					取消
				</el-button>
				<el-button
					type="primary"
					@click="createTeleOrder"
					size="medium"
				>
					立即创建
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import areas from "../../assets/area";

export default {
	props: {},

	data() {
		return {
			tableLoading: false,
			userLoading: false,
			orderDialogShow: false,
			teleDialogShow: false,

			pickerOptions: {
				shortcuts: [
					{
						text: "最近一周",
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(
								start.getTime() - 3600 * 1000 * 24 * 7
							);
							picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近一个月",
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(
								start.getTime() - 3600 * 1000 * 24 * 30
							);
							picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近三个月",
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(
								start.getTime() - 3600 * 1000 * 24 * 90
							);
							picker.$emit("pick", [start, end]);
						},
					},
				],
			},

			// table
			tableData: [],

			// query preset
			citylist: [],
			communitylist: [],
			processmanlist: [],
			mattertype: [],
			variableCommunitylist: [],

			// query bar
			queryOpts: {
				ordernum: null,
				phone: null,
				ordertype: null,
				mattertype: null,
				city: null,
				community: null,
				status: null,
				processman: null,
				asktime: null,
				replytime: null,
			},

			// order dialog
			orderDialog: {},

			// tele dialog
			teleDialog: {
				consultCategory: null,
				communityId: null,
				status: "1",
				nickname: null,
				userPhone: null,
				cityCode: null,
				question: null,
				reply: null,
				userId: null,
			},

			// pagination
			pageNum: 1,
			pageSize: 10,
			totalSize: 0,

			user: getItem("user"),
		};
	},

	computed: {},

	methods: {
		// query present
		initCityList() {
			let area = [];
			for (let [key, value] of Object.entries(areas.province_list)) {
				let child = [];

				area.push({
					value: key,
					label: value,
					children: child,
				});

				for (let [innerKey, innerValue] of Object.entries(
					areas.city_list
				)) {
					if (innerKey.substring(0, 2) === key.substring(0, 2)) {
						child.push({
							value: innerKey,
							label: innerValue,
						});
					}
				}
			}
			this.citylist = area;
		},

		// query event
		crtCityChange(e) {
			let cityCode = e[1].substring(0, 4);
			cityCode = parseInt(cityCode);
			this.axios
				.get("community/getCommunityListByCity", {
					params: {
						cityCode,
					},
				})
				.then((r) => {
					this.variableCommunitylist = r.data.data;
				});
		},

		queryCityChange(e) {
			this.communitylist = [];
			let params = {
				cityCode: e[1].substring(0, 4),
			};
			this.axios
				.get("community/getCommunitiesByCityCode", { params })
				.then((r) => {
					console.log(r.data);
					this.communitylist = r.data.data;
				});
		},

		query() {
			let opts = this.queryOpts;
			let params = {
				adminUserId: this.user.id,
				position: this.user.position,
				pageNum: 1,
				pageSize: this.pageSize,
				id: opts.ordernum,
				userPhone: opts.phone,
				type: opts.ordertype,
				consultCategoryId: opts.mattertype,
				city: opts.city ? opts.city[1].substring(0, 4) : null,
				communityName: opts.community,
				status: opts.status,
				operatorName: opts.processman,
				minConsultTime: opts.asktime ? opts.asktime[0].getTime() : null,
				maxConsultTime: opts.asktime ? opts.asktime[1].getTime() : null,
				minReplyTime: opts.replytime
					? opts.replytime[0].getTime()
					: null,
				maxReplyTime: opts.replytime
					? opts.replytime[1].getTime()
					: null,
			};
			console.log(params)

			this.tableLoading = true;
			this.axios
				.get("consultForm/consultForms", {
					params,
				})
				.then((r) => {
					if (r.data.code === 0) {
						this.tableData = r.data.rows;
						this.pageNum = 1;
						this.totalSize = r.data.total;
					}
					this.tableLoading = false;
				});
		},

		clearQuery() {
			for (let key of Object.keys(this.queryOpts)) {
				this.queryOpts[key] = null;
			}
		},

		// table event
		tableSizeChange(e) {
			this.pageNum = 1;
			this.pageSize = e;
			this.getTableData();
		},

		tableCrtPageChange(page) {
			this.pageNum = page;
			this.getTableData();
		},

		rowEdit(index, row) {
			this.axios
				.get("consultForm/consultFormsDetails", {
					params: {
						id: row.id,
					},
				})
				.then((r) => {
					this.orderDialog = r.data.data;
					this.orderDialogShow = true;
				});
		},

		rowDelete(index, row) {
			// console.log(index, row);
			this.$alert(`删除编号为${row.id}的工单，确定吗？`, {
				showCancelButton: true,
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				callback: (action) => {
					if (action === "confirm") {
						this.axios
							.post("consultForm/deleteConsultForm", {
								id: row.id,
							})
							.then((r) => {
								this.$message.success("删除成功");
								this.getTableData();
							});
					} else {
					}
				},
			});
		},

		// dialog
		searchUserName() {
			this.teleDialog.userId = null;

			if (this.teleDialog.userPhone.length !== 11) {
				return;
			}

			this.teleDialog.nickname = null;

			this.userLoading = true;
			this.axios
				.get("user/getUserByPhone", {
					params: {
						phone: this.teleDialog.userPhone,
					},
				})
				.then((r) => {
					this.teleDialog.nickname = r.data.data.nickname;
					this.teleDialog.userId = r.data.data.id;
					this.userLoading = false;
				})
				.catch((err) => {
					this.userLoading = false;
				});
		},

		modifyOrder() {
			this.axios
				.post("consultForm/updateConsultForm", {
					lawyerId: this.user.id,
					id: this.orderDialog.id,
					reply: this.orderDialog.reply,
					evaluation: this.orderDialog.evaluation,
					starts: this.orderDialog.starts,
				})
				.then((r) => {
					// console.log(r.data);
					if (r.data.code === 0) {
						this.$message.success("提交成功");
						this.orderDialogShow = false;
						this.getTableData();
					}
				});
		},

		createTeleOrder() {
			let d = this.teleDialog;

			let params = {
				lawyerId: this.user.id,
				userPhone: d.userPhone,
				consultTypeId: d.consultCategory,
				communityId: d.communityId,
				userId: d.userId,
				nickname: d.nickname,
				status: d.status,
				question: d.question,
				reply: d.reply,
			};

			if (!d.userId) {
				delete params.userId;
			} else {
				delete params.nickname;
			}

			this.axios.post("consultForm/consultForm", params).then((r) => {
				if (r.data.code === 0) {
					this.$message.success("成功创建电话工单");
					this.teleDialogShow = false;
					this.getTableData();
				}
			});
		},

		// table
		getTableData() {
			let opts = this.queryOpts;
			let params = {
				adminUserId: this.user.id,
				pageNum: this.pageNum,
				pageSize: this.pageSize,
				id: opts.ordernum,
				type: opts.ordertype,
				consultCategoryId: opts.mattertype,
				userPhone: opts.phone,
				cityCode: opts.city ? opts.city[1].substring(0, 4) : null,
				communityName: opts.community,
				status: opts.status,
				operatorName: opts.processman,
				minConsultTime: opts.asktime ? opts.asktime[0].getTime() : null,
				maxConsultTime: opts.asktime ? opts.asktime[1].getTime() : null,
				minReplyTime: opts.replytime
					? opts.replytime[0].getTime()
					: null,
				maxReplyTime: opts.replytime
					? opts.replytime[1].getTime()
					: null,
			};

			this.tableLoading = true;

			this.axios
				.get("consultForm/consultForms", {
					params,
				})
				.then((r) => {
					if (r.data.code === 0) {
						this.tableData = r.data.rows;
						this.totalSize = r.data.total;
					}
					this.tableLoading = false;
				});
		},

		getProcessMen() {
			this.axios.get("adminUsers/lawyers").then((r) => {
				this.processmanlist = r.data.data;
			});
		},

		// getCommunity() {
		// 	this.axios.get("community/getCommunities").then((r) => {
		// 		this.communitylist = r.data.data;
		// 	});
		// },

		getMattertype() {
			this.axios.get("getCategories").then((r) => {
				this.mattertype = r.data.data;
			});
		},
	},

	created() {
		this.getTableData();
		this.getProcessMen();
		// this.getCommunity();
		this.getMattertype();

		this.initCityList();
	},

	mounted() {},

	destroyed() {},
};
</script>

<style scoped>
.container {
	width: 100%;
	position: relative;
	background-color: #fff;
	padding: 20px;
}

.table-query-bar {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.table-query-bar .el-input,
.table-query-bar .el-select,
.table-query-bar .el-cascader {
	width: 140px;
	margin-bottom: 20px;
	margin-right: 20px;
}

.e-date-wrap {
	margin-bottom: 20px;
	margin-right: 20px;
}

.e-date-label {
	font-size: 14px;
	color: #00567e;
	margin-right: 10px;
}

.query-btn-wrap {
	margin-bottom: 20px;
}

/* table */
.table-wrap {
	max-width: 100%;
}

.table-pagination {
	display: flex;
	justify-content: center;
	width: 100%;
	padding: 30px 0;
}

/* order dialog */
/deep/.el-dialog__body {
	padding: 10px 20px;
}

.order-read-only {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.or-wrap-left,
.or-wrap-right {
	width: 350px;
}

.or-label-wrap {
	display: flex;
	align-items: center;
	margin-bottom: 15px;
}

.or-label-content {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 20px;
}

.edit-bar {
	width: 100%;
	display: flex;
	margin-bottom: 15px;
}

.edit-bar-label {
	min-width: 70px;
	margin-right: 20px;
}

.askimg-bar {
	width: 100%;
	padding: 0 90px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.el-image {
	margin-right: 15px;
	margin-bottom: 15px;
}

.des-bar {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	height: 40px;
}

.des-bar-left {
	display: flex;
	align-items: center;
}

.des-bar-label {
	margin-right: 20px;
}

.des-bar-content {
	color: #ff5c5c;
}

/* tele dialog */
.tel-dialog-body {
	width: 100%;
	height: 100%;
}

.tel-dialog-body .el-form {
	display: flex;
	flex-wrap: wrap;
}

.tel-dialog-body .el-form-item {
	width: 50%;
	display: flex;
	/* justify-content: center; */
	padding-left: 40px;
}

.tel-dialog-body .el-select {
	max-width: 202px;
}

.tel-dialog-textarea {
	display: flex;
	width: 100%;
	padding-left: 40px;
	padding-right: 30px;
	margin-bottom: 20px;
}

.tel-dia-label {
	min-width: 68px;
}

.tel-dialog-textarea .el-textarea {
	max-width: 700px;
}
</style>
