/* 导出excel */
export function exportXls (content, name, callback) {
  let data = new Blob([content], {type: 'application/vnd.ms-excel'})
  let downloadUrl = window.URL.createObjectURL(data)
  let anchor = document.createElement('a')
  anchor.target = '_blank'
  anchor.href = downloadUrl
  anchor.download = name
  /*
  anchor.click()
  window.URL.revokeObjectURL(data)
  */
  // 兼容火狐写法
  document.body.appendChild(anchor)
  var evt = document.createEvent('MouseEvents')
  evt.initEvent('click', false, false)
  anchor.dispatchEvent(evt)
  document.body.removeChild(anchor)
  if (callback) {
    callback()
  }
}
/* 导出xml */
export function exportXml (content, name, callback) {
  let data = new Blob([content], {type: 'text/xml'})
  let downloadUrl = window.URL.createObjectURL(data)
  let anchor = document.createElement('a')
  anchor.target = '_blank'
  anchor.href = downloadUrl
  anchor.download = name
  /*
  anchor.click()
  window.URL.revokeObjectURL(data)
  */
  // 兼容火狐写法
  document.body.appendChild(anchor)
  var evt = document.createEvent('MouseEvents')
  evt.initEvent('click', false, false)
  anchor.dispatchEvent(evt)
  document.body.removeChild(anchor)
  if (callback) {
    callback()
  }
}
