<template>
	<div
		class="container"
		v-loading="tableLoading"
		element-loading-text="请稍等"
		element-loading-background="rgba(0, 0, 0, 0.5)"
	>
		<!-- query bar -->
		<div class="table-query-bar">
			<el-input
				placeholder="请输入电话编号"
				size="mini"
				v-model="queryBar.phoneId"
				type="number"
			></el-input>
			<el-input
				placeholder="请输入电话号码"
				size="mini"
				v-model="queryBar.phonenum"
				type="number"
			></el-input>
			<el-select
				clearable
				placeholder="请选择小区"
				size="mini"
				v-model="queryBar.communityId"
			>
				<el-option
					v-for="item of communitylist"
					:key="item.id"
					:label="item.name"
					:value="item.id"
				></el-option>
			</el-select>
			<el-select
				clearable
				placeholder="请选择问题分类"
				size="mini"
				v-model="queryBar.matterId"
			>
				<el-option
					v-for="item of mattertype"
					:key="item.id"
					:label="item.name"
					:value="item.id"
				></el-option>
			</el-select>
			<el-select
				clearable
				placeholder="请选择号码状态"
				size="mini"
				v-model="queryBar.status"
			>
				<el-option label="正常" :value="1"></el-option>
				<el-option label="失效" :value="0"></el-option>
			</el-select>
			<div class="query-btn-wrap">
				<el-button type="primary" size="mini" @click="query"
					>查询</el-button
				>
				<el-button size="mini" @click="clearQuery">重置</el-button>
				<el-button
					type="primary"
					size="mini"
					@click="createDialogShow = true"
					>创建电话</el-button
				>
			</div>
		</div>

		<!-- table -->
		<div class="table-wrap">
			<el-table :data="tableData" border style="width: 99%">
				<el-table-column
					prop="id"
					label="电话编号"
					width="80"
				></el-table-column>
				<el-table-column
					prop="telphone"
					label="电话号码"
				></el-table-column>
				<el-table-column prop="community" label="绑定小区">
					<template slot-scope="s">
						<el-tag
							v-for="item of s.row.communities"
							:key="item.id"
							size="small"
							effect="dark"
							type="danger"
							>{{ item.name }}</el-tag
						>
					</template>
				</el-table-column>
				<el-table-column prop="category" label="问题分类">
					<template slot-scope="s">
						<el-tag
							v-for="item of s.row.consultCategories"
							:key="item.id"
							size="mini"
							>{{ item.name }}</el-tag
						>
					</template>
				</el-table-column>
				<el-table-column prop="status" label="号码状态">
					<template slot-scope="scope">
						<el-tag type="success" v-if="scope.row.status == 1">
							正常
						</el-tag>
						<el-tag type="danger" v-if="scope.row.status == 0">
							失效
						</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="createTime" label="创建时间">
					<template slot-scope="s">
						<span>{{ s.row.createTime | timeformat }}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button
							size="mini"
							@click="rowEdit(scope.$index, scope.row)"
						>
							编辑
						</el-button>
						<el-button
							size="mini"
							type="danger"
							@click="rowDelete(scope.$index, scope.row)"
						>
							删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="table-pagination">
			<el-pagination
				@size-change="tableSizeChange"
				@current-change="tableCrtPageChange"
				:current-page="pageNum"
				:page-sizes="[10, 20, 30, 40]"
				:page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="totalSize"
			></el-pagination>
		</div>

		<!-- edit dialog -->
		<el-dialog title="编辑电话" :visible.sync="editDialogShow" width="30%">
			<div class="dialog-body">
				<el-form label-width="100px" :model="editDialog">
					<el-form-item label="电话号码">
						<el-input v-model="editDialog.phonenumber"></el-input>
					</el-form-item>
					<el-form-item label="绑定小区">
						<el-select
							v-model="editDialog.communitylist"
							multiple
							placeholder="请选择小区"
						>
							<el-option
								v-for="item in communitylist"
								:key="item.id"
								:label="item.name"
								:value="item.id"
							>
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="问题分类">
						<el-select
							v-model="editDialog.categorylist"
							multiple
							placeholder="请选择问题分类"
						>
							<el-option
								v-for="item in mattertype"
								:key="item.id"
								:label="item.name"
								:value="item.id"
							>
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="号码状态">
						<el-select
							v-model="editDialog.status"
							placeholder="请选择号码状态"
						>
							<el-option label="正常" :value="1"></el-option>
							<el-option label="失效" :value="0"></el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="editDialogShow = false">取消</el-button>
				<el-button type="primary" @click="submitEdit">确定</el-button>
			</span>
		</el-dialog>

		<!-- create dialog -->
		<el-dialog
			title="创建电话"
			:visible.sync="createDialogShow"
			width="30%"
		>
			<div class="dialog-body">
				<el-form label-width="100px" :model="createDialog">
					<el-form-item label="电话号码">
						<el-input v-model="createDialog.phonenumber"></el-input>
					</el-form-item>
					<el-form-item label="绑定小区">
						<el-select
							v-model="createDialog.communitylist"
							multiple
							placeholder="请选择小区"
						>
							<el-option
								v-for="item in communitylist"
								:key="item.id"
								:label="item.name"
								:value="item.id"
							>
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="问题分类">
						<el-select
							v-model="createDialog.categorylist"
							multiple
							placeholder="请选择问题分类"
						>
							<el-option
								v-for="item in mattertype"
								:key="item.id"
								:label="item.name"
								:value="item.id"
							>
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="号码状态">
						<el-select
							v-model="createDialog.status"
							placeholder="请选择号码状态"
						>
							<el-option label="正常" :value="1"></el-option>
							<el-option label="失效" :value="0"></el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="createDialogShow = false">取消</el-button>
				<el-button type="primary" @click="submitCreate">确定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
export default {
	props: {},

	data() {
		return {
			tableLoading: false,
			editDialogShow: false,
			createDialogShow: false,

			// query preset
			communitylist: [],
			mattertype: [],

			// query
			queryBar: {
				phoneId: null,
				phonenum: null,
				communityId: null,
				matterId: null,
				status: null,
			},

			// table
			tableData: [],

			// dialog
			editDialog: {
				phonenumber: null,
				communitylist: [],
				categorylist: [],
				status: null,
			},

			createDialog: {
				phonenumber: null,
				communitylist: [],
				categorylist: [],
				status: 1,
			},

			// pagination
			pageNum: 1,
			pageSize: 10,
			totalSize: 0,

			user: getItem("user"),
		};
	},

	computed: {},

	methods: {
		// query bar
		clearQuery() {
			for (let key of Object.keys(this.queryBar)) {
				this.queryBar[key] = null;
			}
		},

		query() {
			let opts = this.queryBar;
			console.log(opts);
			let params = {
				adminUserId: this.user.id,
				pageNum: 1,
				pageSize: this.pageSize,
				id: opts.phoneId,
				telphone: opts.phonenum,
				cid: opts.communityId,
				tid: opts.matterId,
				status: opts.status,
			};
			console.log("query", params);

			this.tableLoading = true;
			this.axios
				.get("phone/getItems", {
					params,
				})
				.then((r) => {
					console.log(r.data);
					if (r.data.code === 0) {
						this.pageNum = 1;
						this.tableData = r.data.data.list;
						this.totalSize = r.data.data.total;
					}
					this.tableLoading = false;
				});
		},

		// table event
		tableSizeChange(e) {
			this.pageNum = 1;
			this.pageSize = e;
			this.getTableData();
		},

		tableCrtPageChange(page) {
			this.pageNum = page;
			this.getTableData();
		},

		rowEdit(index, row) {
			console.log(row);
			let catelist = [];
			let comlist = [];

			row.communities.forEach((val, index) => {
				comlist.push(val.id);
			});

			row.consultCategories.forEach((v, i) => {
				catelist.push(v.id);
			});

			let d = {
				categorylist: catelist,
				communitylist: comlist,
				phonenumber: row.telphone,
				status: row.status,
				id: row.id,
			};
			this.editDialog = d;
			this.editDialogShow = true;
		},

		rowDelete(index, row) {
			this.$alert(`删除${row.telphone}，确定吗？`, {
				showCancelButton: true,
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				callback: (action) => {
					if (action === "confirm") {
						this.axios
							.post("phone/deleteItems", {
								phone: row.telphone,
							})
							.then((r) => {
								if (r.data.code === 0) {
									this.$message.success("删除成功");
									this.getTableData();
								}
							});
					} else {
					}
				},
			});
		},

		submitEdit() {
			let d = this.editDialog;
			let params = {
				id: d.id,
				status: d.status,
				telphone: d.phonenumber,
				cides: d.communitylist,
				tids: d.categorylist,
			};

			console.log(params);

			this.axios.post("phone/items", params).then((r) => {
				console.log(r.data);
				if (r.data.code === 0) {
					this.getTableData();
					this.$message.success("保存修改成功");
					this.editDialogShow = false;
				}
			});
		},

		submitCreate() {
			let d = this.createDialog;
			let params = {
				status: d.status,
				telphone: d.phonenumber,
				cides: d.communitylist,
				tids: d.categorylist,
			};

			console.log(params);

			this.axios.post("phone/insertItems", params).then((r) => {
				console.log(r.data);
				if (r.data.code === 0) {
					this.getTableData();
					this.$message.success("创建成功");
					this.createDialogShow = false;

					for (let key of Object.keys(this.createDialog)) {
						this.createDialog[key] = null;
                    }
                    this.createDialog.status = 1
				}
			});
		},

		getTableData() {
			let opts = this.queryBar;
			console.log(opts);
			let params = {
				adminUserId: this.user.id,
				pageNum: this.pageNum,
				pageSize: this.pageSize,
				id: opts.phoneId,
				telphone: opts.phonenum,
				cid: opts.communityId,
				tid: opts.matterId,
				status: opts.status,
			};
			console.log("query", params);

			this.tableLoading = true;
			this.axios
				.get("phone/getItems", {
					params,
				})
				.then((r) => {
					console.log(r.data);
					if (r.data.code === 0) {
						this.tableData = r.data.data.list;
						this.totalSize = r.data.data.total;
					}
					this.tableLoading = false;
				});
		},

		getCommunity() {
			this.axios.get("community/getCommunities").then((r) => {
				this.communitylist = r.data.data;
			});
		},

		getMattertype() {
			this.axios.get("getCategories").then((r) => {
				this.mattertype = r.data.data;
			});
		},
	},

	created() {
		this.getTableData();
		this.getCommunity();
		this.getMattertype();
	},

	mounted() {},

	destroyed() {},
};
</script>

<style scoped>
.container {
	width: 100%;
	position: relative;
	background-color: #fff;
	padding: 20px;
}

.table-query-bar {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.table-query-bar .el-input,
.table-query-bar .el-select,
.table-query-bar .el-cascader {
	width: 140px;
	margin-bottom: 20px;
	margin-right: 20px;
}

.e-date-wrap {
	margin-bottom: 20px;
	margin-right: 20px;
}

.e-date-label {
	font-size: 14px;
	color: #00567e;
	margin-right: 10px;
}

.query-btn-wrap {
	margin-bottom: 20px;
}

/* table */
.table-wrap {
	max-width: 100%;
}

.table-wrap .el-tag {
	margin-right: 5px;
	margin-bottom: 5px;
}

.table-pagination {
	display: flex;
	justify-content: center;
	width: 100%;
	padding: 30px 0;
}

/* dialog */
.dialog {
	padding: 0 30px;
}

.dialog-body .el-input,
.dialog-body .el-select {
	width: 100%;
}
</style>
