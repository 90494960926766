const keys = {
  version: '1.0.0'
}

// 项目状态
keys.projectStatus = [
  {
    key: '0',
    value: '未开始'
  },
  {
    key: '1',
    value: '进行中'
  },
  {
    key: '2',
    value: '已完成'
  }
]

// 表决方式
keys.voteMethod = [
  {
    key: '1',
    value: '电话'
  },
  {
    key: '2',
    value: '短信'
  },
  {
    key: '3',
    value: '网络'
  },
  {
    key: '4',
    value: '其他'
  },
  {
    key: '5',
    value: '上门'
  }
]

// 表决状态
keys.voteNoticeStatus = [
  {
    key: '1',
    value: '未发送'
  },
  {
    key: '2',
    value: '已发送'
  },
  {
    key: '3',
    value: '有回复-已识别'
  },
  {
    key: '4',
    value: '有回复-未识别'
  }
]

// 征询方式
keys.consultMethod = [
  {
    key: '1',
    value: '电话'
  },
  {
    key: '2',
    value: '短信'
  },
  {
    key: '3',
    value: '网络'
  },
  {
    key: '4',
    value: '其他'
  },
  {
    key: '5',
    value: '上门'
  }
]

// 业主反馈
keys.consultFeedback = [
  {
    key: '1',
    value: '当场表决'
  },
  {
    key: '2',
    value: '无人接听/通话中/接通即挂'
  },
  {
    key: '3',
    value: '无法接通-空号关机停机'
  },
  {
    key: '4',
    value: '非表决人-其他人非业主'
  },
  {
    key: '5',
    value: '非表决人-租户'
  },
  {
    key: '6',
    value: '延迟表决'
  },
  {
    key: '7',
    value: '拒绝表决'
  },
  {
    key: '8',
    value: '其他'
  },
  {
    key: '100',
    value: '当场表决'
  },
  {
    key: '101',
    value: '无人-空置'
  },
  {
    key: '102',
    value: '无人-外出'
  },
  {
    key: '103',
    value: '非表决人-租户'
  },
  {
    key: '104',
    value: '非表决人-其他人非业主'
  },
  {
    key: '105',
    value: '延迟表决'
  },
  {
    key: '106',
    value: '拒绝表决'
  },
  {
    key: '107',
    value: '其他'
  }
]

// 根据key获取value
keys.getValue = function (arr, key) {
  if (!arr || arr.length < 1 || arr === 'undefined' || arr === null || !key || key === '' || key === 'undefined' || key === null) {
    return ''
  }
  const obj = arr.filter((item) => {
    return item.key.toLowerCase() === key.toLowerCase()
  })
  if (obj.length < 1) {
    return key
  } else {
    return obj[0].value
  }
}

// 根据value获取key
keys.getKey = function (arr, value) {
  if (!arr || arr.length < 1 || arr === 'undefined' || arr === null || !value || value === '' || value === 'undefined' || value === null) {
    return ''
  }
  const obj = arr.filter((item) => {
    return item.value === value
  })
  if (obj.length < 1) {
    return value
  } else {
    return obj[0].key
  }
}
export default keys
