<template>
	<div class="container" v-loading="tableLoading" element-loading-text="请稍等" element-loading-background="rgba(0, 0, 0, 0.5)">
		<div class="warp clearfix">
			<div class="user-info">
				<div class="info-box">
					<div class="avatar">{{projectOwner.unitNumber}}</div>
					<div class="info">
						<div class="name-box">{{projectOwner.name}} <span v-if="projectOwner.voteMethod">{{$keys.getValue($keys.voteMethod, String(projectOwner.voteMethod))}}</span></div>
						<div class="phone">
							<el-tooltip placement="top">
								<div slot="content">点击复制</div>
								<span class="tag-read" :data-clipboard-text="projectOwner.phone" @click="copy">{{projectOwner.phone}}</span>  
							</el-tooltip>
						</div>
					</div>
				</div>
				<ul class="project">
					<li v-for="(item, index) in formFields" :key="index">
						<div class="name-box">
							<div class="name">表决事项 {{index + 1}}</div>
							<div class="time">{{projectOwner.voteTime}}</div>
						</div>
						<div class="title">{{item.fieldName}}</div>
						<div class="resoult">表决结果：{{item.fieldValue ? item.fieldValue : '未表决'}}</div>
					</li>
					<li v-for="(item, index) in formFields2" :key="index + 's'">
						<div class="name-box">
							<div class="name">业委会委员 {{index + 1}}</div>
							<div class="time">{{projectOwner.voteTime}}</div>
						</div>
						<div class="title">{{item.fieldName}}</div>
						<div class="resoult">表决结果：{{item.fieldValue ? item.fieldValue : '未表决'}}</div>
					</li>
				</ul>
			</div>
			<div class="more-info">
				<el-button type="text" class="back" @click="back">返回业主列表</el-button>
				<el-tabs v-model="activeName" @tab-click="handleClick">
					<el-tab-pane label="征询跟进" name="first">
						<el-form :model="form.form" ref="form" :rules="form.rules">
							<el-form-item label="征询方式：" :label-width="formLabelWidth" prop="consultMethod">
								<el-radio-group v-model="form.form.consultMethod">
									<el-radio label="1">电话</el-radio>
									<el-radio label="2">短信</el-radio>
									<el-radio label="3">网络</el-radio>
									<el-radio label="4">其他</el-radio>
								</el-radio-group>
							</el-form-item>
							<el-form-item label="业主反馈：" :label-width="formLabelWidth" prop="consultFeedback">
								<el-radio-group v-model="form.form.consultFeedback">
									<div style="margin-bottom: 10px;">
										<el-radio label="1">当场表决</el-radio>
									</div>
									<div style="margin-bottom: 10px;">
										<el-radio label="2">无人接听/通话中/接通即挂</el-radio>
									</div>
									<div style="margin-bottom: 10px;">
										<el-radio label="3">无法接通-空号关机停机</el-radio>
									</div>
									<div style="margin-bottom: 10px;">
										<el-radio label="4">非表决人-其他人非业主</el-radio>
									</div>
									<div style="margin-bottom: 10px;">
										<el-radio label="5">非表决人-租户</el-radio>
									</div>
									<div style="margin-bottom: 10px;">
										<el-radio label="6">延迟表决</el-radio>
									</div>
									<div style="margin-bottom: 10px;">
										<el-radio label="7">拒绝表决</el-radio>
									</div>
									<div style="margin-bottom: 10px;">
										<el-radio label="8">其他</el-radio>
									</div>
								</el-radio-group>
							</el-form-item>
							<div v-if="form.form.consultFeedback === '1'">
								<div v-for="(item, index) in projectDetail.items2" :key="index">
									<el-form-item :label="'表决事项' + (index + 1) + '：'" :label-width="formLabelWidth">
										<span style="font-weight: bold;">{{item.fieldName}}</span>
									</el-form-item>
									<el-form-item label="表决结果：" :label-width="formLabelWidth">
										<el-radio-group v-model="item.optionCode">
											<el-radio v-for="(i, o) in item.options" :key="o" :label="i.optionName + '-' + i.optionCode">{{i.optionName}}</el-radio>
										</el-radio-group>
									</el-form-item>
								</div>
							</div>
							<div v-if="form.form.consultFeedback === '1'">
								<div v-for="(item, index) in projectDetail.items3" :key="index">
									<el-form-item :label="'业委会委员' + (index + 1) + '：'" :label-width="formLabelWidth">
										<span style="font-weight: bold;">{{item.fieldName}}</span>
									</el-form-item>
									<el-form-item label="表决结果：" :label-width="formLabelWidth">
										<el-radio-group v-model="item.optionCode">
											<el-radio v-for="(i, o) in item.options" :key="o" :label="i.optionName + '-' + i.optionCode">{{i.optionName}}</el-radio>
										</el-radio-group>
									</el-form-item>
								</div>
							</div>
							<el-form-item label="备注：" :label-width="formLabelWidth" prop="remark">
								<el-input size="mini" type="text" maxlength="200" v-model="form.form.remark" placeholder="请输入征询备注"></el-input>
							</el-form-item>
						</el-form>
						<el-row style="display: flex;justify-content: flex-start; margin-top: 20px; margin-left:120px; margin-bottom: 20px;">
							<el-button type="primary" @click="saveData" size="mini">保存</el-button>
						</el-row>
						<el-table :data="tableData" size="mini" ref="userTable" element-loading-text="加载中" border tooltip-effect="dark" style="width: 100%">
							<el-table-column label="征询方式" prop="consultMethod" width="120" align="center">
								<template slot-scope="scope">
									{{$keys.getValue($keys.consultMethod, String(scope.row.consultMethod))}}
								</template>
							</el-table-column>
							<el-table-column prop="consultFeedback" label="征询反馈" min-width="120" align="center">
								<template slot-scope="scope">
									{{$keys.getValue($keys.consultFeedback, String(scope.row.consultFeedback))}}
								</template>
							</el-table-column>
							<el-table-column prop="remark" label="征询备注" min-width="200" align="center">
							</el-table-column>
							<el-table-column prop="consultTime" label="征询时间" min-width="150" align="center">
							</el-table-column>
							<el-table-column prop="optUserName" label="征询人" min-width="150" align="center">
							</el-table-column>
							<el-table-column label="操作" min-width="150" align="center" v-if="user.roles[0].name === 'ROLE_系统管理员'">
								<template slot-scope="scope">
									<el-button size="mini" plain type="danger" @click="deleteData(scope.row)">删除</el-button>
								</template>
							</el-table-column>
						</el-table>
						<div class="table-pagination">
							<el-pagination @size-change="tableSizeChange" @current-change="tableCrtPageChange" :current-page="pageNum" :page-sizes="[5, 10, 15, 20]" :page-size="pageSize"	layout="total, sizes, prev, pager, next, jumper" :total="totalSize"></el-pagination>
						</div>
					</el-tab-pane>
					<el-tab-pane label="业主表决记录" name="second">
						<el-table :data="tableData2" size="mini" ref="userTable" element-loading-text="加载中" border tooltip-effect="dark" style="width: 100%">
							<el-table-column v-for="(item, index) in subList" :key="index" :label="item.name">
								<el-table-column v-for="(i, o) in item.formFields" :key="o" :label="i.fieldName" width="300">
									<template slot-scope="scope">
										<span v-if="!$NUtil.isEmpty(scope.row.subList[index].formFields[o].fieldValue)">{{scope.row.subList[index].formFields[o].fieldValue}}</span>
										<span v-else class="red">未表决</span>
									</template>
								</el-table-column>
							</el-table-column>
							<el-table-column prop="voteMethod" label="表决方式" min-width="150" align="center">
								<template slot-scope="scope">
									<span v-if="scope.row.voteMethod">{{$keys.getValue($keys.voteMethod, String(scope.row.voteMethod))}}</span>
									<span v-else>-</span>
								</template>
							</el-table-column>
							<el-table-column label="表决材料" min-width="150" align="center">
								<template slot-scope="scope">
									<el-button size="mini" type="primary" v-if="scope.row.attachments" @click="lookImg(scope.row)">
										查看
									</el-button>
									<span v-else>-</span>
								</template>
							</el-table-column>
							<el-table-column prop="voteTime" label="表决时间" min-width="150" align="center">
							</el-table-column>
							<el-table-column prop="consultUserName" label="征询人" min-width="150" align="center">
							</el-table-column>
						</el-table>
						<div class="table-pagination">
							<el-pagination @size-change="tableSizeChange2" @current-change="tableCrtPageChange2" :current-page="pageNum2" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize2"	layout="total, sizes, prev, pager, next, jumper" :total="totalSize2"></el-pagination>
						</div>
					</el-tab-pane>
					<el-tab-pane label="短信往来记录" name="third">
						<div class="chat-box">
							<div v-if="messageList.length > 0" v-for="(item, index) in messageList" :key="index">
								<div class="out-side left" v-if="item.type === 'owner'">
									<div class="line-box">
										<div class="avatar-box"><img src="../../assets/img/avator.png"></div>
										<div class="msg-box">
											<div class="name-box">
												<div class="name">{{item.ownerName}}</div>
												<div class="time">{{item.createTime}}</div>
											</div>
											<div class="content">
												{{item.content}}
											</div>
											<div class="tips" v-if="item.signResult === 1">
												<el-alert title="系统已自动识别表决结果为:" type="success" :closable="false" show-icon></el-alert>
												<p v-html="item.resultMsg"></p>
											</div>
											<div class="tips" v-if="item.signResult === 2">
												<el-alert title="系统未能自动识别表决结果" type="error" :closable="false" show-icon></el-alert>
											</div>
										</div>
									</div>
								</div>
								<div class="out-side right" v-if="item.type === 'system' || item.type === 'consult'">
									<div class="line-box">
										<div class="msg-box">
											<div class="name-box">
												<div class="name">{{item.consultUserName}}</div>
												<div class="time">{{item.createTime}}</div>
											</div>
											<div class="content">
												{{item.content}}
											</div>
										</div>
										<div class="avatar-box"><img src="../../assets/img/avator.png"></div>
									</div>
								</div>
							</div>
							<div class="empty" v-if="messageList.length === 0">暂无记录</div>
						</div>
						<div class="choose-box">
							<el-radio-group v-model="radioPhone" @change="getMessageList">
								<el-radio :label="projectOwner.phone">手机号1：{{projectOwner.phone}}</el-radio>
								<el-radio :label="projectOwner.sparePhone" v-if="!$NUtil.isEmpty(projectOwner.sparePhone)">手机号2：{{projectOwner.sparePhone}}</el-radio>
							</el-radio-group>
						</div>
						<el-row style="display: flex;justify-content: flex-start; margin-top: 20px; margin-bottom: 20px;">
							<el-button type="primary" @click="sendMsg" size="mini">发送短信</el-button>
						</el-row>
					</el-tab-pane>
				</el-tabs>
			</div>
		</div>
		<!-- 发送短信弹框 -->
		<el-dialog :visible.sync="dialogForm3.visible" :title="dialogForm3.title">
			<el-form :model="dialogForm3.form" ref="dialogForm3" :rules="dialogForm3.rules">
				<div class="choose-box">
					<el-radio-group v-model="dialogForm3.form.tplCode"  @change="tplCodeChange">
						<el-radio label="info">表决通知短信</el-radio>
						<el-radio label="noinfo">表决结果短信</el-radio>
					</el-radio-group>
				</div>
				<div class="message-box">
					<div class="msg-title">
						短信示例：
					</div>
					<div class="msg-content">
						<textarea v-model="message">
						</textarea>
					</div>
				</div>
				<div class="send-name">
					<div class="name-r">
						<div class="text">发送至：</div>
						<div class="checkbox">
							<el-checkbox-group v-model="dialogForm3.form.send">
								<el-checkbox label="sendPhoneMode">手机号1</el-checkbox>
								<el-checkbox label="sendSparePhoneMode" v-if="!$NUtil.isEmpty(projectOwner.sparePhone)">手机号2</el-checkbox>
							</el-checkbox-group>
						</div>
					</div>
				</div>
			</el-form>
			<div slot="footer" class="dialog-footer">
			<el-button @click="dialogForm3.visible = false">取 消</el-button>
			<el-button type="primary" @click="sendData" :loading="btnLoading">立即发送</el-button>
			</div>
		</el-dialog>
		<!-- 查看图片弹框 -->
		<el-dialog :visible.sync="dialogForm.visible" :title="dialogForm.title">
			<div class="img-box">
				<img :src="dialogForm.form.url">
			</div>
			<div slot="footer" class="dialog-footer">
			<el-button @click="dialogForm.visible = false">关 闭</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import Clipboard from 'clipboard'
export default {
	props: {},

	data() {
		return {
			btnLoading: false,
			user: getItem("user"),
			tableLoading: false,
			activeName: 'first',
			formLabelWidth: '120px',
			form: {
				form: {
					consultMethod: '1',
					consultFeedback: '1',
					remark: '',
					formDataValues: []
				},
				rules: {
				}
			},
			tableData: [],
			tableData2: [],
			id: '', // 业主ID
			projectOwner: '', // 业主信息
			formFields: [], // 表决事项
			formFields2: [],
			projectDetail: {}, // 项目详情
			// pagination
			pageNum: 1,
			pageNum2: 1,
			pageSize: 5,
			pageSize2: 10,
			totalSize: 0,
			totalSize2: 0,
			radioPhone: '',
			subList: [],
			messageList: [],
			dialogForm3: {
				clickDis: true, // 新建按钮多次点击状态
				visible: false, // 显示隐藏
				status: true, // 是新增还是编辑 true:新增，false:编辑
				title: '发送短信',
				form: {
					tplCode: 'info',
					send: ['sendPhoneMode']
				},
				rules: {
					// 表单规则
					tplCode: [
						{ required: true, message: '请选中短信模板', trigger: 'change' }
					],
					send: [
						{ required: true, message: '请选择发送手机号', trigger: 'change' }
					]
				}
			},
			dialogForm: {
				clickDis: true, // 新建按钮多次点击状态
				visible: false, // 显示隐藏
				status: true, // 是新增还是编辑 true:新增，false:编辑
				title: '查看',
				form: {
					url: ''
				}
			},
			tplContent: '',
			message: '',
			data: ''
		};
	},

	computed: {},

	methods: {
		back(){
			this.$router.push({ path: '/home/projectItem', query: { id: this.projectOwner.projectId, data: this.data } })
		},
		copy() {
			var clipboard = new Clipboard('.tag-read')  
            clipboard.on('success', e => {  
				this.$message.success("已复制到剪切板！")
                // 释放内存  
                clipboard.destroy()  
            })  
            clipboard.on('error', e => {  
				// 不支持复制  
				console.log('该浏览器不支持自动复制')  
				// 释放内存  
				clipboard.destroy()  
			})
		},
		tplCodeChange() {
			if (this.dialogForm3.form.tplCode === 'info') {
				this.message = this.tplContent
			} else {
				this.message = ''
			}
		},
		//lookImg
		lookImg(row) {
			this.dialogForm.visible = true
			this.dialogForm.form.url = row.attachments
		},
		// 发送短信
		sendData() {
			this.$refs['dialogForm3'].validate(valid => {
				if (valid) {
					this.btnLoading = true
					let form = this.dialogForm3.form
					let sendPhoneMode = false
					let sendSparePhoneMode = false
					let ownerIds = [this.id]
					this.dialogForm3.form.send.forEach(element => {
						if (element === 'sendPhoneMode') {
							sendPhoneMode = true
						} else {
							sendSparePhoneMode = true
						}
					})
					let data = {
						tplCode: form.tplCode,
						sendPhoneMode: sendPhoneMode,
						tplContent: this.message,
						sendSparePhoneMode: sendSparePhoneMode,
						ownerIds: ownerIds,
						projectOwner: {
							projectId: this.projectOwner.projectId
						}
					}
					// console.log(data)
					this.axios.post("/project/sms/record", data).then((r) => {
						if (r.data.code === 0) {
							this.btnLoading = false
							this.$message.success("发送短信成功！")
							this.dialogForm3.visible = false
							this.getMessageList()
						} else {
							this.$message.warning(r.data.message)
						}
					});
				}
			})
		},
		sendMsg(){
			this.dialogForm3.visible = true
		},
		handleClick (tab, event) {
			if (this.activeName === 'second') {
				this.getVoteList()
			} else if (this.activeName === 'third') {
				this.getMessageList()
			}
		},
		// 获取业主详情
		getOwnerDetil () {
			this.axios.get("/project/owner/detail/" + this.id).then((r) => {
				if (r.data.code === 0) {
					// console.log(r.data.data)
					this.projectOwner = r.data.data.projectOwner
					// this.formFields = r.data.data.formFields
					this.formFields = []
					this.formFields2 = []
					r.data.data.formFields.forEach((item) => {
						if (item.fieldMode === 0) {
							this.formFields.push(item)
						} else {
							this.formFields2.push(item)
						}
					})
					this.getProjectDetail()
					this.radioPhone = this.projectOwner.phone
				} else {
					this.$message.warning(r.data.message)
				}
			})
		},
		// 获取项目详情
		getProjectDetail() {
			this.axios.get("/project/" + this.projectOwner.projectId).then((r) => {
				if (r.data.code === 0) {
					this.projectDetail = r.data.data
					this.tplContent = r.data.data.tplContent
					this.message = r.data.data.tplContent
					this.$set(this.projectDetail, 'items2', [])
					this.$set(this.projectDetail, 'items3', [])
					for (let k = 0; k < r.data.data.items.length; k++) {
						if (r.data.data.items[k].fieldMode === 0) {
							this.projectDetail.items2.push(this.projectDetail.items[k])
						} else {
							this.projectDetail.items3.push(this.projectDetail.items[k])
						}
					}
					// 回显选项
					for (let i = 0; i < this.projectDetail.items2.length; i++) {
						this.$set(this.projectDetail.items2[i], 'optionCode', this.projectDetail.items2[i].options[0].optionName + '-' + this.projectDetail.items2[i].options[0].optionCode)
					}
					for (let j = 0; j < this.projectDetail.items3.length; j++) {
						this.$set(this.projectDetail.items3[j], 'optionCode', this.projectDetail.items3[j].options[0].optionName + '-' + this.projectDetail.items3[j].options[0].optionCode)
					}
					this.getRecordTableData()
				} else {
					this.$message.warning(r.data.message)
				}
			})
		},
		// 获取征询更近列表
		getRecordTableData() {
			let params = {
				ownerId: this.id,
				pageNum: this.pageNum,
				pageSize: this.pageSize
			};
			this.tableLoading = true;
			this.axios.get("/project/consult/record/list", { params }).then((r) => {
				if (r.data.code === 0) {
					this.tableData = r.data.rows;
					this.totalSize = r.data.total
				} else {
					this.$message.warning(r.data.message)
				}
				this.tableLoading = false;
			});
		},
		// 获取业主表决记录
		getVoteList() {
			let params = {
				userId: this.id,
				pageNum: this.pageNum2,
				pageSize: this.pageSize2
			};
			this.tableLoading = true;
			this.axios.get("/project/owner/vote/list", { params }).then((r) => {
				if (r.data.code === 0) {
					this.tableData2 = r.data.rows;
					this.totalSize2 = r.data.total
					this.subList = (this.tableData2[0] && this.tableData2[0]['subList']) || []
				} else {
					this.$message.warning(r.data.message)
				}
				this.tableLoading = false;
			})
		},
		// 获取短信来往记录
		getMessageList() {
			let params = {
				phone: this.radioPhone,
				projectId: this.projectOwner.projectId,
				ownerId: this.id,
				pageNum: 1,
				pageSize: 2000
			};
			this.axios.get("/project/sms/record/list", { params }).then((r) => {
				if (r.data.code === 0) {
					this.messageList = r.data.rows;
				} else {
					this.$message.warning(r.data.message)
				}
			})
		},
		// table event
		tableSizeChange(e) {
			this.pageNum = 1;
			this.pageSize = e;
			this.getRecordTableData();
		},
		tableSizeChange2(e) {
			this.pageNum2 = 1;
			this.pageSize2 = e;
			this.getVoteList();
		},
		tableCrtPageChange(page) {
			this.pageNum = page;
			this.getRecordTableData();
		},
		tableCrtPageChange2(page) {
			this.pageNum2 = page;
			this.getVoteList();
		},
		deleteData(row) {
			this.$alert(`此操作将永久删除, 是否继续?`, {
				showCancelButton: true,
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				callback: (action) => {
					if (action === "confirm") {
						let data = [row.id]
						this.axios.delete("/project/consult/record", {data}).then((r) => {
							if (r.data.code === 0) {
								this.$message.success("删除成功");
								this.getRecordTableData();
							} else {
								this.$message.warning(r.data.message)
							}
						});
					} else {
					}
				},
			});
		},
		saveData () {
			var form = this.form.form
			var formDataValues = []
			var option = ''
			var obj = {}
			if (this.form.form.consultFeedback === '1') {
				for (let i = 0; i < this.projectDetail.items2.length; i++) {
					if (this.$NUtil.isEmpty(this.projectDetail.items2[i].optionCode)) {
						this.$message.warning('请选择表决结果！')
						return false
					}
					option = this.projectDetail.items2[i].optionCode.split('-')
					obj = {
						fieldId: this.projectDetail.items2[i].fieldId,
						fieldName: this.projectDetail.items2[i].fieldName,
						fieldValue: option[0],
						optionCode: option[1]
					}
					formDataValues.push(obj)
				}
				for (let j = 0; j < this.projectDetail.items3.length; j++) {
					if (this.$NUtil.isEmpty(this.projectDetail.items3[j].optionCode)) {
						this.$message.warning('请选择表决结果！')
						return false
					}
					option = this.projectDetail.items3[j].optionCode.split('-')
					obj = {
						fieldId: this.projectDetail.items3[j].fieldId,
						fieldName: this.projectDetail.items3[j].fieldName,
						fieldValue: option[0],
						optionCode: option[1]
					}
					formDataValues.push(obj)
				}	
			} else {
				formDataValues = []
			}
			const data = {
				ownerId: this.id,
				ownerName: this.projectOwner.name,
				projectId: this.projectOwner.projectId,
				consultUserId: this.projectOwner.consultUserId,
				consultMethod: form.consultMethod,
				consultFeedback: form.consultFeedback,
				remark: form.remark,
				formDataValues: formDataValues
			}
			this.axios.post("/project/consult/record", data).then((r) => {
				if (r.data.code === 0) {
					this.$message.success("操作成功！")
					this.pageNum = 1
					this.pageSize = 5
					this.projectDetail.items.forEach((item) => {
						item.optionCode = ''
					})
					this.form.form = {
						consultMethod: '1',
						consultFeedback: '1',
						remark: '',
						formDataValues: []
					}
					this.getOwnerDetil()
				} else {
					this.$message.warning(r.data.message)
				}
			});
		}
	},

	created() {
		// console.log('111', getItem('user'))
		this.data = this.$route.query.data
		console.log('data', JSON.parse(this.$route.query.data))
		this.id = this.$route.query.id
		if (this.id) {
			this.getOwnerDetil()
		}
	},

	mounted() {},

	destroyed() {},
};
</script>

<style scoped lang="scss">
.img-box img{
	width: 100%;
}
.message-box{
	background-color: #eee;
	border-radius: 5px;
	padding: 10px;
	margin: 20px auto 0;
}
.message-box .msg-title{
	font-size: 16px;
	font-weight: bold;
	margin-bottom: 10px;
}
.message-box .msg-content textarea{
	width: 100%;
	height: 200px;
	padding: 5px;
	border-radius: 5px;
	background-color: #eee;
}
.message-box .msg-content p{
	margin-top: 10px;
	color: red;
}
.choose-box{
	text-align: left;
}
.send-name{
	display: flex;
	justify-content: space-between;
	padding: 30px 0 10px;
}
.send-name .name-r{
	display: flex;
	justify-content: flex-start;
}
.table-pagination {
	display: flex;
	justify-content: center;
	width: 100%;
	padding: 30px 0;
}
.warp{
	width: 100%;
	display: flex;
	justify-content: space-between;
}
.user-info {
  color: #606266;
  padding: 20px 10px;
  width: 24%;
  border: 1px solid #eee;
  font-size: 14px;
  background-color: #fff;
  .info-box{
	  display: flex;
	  justify-content: flex-start;
	  align-items: center;
	  .avatar {
		padding: 20px;
		border-radius: 5px;
		background-color: #409EFF;
		color: #fff;
		margin-right: 20px;
	  }
	  .info{
		  .name-box{
			  font-size: 14px;
			  span{
				  padding: 2px 5px;
				  background-color: #73d13d;
				  color: #fff;
				  margin-left: 10px;
				  border-radius: 5px;
				  font-size: 12px;
			  }
		  }
		  .phone{
			  margin-top: 5px;
		  }
	  }
  }
  .project{
	  margin-top: 30px;
	  list-style:none;
	  li{
		  padding: 8px;
		  background-color: #f0f0f0;
		  margin-top: 15px;
		  list-style:none;
		  .name-box{
			  display: flex;
			  justify-content: space-between;
			.name{
				font-size: 14px;
				font-weight: bold;
			}
			.time{
				color: #606266;
				font-size: 12px;
			}
		  }
		  .title{
			  margin-top: 10px;
			  font-size: 12px;
		  }
		  .resoult{
			  margin-top: 10px;
			  font-size: 14px;
			  font-weight: bold;
		  }
	  }
  }
}
.more-info {
  border: 1px solid #eee;
  padding: 20px 10px;
  background-color: #fff;
  width: 74%;
  position: relative;
}
.more-info .back{
	position: absolute;
	right: 15px;
	top: 20px;
	z-index: 999;
}
.more-info .el-tabs__header{
	margin-left: 30px;
}
.chat-box{
	width: 100%;
	padding: 30px;
	background-color: #eee;
	max-height: 500px;
	overflow-y: auto;
	.out-side{
		width: 100%;
		&.left{
			display: flex;
			justify-content: flex-start;
			.line-box{
				.msg-box{
					.content{
						background-color: #fff;
						color: #000;
					}
				}
			}
		}
		&.right{
			display: flex;
			justify-content: flex-end;
			.avatar-box{
				margin-left: 20px;
				margin-right: 0;
			}
			.line-box{
				.msg-box{
					.content{
						text-align: right;
					}
				}
			}
		}
	}
	.line-box{
		width: 500px;
		margin-top: 30px;
		display: flex;
		justify-content: flex-start;
		.avatar-box{
			border-radius: 50%;
			width: 50px;
			height: 50px;
			background-color: #ccc;
			margin-right: 20px;
			img{
				width: 50px;
				height: 50px;
				border-radius: 50%;
			}
		}
		.msg-box{
			width: 430px;
			.name-box{
				display: flex;
				justify-content: space-between;
				.name{
					font-size: 12px;
				}
				.time{
					font-size: 12px;
					color: #606266;
				}
			}
			.content{
				font-size: 12px;
				color: #fff;
				background-color: #1890ff;
				padding: 8px;
				margin-top: 15px;
				border-radius: 5px;
			}
			.tips{
				font-size: 12px;
				color: #606266;
				margin-top: 15px;
				p{
					margin-top: 5px;
				}
			}
		}
	}
}
.choose-box{
	margin-top: 20px;
}
.more-info .el-form-item__label{
	font-weight: normal;
}
</style>
