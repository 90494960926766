<template>
	<div class="page">
		<el-container>
			<el-main>
				<div class="wrapper">
					<img
						src="../assets/img/logo3.png"
						alt=""
						class="logo-img"
					/>
					<el-input v-model="account" placeholder="帐号"></el-input>
					<el-input
						v-model="password"
						placeholder="密码"
						show-password
					></el-input>
					<div class="code-wrap">
						<el-input
							v-model="code"
							placeholder="验证码"
						></el-input>
						<el-button @click="getCode"
							><img :src="codeImg" alt="" class="code-img"
						/></el-button>
					</div>
					<el-button type="primary" @click="login">登录</el-button>
				</div>
			</el-main>
			<el-footer>
				<p class="copyright">
					<!-- copyright&copy;2020 壹零(天津)信息技术有限公司 -->
					copyright&copy;2022 江苏法库企业管理有限公司
				</p>
			</el-footer>
		</el-container>
	</div>
</template>

<script>
export default {
	data() {
		return {
			account: "",
			password: "",
			code: "",
			codeImg: null,
		};
	},

	computed: {},

	methods: {
		login() {
			if (!this.account) {
				this.$message.error("帐号不能为空");
				return;
			}

			if (!this.password) {
				this.$message.error("密码不能为空");
				return;
			}

			if (!this.code) {
				this.$message.error("验证码不能为空");
				return;
			}

			this.axios
				.post("doLogin", {
					username: this.account,
					password: this.password,
					code: this.code,
				})
				.then((r) => {
					console.log(r.data);
					if (r.data.status === 200) {
						this.$message.success("登录成功");
						setItem("user", r.data.obj);
						this.$router.push({ path: "/home" });
					} else {
						this.$message.error(r.data.msg);
						this.getCode();
					}
				});
		},

		getCode() {
			this.axios
				.get("verifyCode", {
					responseType: "arraybuffer",
				})
				.then((r) => {
					let encodeData = btoa(
						new Uint8Array(r.data).reduce(
							(data, byte) => data + String.fromCharCode(byte),
							""
						)
					);
					return "data:image/png;base64," + encodeData;
				})
				.then((d) => {
					this.codeImg = d;
				});
		},
	},

	created() {
		this.getCode();
	},

	mounted() {},
};
</script>

<style scoped>
.page {
	background-image: url("../assets/img/bg.jpg");
	background-size: cover;
	height: 100vh;
	width: 100%;
	display: flex;
}

.el-main {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.logo-img {
	width: 300px;
	/* height: 120px; */
	margin: 50px auto 50px;
}

.el-footer {
	color: #888;
	font-size: 14px;
	text-align: center;
}

.el-input {
	width: 330px;
	margin-bottom: 20px;
}

.code-wrap {
	display: flex;
	width: 100%;
	/* align-items: center; */
	justify-content: space-between;
}

.code-wrap .el-input {
	/* flex-grow: 1; */
	width: 200px;
}

.code-wrap > .el-button {
	width: 120px;
	height: 40px;
	margin: 0;
	padding: 0;
	overflow: hidden;
}

.code-img {
	width: 100%;
	height: 100%;
}

.wrapper > .el-button {
	width: 100%;
	margin-top: 50px;
}
</style>
