import store from '../store'
import NUtil from '@/utils/util'

const TokenKey = 'nplus-Token'

export function isLogin () {
  const token = NUtil.getCookie(TokenKey)
  // TODO:判断Token有效期
  return token
}

export function getToken () {
  return getTokenPrefix() + NUtil.getCookie(TokenKey)
}

export function setToken (token) {
  return NUtil.setCookie(TokenKey, token)
}

export function removeToken () {
  return NUtil.removeCookie(TokenKey)
}

export function getTokenPrefix () {
  return 'Bearer '
}

export function getTokenHeader () {
  return 'Authorization'
}

export function hasPermission (code) {
  if (code instanceof Array) {
    for (var j = 0, len = code.length; j < len; j++) {
      if (store.getters.permissions.indexOf(code[j]) !== -1) {
        return true
      }
    }
    return false
  } else {
    return !(store.getters.permissions.indexOf(code) === -1)
  }
}

export function hasRole (role) {
  if (role instanceof Array) {
    for (var j = 0, len = role.length; j < len; j++) {
      if (store.getters.roles.indexOf(role[j]) !== -1) {
        return true
      }
    }
    return false
  } else {
    return !(store.getters.roles.indexOf(role) === -1)
  }
}

export function hasGroup (group) {
  if (group instanceof Array) {
    for (var j = 0, len = group.length; j < len; j++) {
      if (store.getters.groups.indexOf(group[j]) !== -1) {
        return true
      }
    }
    return false
  } else {
    return !(store.getters.groups.indexOf(group) === -1)
  }
}
