<template>
	<div class="container">
		<el-footer>
			<p class="copyright">
				copyright&copy;2020 壹零(天津)信息技术有限公司
			</p>
		</el-footer>
	</div>
</template>

<script>
export default {
	props: {},

	data() {
		return {};
	},

	computed: {},

	methods: {},

	created() {},

	mounted() {},

	destroyed() {},
};
</script>

<style scoped>
.el-footer {
	background-color: #f7f7f7;
	color: #888;
	font-size: 14px;
	text-align: center;
}
</style>
