<template>
	<div class="container" v-loading="tableLoading" element-loading-text="请稍等" element-loading-background="rgba(0, 0, 0, 0.5)">
		<!-- query bar -->
		<div class="table-query-bar">
			<el-input v-model="queryOpts.projectName" placeholder="请输入项目名称"	size="mini"></el-input>
			<el-cascader v-model="queryOpts.city" clearable :options="citylist" placeholder="请选择省市" size="mini" :props="{ expandTrigger: 'hover' }"></el-cascader>
			<el-select v-model="queryOpts.communityName" clearable filterable placeholder="请选择所在小区"	size="mini">
				<el-option v-for="item of communitylistDig" :key="item.id" :label="item.name" :value="item.name"></el-option>
			</el-select>
			<el-select v-model="queryOpts.projectStatus" clearable	placeholder="请选择项目状态" size="mini">
				<el-option v-for="item in $keys.projectStatus" :key="item.key" :label="item.value" :value="item.key"></el-option>
			</el-select>
			<div class="query-btn-wrap">
				<el-button type="primary" size="mini" @click="query">
					查询
				</el-button>
				<el-button size="mini" @click="clearQuery">重置</el-button>
				<el-button type="primary" size="mini" @click="createDataFun" v-if="user.roles[0].name === 'ROLE_系统管理员'">
					新建项目
				</el-button>
			</div>
		</div>
		<!-- table -->
		<div class="table-wrap">
			<el-table :data="tableData" border stripe style="width: 99%">
				<el-table-column prop="projectCode" label="项目编号" width="120"></el-table-column>
				<el-table-column prop="projectName" label="项目名称" width="200">
					<template slot-scope="scope">
						<a href="javascript:;" @click="goProItem(scope.row)">{{scope.row.projectName}}</a>
					</template>
				</el-table-column>
				<el-table-column prop="city" label="所在城市" width="120"></el-table-column>
				<el-table-column prop="communityName" label="所在小区" width="200"></el-table-column>
				<el-table-column prop="itemCount" label="表决事项数" width="100"></el-table-column>
				<el-table-column prop="ownerCount" label="表决/业主数" width="100"></el-table-column>
				<el-table-column prop="projectStatus" label="项目状态" width="80">
					<template slot-scope="scope">
						{{$keys.getValue($keys.projectStatus, String(scope.row.projectStatus))}}
					</template>
				</el-table-column>
				<el-table-column label="活动时间" width="280">
					<template slot-scope="scope">
						<span>{{ scope.row.startTime }} - {{ scope.row.endTime }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="createTime" label="创建时间" width="150">
					<template slot-scope="scope">
						<span>{{ scope.row.createTime }}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" fixed="right" width="200" v-if="user.roles[0].name === 'ROLE_系统管理员'">
					<template slot-scope="scope">
						<el-button size="mini" @click="rowEdit(scope.$index, scope.row)">
							项目设置
						</el-button>
						<el-button size="mini" type="danger" @click="rowDelete(scope.$index, scope.row)">
							删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="table-pagination">
			<el-pagination @size-change="tableSizeChange" @current-change="tableCrtPageChange" :current-page="pageNum" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize"	layout="total, sizes, prev, pager, next, jumper" :total="totalSize"></el-pagination>
		</div>
		<!-- 新建项目弹框 -->
		<el-dialog :visible.sync="dialogForm.visible" :title="dialogForm.title">
			<el-form :model="dialogForm.form" ref="dialogForm" :rules="dialogForm.rules">
				<div class="title">基本信息</div>
				<el-form-item label="项目名称" :label-width="formLabelWidth" prop="projectName" style="width:460px;">
					<el-input v-model="dialogForm.form.projectName" size="mini" maxlength="500" placeholder="请输入项目名称"></el-input>
				</el-form-item>
				<el-form-item label="所属小区" :label-width="formLabelWidth" prop="communityId">
					<el-select v-model="dialogForm.form.communityId" clearable filterable	placeholder="请选择所在小区" size="mini">
						<el-option	v-for="item of communitylistDig" :key="item.id" :label="item.name"	:value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="项目周期" :label-width="formLabelWidth" prop="projectTime">
					<el-date-picker size="mini" v-model="dialogForm.form.projectTime" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
				</el-form-item>
				<div class="title">
					事项设置
					<el-button class="fr" type="primary" size="mini" @click="addPriject" v-if="dialogForm.items.length === 0">添加事项</el-button>
					<el-button class="fr" type="primary" size="mini" @click="addPriject2" style="margin-right: 20px;" v-if="dialogForm.items2.length === 0">添加业委会委员事项组</el-button>
				</div>
				<div class="project-box" v-for="(item, index) in dialogForm.items" :key="index">
					<div class="project-list-item" style="padding: 5px 5px; background-color: #eee;margin-bottom: 10px; overflow:hidden;line-height:30px;">
						表决事项 {{index + 1}}
						<el-button class="fr" type="text" size="mini" @click="addPriject(index)">+ 添加事项</el-button>
						<el-button class="fr" type="text" size="mini"  @click="deleteItems(index)" style="margin-right: 10px; color: #f56c6c">- 删除事项</el-button>
					</div>
					<el-form-item label="事项名称" :label-width="formLabelWidth" prop="fieldName">
						<el-input v-model="item.fieldName" maxlength="500" placeholder="请输入项目名称"></el-input>
					</el-form-item>
					<!-- <el-form-item label="" :label-width="formLabelWidth" prop="name">
						<el-button type="primary" size="mini" @click="addOption(index)">添加选项</el-button>
					</el-form-item> -->
					<el-form-item :label="'选项' + (o + 1)" :label-width="formLabelWidth" prop="name" v-for="(i, o) in item.options" :key="o">
						<el-input class="fl" v-model="i.optionName" placeholder="请输入选项1" size="mini" style="width: 300px; margin-right: 20px;"></el-input>
						<el-button class="fl" type="primary" plain circle icon="el-icon-plus" size="mini" @click="addOption(index, o)"></el-button>
						<el-button class="fl" plain circle size="mini" icon="el-icon-minus" @click="deleteOption(index, o)"  v-if="o !== 0"></el-button>
					</el-form-item>
				</div>
				<div class="project-box" v-for="(item, index) in dialogForm.items2" :key=" 'id' + index">
					<div class="project-list-item" style="padding: 5px 5px; background-color: #eee;margin-bottom: 10px; overflow:hidden; line-height:30px;" v-if="index === 0">
						业委会委员事项组
						<!-- <el-button class="fr" type="text" @click="addPriject2" size="mini">+ 添加委员</el-button> -->
						<el-button class="fr" type="text" size="mini" style="margin-right: 10px; color: #f56c6c;" @click="deleteGroup">- 删除委员事项组</el-button>
					</div>
					<el-form-item :label="'委员' + (index + 1) + '姓名'" :label-width="formLabelWidth" prop="fieldName">
						<el-input v-model="item.fieldName" maxlength="500" placeholder="请输入委员名称" style="width: 400px; margin-right: 20px;"></el-input>
						<el-button type="text" size="mini"  @click="addPriject2(index)" style="margin-top:3px;">+ 添加委员</el-button>
						<el-button type="text" size="mini"  @click="deleteItems2(index)" style="color: #f56c6c; margin-top:3px;">- 删除委员</el-button>
					</el-form-item>
					<el-form-item :label="'选项' + (o + 1)" :label-width="formLabelWidth" prop="name" v-for="(i, o) in item.options" :key="o">
						<el-input class="fl" v-model="i.optionName" placeholder="请输入选项1" size="mini" style="width: 300px; margin-right: 20px;"></el-input>
						<el-button class="fl" type="primary" plain circle icon="el-icon-plus" size="mini" @click="addOption2(index, o)"></el-button>
						<el-button class="fl" plain circle size="mini" icon="el-icon-minus" @click="deleteOption2(index, o)"  v-if="o !== 0"></el-button>
					</el-form-item>
				</div>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogForm.visible = false" size="mini">取 消</el-button>
				<el-button type="primary" v-if="dialogForm.status" size="mini" @click="createData">确 定</el-button>
				<el-button type="primary" v-else @click="editData" size="mini">确 定</el-button>
			</div>
        </el-dialog>
	</div>
</template>

<script>
import areas from "../../assets/area";

export default {
	props: {},

	data() {
		return {
			tableLoading: false,
			userLoading: false,
			orderDialogShow: false,
			teleDialogShow: false,
			// table
			tableData: [],
			// query preset
			citylist: [],
			communitylist: [],
			variableCommunitylist: [],
			// query bar
			queryOpts: {
				projectName: '', // 项目名称
				city: '', // 城市
				communityName: '', // 小区
				projectStatus: '', // 状态
				asktime: null,
				replytime: null,
			},
			// order dialog
			orderDialog: {},
			// tele dialog
			teleDialog: {
				consultCategory: null,
				communityId: null,
				status: "1",
				nickname: null,
				userPhone: null,
				cityCode: null,
				question: null,
				reply: null,
				userId: null,
			},
			// pagination
			pageNum: 1,
			pageSize: 10,
			totalSize: 0,
			user: getItem("user"),
			formLabelWidth: '120px',
			dialogForm: {
				clickDis: true, // 新建按钮多次点击状态
				visible: false, // 显示隐藏
				status: true, // 是新增还是编辑 true:新增，false:编辑
				title: '新建项目',
				form: {
					projectName: '',
					communityId: '',
					projectTime: ''
				},
				items: [],
				items2: [],
				rules: {
					// 表单规则
					projectName: [
						{ required: true, message: '请填写项目名称', trigger: 'change' }
					],
					communityId: [
						{ required: true, message: '请选择所属小区', trigger: 'change' }
					],
					projectTime: [
						{ required: true, message: '请选择项目周期', trigger: 'change' }
					]
				},
				items: []
			},
			communitylistDig: []
		};
	},

	computed: {},

	methods: {
		// 删除委员组
		deleteGroup () {
			this.dialogForm.items2 = []
		},
		resetDialogForm () {
			// 将弹窗表单清空
			this.dialogForm.items = []
			this.dialogForm.items2 = []
			this.dialogForm.form.projectName = ''
			this.dialogForm.form.communityId = ''
			this.dialogForm.form.projectTime = ''
		},
		// 点击新增
		createDataFun() {
			this.dialogForm.clickDis = true
			this.dialogForm.visible = true
			this.dialogForm.status = true
			this.dialogForm.title = '新建项目'
			this.resetDialogForm()
			this.cityData()
			this.$nextTick(() => {
				this.$refs['dialogForm'].clearValidate()
			})
		},
		// 提交新增
		createData() {
			this.$refs['dialogForm'].validate(valid => {
        		if (valid) {
					if (this.dialogForm.items.length > 20) {
						this.$message.warning('最多只能添加20个事项！')
        				return false
					}
					const arr = this.dialogForm.items.filter(item => item.fieldName === '')
					if (arr.length > 0) {
						this.$message({
						message: '请检查事项名称否全部填写完整',
						type: 'warning'
						})
						return
					}
					for (var i = 0; i < this.dialogForm.items.length; i++) {
						const a = this.dialogForm.items[i].options.filter(i => i.optionName === '')
						if (a.length > 0) {
							this.$message({
							message: '请检查事项的我选项是否全部填写完整',
							type: 'warning'
							})
							return
						}
					}
					let items = this.dialogForm.items.concat(this.dialogForm.items2)
					let params = {
						projectName: this.dialogForm.form.projectName,
						communityId: this.dialogForm.form.communityId,
						startTime: this.dateFormat(new Date(this.dialogForm.form.projectTime[0]), 'yyyy-MM-dd') + ' 00:00:00',
						endTime: this.dateFormat(new Date(this.dialogForm.form.projectTime[1]), 'yyyy-MM-dd') + ' 23:59:59',
						items: items
					}
					this.axios.post("/project", params).then((r) => {
						if (r.data.code === 0) {
							this.$message.success("成功创建项目")
							this.dialogForm.visible = false
							this.getTableData()
						} else {
							this.$message.warning(r.data.message)
						}
					});
				}
			})
		},
		// 时间格式转化
		dateFormat (date, format) {
			let zeroPadding = function (i) {
				return (i < 10 ? '0' : '') + i
			}
			return format.replace(/yyyy|MM|dd|HH|mm|ss/g, function (item) {
				switch (item) {
				case 'yyyy':
					return zeroPadding(date.getFullYear())
				case 'MM':
					return zeroPadding(date.getMonth() + 1)
				case 'dd':
					return zeroPadding(date.getDate())
				case 'HH':
					return zeroPadding(date.getHours())
				case 'mm':
					return zeroPadding(date.getMinutes())
				case 'ss':
					return zeroPadding(date.getSeconds())
				}
			})
		},
		// 跳转项目子页面
		goProItem(row) {
			this.$router.push({ path: '/home/projectItem?id=' + row.id })
		},
		// 添加事项
		addPriject(index) {
			let options = [
				{
					optionName: '同意'
				},
				{
					optionName: '反对'
				},
				{
					optionName: '弃权'
				}
			]
			let itemObj = {
				fieldName: '',
				fieldMode: 0,
				options: options
			}
			this.dialogForm.items.splice(index + 1, 0, itemObj)
		},
		addPriject2(index) {
			let options = [
				{
					optionName: '同意'
				},
				{
					optionName: '反对'
				},
				{
					optionName: '弃权'
				}
			]
			let itemObj = {
				fieldName: '',
				fieldMode: 1,
				options: options
			}
			this.dialogForm.items2.splice( index + 1, 0, itemObj)
		},
		// 添加事项的选项
		addOption (index, i) {
			let optionsObj = {
				optionName: ''
			}
			this.dialogForm.items[index].options.splice( i + 1, 0, optionsObj)
		},
		addOption2 (index, i) {
			let optionsObj = {
				optionName: ''
			}
			this.dialogForm.items2[index].options.splice( i + 1, 0, optionsObj)
		},
		// 删除事项的选项
		deleteOption (index, o) {
			this.dialogForm.items[index].options.splice(o, 1)
		},
		deleteOption2 (index, o) {
			this.dialogForm.items2[index].options.splice(o, 1)
		},
		deleteItems (index) {
			this.dialogForm.items.splice(index, 1)
		},
		deleteItems2 (index) {
			this.dialogForm.items2.splice(index, 1)
		},
		// 省市列表
		initCityList() {
			let area = [];
			for (let [key, value] of Object.entries(areas.province_list)) {
				let child = [];
				area.push({
					value: key,
					label: value,
					children: child,
				});
				for (let [innerKey, innerValue] of Object.entries(
					areas.city_list
				)) {
					if (innerKey.substring(0, 2) === key.substring(0, 2)) {
						child.push({
							value: innerKey,
							label: innerValue,
						});
					}
				}
			}
			this.citylist = area;
		},
		// 根据省市查询小区
		queryCityChange(e) {
			this.communitylist = [];
			let params = {
				cityCode: e[1].substring(0, 4),
			};
			this.axios.get("community/getCommunitiesByCityCode", { params }).then((r) => {
				if (r.data.code === 0) {
					this.communitylist = r.data.data;
				} else {
					this.$message.warning(r.data.message)
				}
			});
		},
		// 所有小区列表
		cityData() {
			this.communitylistDig = [];
			let params = {
				adminUserId: this.user.id
			};
			this.axios.get("/community/getCommunities", { params }).then((r) => {
				this.communitylistDig = r.data.data;
			});
		},
		// 查询方法
		query() {
			let opts = this.queryOpts;
			let params = {
				pageNum: this.pageNum,
				pageSize: this.pageSize,
				projectName: opts.projectName,
				cityCode: opts.city ? opts.city[1].substring(0, 4) : null,
				communityName: opts.communityName,
				projectStatus: opts.projectStatus
			};
			this.tableLoading = true;
			this.axios.get("/project/list", {params}).then((r) => {
				if (r.data.code === 0) {
					this.tableData = r.data.rows;
					this.pageNum = 1;
					this.totalSize = r.data.total;
				} else {
					this.$message.warning(r.data.message)
				}
				this.tableLoading = false;
			});
		},
		// 重置方法
		clearQuery() {
			for (let key of Object.keys(this.queryOpts)) {
				this.queryOpts[key] = null;
			}
		},
		// table event
		tableSizeChange(e) {
			this.pageNum = 1;
			this.pageSize = e;
			this.getTableData();
		},
		tableCrtPageChange(page) {
			this.pageNum = page;
			this.getTableData();
		},
		rowEdit(index, row) {
			this.resetDialogForm()
			this.axios.get("/project/" + row.id).then((r) => {
				this.projectCode = row.projectCode
				this.cityData()
				this.dialogForm.status = false
				let data = r.data.data
				this.dialogForm.form.projectName = data.projectName
				this.dialogForm.form.communityId = data.communityId
				this.dialogForm.form.projectTime = [data.startTime, data.endTime]
				for (let i = 0; i < data.items.length; i++) {
					console.log(data.items[i].fieldMode)
					if (data.items[i].fieldMode === 0) {
						this.dialogForm.items.push(data.items[i])
					} else {
						this.dialogForm.items2.push(data.items[i])
					}
				}
				console.log(this.dialogForm.items)
				console.log(this.dialogForm.items2)
				// this.dialogForm.items = data.items
				setTimeout(() => {
					this.dialogForm.visible = true
				}, 100)
			})
		},
		editData () {
			this.$refs['dialogForm'].validate(valid => {
        		if (valid) {
					if (this.dialogForm.items.length > 20) {
						this.$message.warning('最多只能添加20个事项！')
        				return false
					}
					const arr = this.dialogForm.items.filter(item => item.fieldName === '')
					if (arr.length > 0) {
						this.$message({
						message: '请检查事项名称否全部填写完整',
						type: 'warning'
						})
						return
					}
					for (var i = 0; i < this.dialogForm.items.length; i++) {
						const a = this.dialogForm.items[i].options.filter(i => i.optionName === '')
						if (a.length > 0) {
							this.$message({
							message: '请检查事项的我选项是否全部填写完整',
							type: 'warning'
							})
							return
						}
					}
					let items = this.dialogForm.items.concat(this.dialogForm.items2)
					let params = {
						projectCode: this.projectCode,
						projectName: this.dialogForm.form.projectName,
						communityId: this.dialogForm.form.communityId,
						startTime: this.dateFormat(new Date(this.dialogForm.form.projectTime[0]), 'yyyy-MM-dd') + ' 00:00:00',
						endTime: this.dateFormat(new Date(this.dialogForm.form.projectTime[1]), 'yyyy-MM-dd')  + ' 23:59:59',
						items: items
					}
					this.axios.put("/project", params).then((r) => {
						if (r.data.code === 0) {
							this.$message.success("修改项目成功")
							this.dialogForm.visible = false
							this.getTableData()
						} else {
							this.$message.warning(r.data.message)
						}
					});
				}
			})
		},
		rowDelete(index, row) {
			// console.log(index, row);
			this.$alert(`删除表决项目${row.projectName}，确定吗？`, {
				showCancelButton: true,
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				callback: (action) => {
					if (action === "confirm") {
						let data = [row.id]
						this.axios.delete("/project", {data}).then((r) => {
							if (r.data.code === 0) {
								this.$message.success("删除成功");
								this.getTableData();
							} else {
								this.$message.warning(r.data.message)
							}
						});
					} else {
					}
				},
			});
		},
		// 获取列表
		getTableData() {
			let opts = this.queryOpts;
			let params = {
				pageNum: this.pageNum,
				pageSize: this.pageSize,
				projectName: opts.projectName,
				cityCode: opts.city ? opts.city[1].substring(0, 4) : null,
				communityName: opts.communityName,
				projectStatus: opts.projectStatus
			};
			this.tableLoading = true;
			this.axios.get("/project/list", {params}).then((r) => {
				if (r.data.code === 0) {
					this.tableData = r.data.rows;
					this.totalSize = r.data.total;
				} else {
					this.$message.warning(r.data.message)
				}
				this.tableLoading = false;
			});
		}
	},
	created() {
		this.getTableData();
		this.initCityList();
		this.cityData()
	},
	mounted() {},
	destroyed() {},
};
</script>

<style scoped>
.container {
	width: 100%;
	position: relative;
	background-color: #fff;
	padding: 20px;
}

.table-query-bar {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.table-query-bar .el-input,
.table-query-bar .el-select,
.table-query-bar .el-cascader {
	width: 140px;
	margin-bottom: 20px;
	margin-right: 20px;
}

.e-date-wrap {
	margin-bottom: 20px;
	margin-right: 20px;
}

.e-date-label {
	font-size: 14px;
	color: #00567e;
	margin-right: 10px;
}

.query-btn-wrap {
	margin-bottom: 20px;
}

/* table */
.table-wrap {
	max-width: 100%;
}

.table-pagination {
	display: flex;
	justify-content: center;
	width: 100%;
	padding: 30px 0;
}

/* order dialog */
/deep/.el-dialog__body {
	padding: 10px 20px;
}

.order-read-only {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.or-wrap-left,
.or-wrap-right {
	width: 350px;
}

.or-label-wrap {
	display: flex;
	align-items: center;
	margin-bottom: 15px;
}

.or-label-content {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 20px;
}

.edit-bar {
	width: 100%;
	display: flex;
	margin-bottom: 15px;
}

.edit-bar-label {
	min-width: 70px;
	margin-right: 20px;
}

.askimg-bar {
	width: 100%;
	padding: 0 90px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.el-image {
	margin-right: 15px;
	margin-bottom: 15px;
}

.des-bar {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	height: 40px;
}

.des-bar-left {
	display: flex;
	align-items: center;
}

.des-bar-label {
	margin-right: 20px;
}

.des-bar-content {
	color: #ff5c5c;
}

/* tele dialog */
.tel-dialog-body {
	width: 100%;
	height: 100%;
}

.tel-dialog-body .el-form {
	display: flex;
	flex-wrap: wrap;
}

.tel-dialog-body .el-form-item {
	width: 50%;
	display: flex;
	/* justify-content: center; */
	padding-left: 40px;
}

.tel-dialog-body .el-select {
	max-width: 202px;
}

.tel-dialog-textarea {
	display: flex;
	width: 100%;
	padding-left: 40px;
	padding-right: 30px;
	margin-bottom: 20px;
}

.tel-dia-label {
	min-width: 68px;
}

.tel-dialog-textarea .el-textarea {
	max-width: 700px;
}
.title{
	margin: 15px 0;
	padding-left: 15px;
	border-left: 3px solid #66b1ff;
	overflow: hidden;
	line-height: 20px;
}
.fr{
	float: right;
}
.fl{
	float: left;
}
.el-button--mini.is-circle{
	padding: 4px !important;
	margin-top: 7px;
}
</style>
