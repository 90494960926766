import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import axios from "axios";
import qs from "qs";
import NUtil from '@/utils/util'
import keys from '@/utils/keys'
import "element-ui/lib/theme-chalk/index.css";
import footer from "./components/footer";
import { Message } from "element-ui";

Vue.config.productionTip = false;
Vue.use(ElementUI);

Vue.component("e-footer", footer);

axios.defaults.withCredentials = true;
// axios.defaults.transformRequest = (data) => qs.stringify(data);
// axios.defaults.baseURL = "http://49.233.107.11:8087/";

// 创建axios实例
const service = axios.create({
	// axios中请求配置有baseURL选项，表示请求URL公共部分
	baseURL: "https://admin-api.efacoo.com/",
	// 超时
	timeout: 60000
});

// request拦截器
service.interceptors.request.use(config => {

	// 如果是项目开头的，进行改造，采用JSON请求方式
	if(config.url.indexOf('project') > 0){
		config.headers['Content-Type'] = 'application/json;charset=utf-8'
	}else{
		config.transformRequest = (data) => qs.stringify(data)
	}

	return config
  }, error => {
	  console.log(error)
	  Promise.reject(error)
  }
);

// 全局响应拦截器
service.interceptors.response.use(
	function(res) {
		// Any status code that lie within the range of 2xx cause this function to trigger
		// Do something with response data
		if (res.data.status === 401) {
			Message.error({ message: "登录状态过期,请重新登录" });
			router.replace("/");
			return false;
        }

        if(res.status == 500){
            Message.error({ message: "服务器错误" });
            return;
        }

		switch (res.data.code) {
			case 2:
				Message.error({ message: "非法参数" });
				break;

			case 3:
				Message.error({ message: "用户已存在" });
				break;
			case 4:
				Message.error({ message: "找不到记录" });
				break;
			case 5:
				Message.error({ message: "无权限访问" });
				break;
			case 6:
				Message.error({ message: "服务器内部错误" });
				break;
			case 7:
				Message.error({ message: "状态错误" });
				break;
			case 8:
				Message.error({ message: "内容重复" });
				break;
			case 9:
				Message.error({ message: "找不到内容" });
				break;
			case 10:
				Message.error({ message: "验证失败" });
				break;
			case 11:
				Message.error({ message: "登陆过期" });
				break;
			case 12:
				Message.error({ message: "登陆失效" });
				break;
			case 13:
				Message.error({ message: "验证码错误" });
				break;
			case 14:
				Message.error({ message: "操作异常" });
				break;
			case 15:
				Message.error({ message: "文件读写错误" });
				break;
			case 16:
				Message.error({ message: "无效的请求签名" });
				break;
			case 17:
				Message.error({ message: "token鉴权错误" });
				break;
			case 18:
				Message.error({ message: "token已过期" });
				break;
			case 19:
				Message.error({ message: "请求已过期" });
				break;
			case 20:
				Message.error({ message: "用户名或密码错误" });
				break;
			case 21:
				Message.error({ message: "用户不存在" });
				break;
			case 22:
				Message.error({ message: "手机号已存在" });
				break;
			case 23:
				Message.error({ message: "该课程已经收藏过" });
				break;
			case 25:
				Message.error({ message: "请重试！" });
				break;
			case 26:
				Message.error({ message: "图片上传失败！" });
				break;
			case 27:
				Message.error({ message: "手机号码格式错误!" });
				break;
			case 28:
				Message.error({
					message: "您所在的小区未开通服务或者服务已经过期!",
				});
				break;
			default:
				break;
		}

		return res;
	},
	function(error) {
		// Any status codes that falls outside the range of 2xx cause this function to trigger
		// Do something with response error
		return Promise.reject(error);
	}
);

window.setItem = function(name, item) {
	if (typeof item === "object") {
		window.localStorage.setItem(name, JSON.stringify(item));
		return 1;
	} else {
		window.localStorage.setItem(name, item);
		return 0;
	}
};

window.getItem = function(name) {
	return JSON.parse(window.localStorage.getItem(name));
};

Vue.prototype.$qs = qs;
Vue.prototype.axios = service;
Vue.prototype.$keys = keys
Vue.prototype.$NUtil = NUtil
// Vue.prototype.$axios = axios;

Vue.filter("timeformat", function(timestamp) {
	if (!timestamp) return "-";
	const date = new Date(timestamp);
	let year = date.getFullYear();
	let month = date.getMonth() + 1;
	let day = date.getDate();
	let hour = date.getHours();
	let minute = date.getMinutes();
	let second = date.getSeconds();

	month = month.toString().padStart(2, "0");
	day = day.toString().padStart(2, "0");
	hour = hour.toString().padStart(2, "0");
	minute = minute.toString().padStart(2, "0");
	second = second.toString().padStart(2, "0");

	return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
});

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");
