import Cookies from 'js-cookie'
/**
 * 工具类
 * @return {[type]} [description]
 */
let NUtil = {}

/**
 * 隐藏手机和身份证中间数字，只显示前3和后4位数字，中间星号
 * @param  {[String]}  value 待处理的号码
 * @return {[String]}  处理好的号码
 */
NUtil.doHide = function (value) {
  let v = value.replace(/\s+/g, '').replace(/\r\n/g, '').replace(/\n/g, '')
  let reg = /^(\d{3})(\d+)(\d{4}|\d{3}(x|X))$/
  return v.replace(reg, '$1****$3')
}

/**
 * 判断密码试试符合要求,对应的验证规则是：密码中必须包含字母、数字、特称字符，至少8个字符，最多20个字符
 * @param  {[String]}  value 待验证的参数
 * @return {[String]}  ture-符合，false-不符合
 */
NUtil.isGoodPassword = function (value) {
  let reg = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,20}$/
  if (reg.test(value)) {
    return true
  } else {
    return false
  }
}

/**
 * 判断参数是否为空
 * @param  {[String]}  value 待验证的参数
 * @return {Boolean}   ture-空，false-不为空
 */
NUtil.isEmpty = function (value) {
  if (typeof (value) === 'undefined' || value === null || String(value).replace(/\s+/g, '') === '') {
    return true
  } else {
    return false
  }
}

/**
 * 时间戳转日期
 *
 */
function add0 (m) { return m < 10 ? '0' + m : m }
NUtil.transTime = function (str) {
  if (!str) return
  const time = new Date(str)
  const y = time.getFullYear()
  const m = time.getMonth() + 1
  const d = time.getDate()
  // const h = time.getHours()
  // const mm = time.getMinutes()
  // const s = time.getSeconds()
  // return y+'-'+add0(m)+'-'+add0(d)+' '+add0(h)+':'+add0(mm)+':'+add0(s);
  return y + '年' + add0(m) + '月' + add0(d) + '日'
}

/**
 * 判断手机号码格式
 * @param  {[String]}  value 待验证的参数
 * @return {Boolean}   ture-正确，false-不正确
 */
NUtil.isMobile = function (value) {
  let reg = /^(1+\d{10})$/
  if (reg.test(value)) {
    return true
  } else {
    return false
  }
}

/**
 * 判断是否是数字
 * @param  {[String]}  value 待验证的参数
 * @return {Boolean}   ture-正确，false-不正确
 */
NUtil.isNum = function (value) {
  let reg = /^([0-9]*[1-9][0-9]*(.[0-9]+)?|[0]+.[0-9]*[1-9][0-9]*)$/
  if (reg.test(value)) {
    return true
  } else {
    return false
  }
}

/**
 * 判断是否是小数
 * @param  {[String]}  value 待验证的参数
 * @return {Boolean}   ture-正确，false-不正确
 */
NUtil.isDecimals = function (value) {
  let reg = /[^\d]/
  if (reg.test(value)) {
    return true
  } else {
    return false
  }
}

/**
 * 判断是否是汉字和字母
 * @param  {[String]}  value 待验证的参数
 * @return {Boolean}   ture-正确，false-不正确
 */
NUtil.isCharacter = function (value) {
  let reg = /^[a-zA-Z\u4e00-\u9fa5]+$/
  if (reg.test(value)) {
    return true
  } else {
    return false
  }
}

/**
 * 判断身份证格式
 * @param  {[String]}  value 待验证的参数
 * @return {Boolean}   ture-符合规范，false-不符合规范
 */
NUtil.isIdentityCard = function (value) {
  let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
  if (reg.test(value)) {
    return true
  } else {
    return false
  }
}

/**
 * 判断邮箱格式
 * @param  {[String]}  value 待验证的参数
 * @return {Boolean}   ture-符合规范，false-不符合规范
 */
NUtil.isEmail = function (value) {
  let reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
  if (reg.test(value)) {
    return true
  } else {
    return false
  }
}

/**
 * 根据文件名获取文件后缀。例：'xxxxx.jpg.EXE' 返回 'exe'
 * @param  {String} fileName 文件名
 * @return {String}          小写形式的后缀名
 */
NUtil.getFileSuffix = function (fileName) {
  let suffix = ''
  let index = fileName.lastIndexOf('.')
  if (index > -1) {
    suffix = fileName.substring(index + 1).toLowerCase()
  }
  return suffix
}

/**
 * 时间格式化
 * @param  {[Date]} date   要格式化的时间对象
 * @param  {[String]} format 格式，例如"yyyy-MM-dd HH:mm:ss"
 * @return {[String]}        格式化后的时间字符串
 */
NUtil.dateFormat = function (date, format) {
  let zeroPadding = function (i) {
    return (i < 10 ? '0' : '') + i
  }
  return format.replace(/yyyy|MM|dd|HH|mm|ss/g, function (item) {
    switch (item) {
      case 'yyyy':
        return zeroPadding(date.getFullYear())
      case 'MM':
        return zeroPadding(date.getMonth() + 1)
      case 'dd':
        return zeroPadding(date.getDate())
      case 'HH':
        return zeroPadding(date.getHours())
      case 'mm':
        return zeroPadding(date.getMinutes())
      case 'ss':
        return zeroPadding(date.getSeconds())
    }
  })
}

/**
 * 获取两个日期之间的间隔天数。两个日期的格式为：yyyy-MM-dd
 * @param  {[String]} beginDate 开始日期。例：2016-01-01
 * @param  {[String]} endDate   结束日期。例：2016-07-23
 * @return {[int]}           间隔天数
 */
NUtil.getIntervalDays = function (beginDate, endDate) {
  let beginArr = beginDate.split('-')
  let endArr = endDate.split('-')
  let begin = new Date(beginArr[0], beginArr[1] - 1, beginArr[2]).getTime()
  let end = new Date(endArr[0], endArr[1] - 1, endArr[2]).getTime()
  let temp = (end - begin) / (1000 * 60 * 60 * 24)
  return temp
  /*
  if (temp < 0) {
    return temp === temp - 1
  } else {
    return temp === temp + 1
  }
  */
}

/**
 * 获取URL中参数的的值
 * @param  {[String]} key 参数名称
 * @return {[String]}     参数值
 */
NUtil.getParameter = function (key) {
  let url = location.href
  let paraString = url.substring(url.indexOf('?') + 1, url.length).split('&')
  let paraObj = {}
  for (let i = 0, len = paraString.length; i < len; i++) {
    let j = paraString[i]
    paraObj[j.substring(0, j.indexOf('=')).toLowerCase()] = j.substring(j.indexOf('=') + 1, j.length)
  }
  let returnValue = paraObj[key.toLowerCase()]
  if (typeof (returnValue) === 'undefined') {
    return ''
  } else {
    return returnValue
  }
}

/**
 * 设置cookie值
 * @param  {[String]} key 参数名称
 * @param  {[String]} value 参数值
 * @param  {[int]} sec 是小时间。单位：秒
 * @return {[String]}
 */
/*
NUtil.setCookie = function (key, value, sec) {
  let cookieStr = key + '=' + escape(value)
  if (!NUtil.isEmpty(sec)) {
    let exp = new Date()
    exp.setTime(exp.getTime() + sec * 1000)
    cookieStr += ';expires=' + exp.toGMTString()
  }
  cookieStr += ';path=/'
  document.cookie = cookieStr
}
*/
/**
 * 获取cookie中参数的的值
 * @param  {[String]} key 参数名称
 * @return {[String]}     参数值
 */

NUtil.getCookie = function (key) {
  let arr = document.cookie.match(new RegExp('(^| )' + key + '=([^;]*)(;|$)'))
  if (arr === null || arr === '') {
    return ''
  } else {
    return unescape(arr[2])
  }
}

/**
 * 清除cookie中的值
 * @param  {[String]} key 参数名称
 * @return
 */
NUtil.clearCookie = function (key) {
  let exp = new Date()
  exp.setTime(exp.getTime() - 1)
  let value = NUtil.getCookie(key)
  if (!NUtil.isEmpty(value)) {
    document.cookie = key + '=' + value + ';expires=' + exp.toGMTString()
  }
}

/**
 * 秒格式化。转换成：时分秒。例：4230秒 转换成 1小时10分30秒
 * @param   int seconds
 * @return  String
 */
NUtil.secondFormat = function (seconds) {
  let theTime = parseInt(seconds) // 秒
  let theTime1 = 0 // 分
  let theTime2 = 0 // 小时
  if (theTime > 60) {
    theTime1 = parseInt(theTime / 60)
    theTime = parseInt(theTime % 60)
    if (theTime1 > 60) {
      theTime2 = parseInt(theTime1 / 60)
      theTime1 = parseInt(theTime1 % 60)
    }
  }
  let result = '' + parseInt(theTime) + '秒'
  if (theTime1 > 0) {
    result = '' + parseInt(theTime1) + '分' + result
  }
  if (theTime2 > 0) {
    result = '' + parseInt(theTime2) + '小时' + result
  }
  return result
}

/**
 * base 64位加密解密
 */
NUtil.base64 = function (dir, input) {
  const publ = {}
  const b64 = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='
  // http://phpjs.org/functions/base64_encode/
  publ.encode = function (data) {
    data = unescape(encodeURIComponent(data))
    if (data === '') return
    let o1
    let o2
    let o3
    let h1
    let h2
    let h3
    let h4
    let bits
    let i = 0
    let ac = 0
    let enc = ''
    const tmpArr = []
    if (!data) return data
    do {
      o1 = data.charCodeAt(i++)
      o2 = data.charCodeAt(i++)
      o3 = data.charCodeAt(i++)
      bits = o1 << 16 | o2 << 8 | o3
      h1 = bits >> 18 & 0x3f
      h2 = bits >> 12 & 0x3f
      h3 = bits >> 6 & 0x3f
      h4 = bits & 0x3f
      tmpArr[ac++] = b64.charAt(h1) + b64.charAt(h2) + b64.charAt(h3) + b64.charAt(h4)
    } while (i < data.length)
    enc = tmpArr.join('')
    let r = data.length % 3
    return (r ? enc.slice(0, r - 3) : enc) + '==='.slice(r || 3)
  }
  // http://phpjs.org/functions/base64_decode/
  publ.decode = function (data) {
    let o1
    let o2
    let o3
    let h1
    let h2
    let h3
    let h4
    let bits
    let i = 0
    let ac = 0
    let dec = ''
    const tmpArr = []
    if (!data) return data
    data += ''
    do { // unpack four hexets into three octets using index points in b64
      h1 = b64.indexOf(data.charAt(i++))
      h2 = b64.indexOf(data.charAt(i++))
      h3 = b64.indexOf(data.charAt(i++))
      h4 = b64.indexOf(data.charAt(i++))
      bits = h1 << 18 | h2 << 12 | h3 << 6 | h4
      o1 = bits >> 16 & 0xff
      o2 = bits >> 8 & 0xff
      o3 = bits & 0xff
      if (h3 === 64) {
        tmpArr[ac++] = String.fromCharCode(o1)
      } else if (h4 === 64) {
        tmpArr[ac++] = String.fromCharCode(o1, o2)
      } else {
        tmpArr[ac++] = String.fromCharCode(o1, o2, o3)
      }
    } while (i < data.length)
    dec = tmpArr.join('')
    return decodeURIComponent(escape(dec))
  }
  return input ? publ[dir](input) : dir ? null : publ
}

/**
 * 对内容加密
 * @param {[String]} mingwen 加密明文
 * @param {[int]} times 加密次数
 */
NUtil.encodeBase64 = function (mingwen, times) {
  let code = ''
  let num = 1
  if (typeof times === 'undefined' || times === null || times === '') {
    num = 1
  } else {
    let vt = times + ''
    num = parseInt(vt)
  }
  if (typeof mingwen === 'undefined' || mingwen === null || mingwen === '') {
  } else {
    NUtil.base64.utf8encode = true
    code = mingwen
    for (let i = 0; i < num; i++) {
      code = NUtil.base64('encode', code)
    }
  }
  return code
}

/**
 * 对加密内容进行解密
 * @param {[String]} mi 要解密内容
 * @param {[int]} times 解密次数
 */
NUtil.decodeBase64 = function (mi, times) {
  let mingwen = ''
  let num = 1
  if (typeof times === 'undefined' || times === null || times === '') {
    num = 1
  } else {
    let vt = times + ''
    num = parseInt(vt)
  }
  if (typeof mi === 'undefined' || mi === null || mi === '') {
  } else {
    NUtil.base64.utf8encode = true
    mingwen = mi
    for (let i = 0; i < num; i++) {
      // console.log(mingwen)
      mingwen = NUtil.base64().decode(mingwen)
    }
  }
  return mingwen
}

/**
 * 截取字符串长度
 */
NUtil.cutStr = function (text, num) {
  const arr = text.split('')
  let str = text
  if (arr.length > parseInt(num)) {
    str = arr.slice(0, num).join('') + '...'
  }
  return str
}

/**
 * URL中有一些参数（例如常用的authToken）带有特殊符号，浏览器会做转码，转码之后获取会有问题，本次遇到的是+变成了空格（%20）
 * 在对URL参数赋值之前对特殊字符做处理
 */
NUtil.URLencode = function (value) {
  return value.replace(/\+/g, '%2B').replace(/"/g, '%22').replace(/'/g, '%27').replace(/\//g, '%2F')
}

/**
 * oss图片压缩
 * flag=true是移动端
 * arr=[移动端图片宽度，PC端图片宽度]
 */
NUtil.ossImgResize = function (flag, arr) {
  return flag ? `?x-oss-process=image/resize,w_${arr[0]}` : `?x-oss-process=image/resize,w_${arr[1]}`
}

/**
 * 判断是否是微信浏览器打开
 */
NUtil.isWeiXin = function () {
  // navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
  let ua = navigator.userAgent.toLowerCase()
  // 通过正则表达式匹配ua中是否含有MicroMessenger字符串
  if (ua.indexOf('micromessenger') !== -1) {
    return true
  } else {
    return false
  }
}

/**
 * 生成随机字符串
 */
NUtil.randomString = function (len) {
  len = len || 32
  let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678' // 默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1
  let maxPos = chars.length
  let pwd = ''
  for (let i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxPos))
  }
  return pwd
}

/**
 * 把json对象处理成链接参数
 */
NUtil.json2Form = function (json) {
  var str = []
  for (var p in json) {
    str.push(encodeURIComponent(p) + '=' + encodeURIComponent(json[p]))
  }
  return str.join('&')
}


/**
 * remove Cookie
 */
NUtil.removeCookie = function (key) {
  Cookies.remove(key)
}

// 函数节流
NUtil.throttle = function (fn, gapTime) {
  if (this.isEmpty(gapTime)) {
    gapTime = 1500
  }
  let _lastTime = null
  return function (e) {
    var that = this
    let _nowTime = +new Date()
    if (_nowTime - _lastTime > gapTime || !_lastTime) {
      fn(e, that)
      _lastTime = _nowTime
    }
  }
}
NUtil.transferSecond = function (value) {
  var theTime = parseInt(value) // 秒
  var theTime1 = 0 // 分
  var theTime2 = 0 // 小时
  if (theTime > 60) {
    theTime1 = parseInt(theTime / 60)
    theTime = parseInt(theTime % 60)
    if (theTime1 > 60) {
      theTime2 = parseInt(theTime1 / 60)
      theTime1 = parseInt(theTime1 % 60)
    }
  }
  var result = '' + parseInt(theTime) + '秒'
  if (theTime1 > 0) {
    result = '' + parseInt(theTime1) + '分' + result
  }
  if (theTime2 > 0) {
    result = '' + parseInt(theTime2) + '小时' + result
  }
  return result
}
export default NUtil
