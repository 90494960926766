<template>
	<div
		class="container"
		v-loading="tableLoading"
		element-loading-text="请稍等"
		element-loading-background="rgba(0, 0, 0, 0.5)"
	>
		<!-- query bar -->
		<div class="table-query-bar">
			<el-input
				v-model="queryBar.worknum"
				placeholder="请输入管理员工号"
				size="mini"
			></el-input>
			<el-input
				v-model="queryBar.account"
				placeholder="请输入管理员账号"
				size="mini"
			></el-input>
			<el-input
				v-model="queryBar.phone"
				placeholder="请输入手机号码"
				size="mini"
			></el-input>
			<el-select
				v-model="queryBar.role"
				clearable
				placeholder="请选择角色"
				size="mini"
			>
				<el-option label="律师" :value="0"></el-option>
				<el-option label="助理" :value="1"></el-option>
				<el-option label="管理员" :value="2"></el-option>
				<el-option label="征询员" :value="3"></el-option>
			</el-select>
			<div class="e-date-wrap">
				<span class="e-date-label">最后登录时间</span>
				<el-date-picker
					v-model="queryBar.lastlogintime"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					size="mini"
					@change="servicetimeChange"
				></el-date-picker>
			</div>
			<div class="query-btn-wrap">
				<el-button type="primary" size="mini" @click="query"
					>查询</el-button
				>
				<el-button size="mini" @click="clearQuery">重置</el-button>
				<el-button
					type="primary"
					size="mini"
					@click="createDialogShow = true"
					>创建管理员</el-button
				>
			</div>
		</div>

		<!-- table -->
		<div class="table-wrap">
			<el-table :data="tableData" border style="width: 99%">
				<el-table-column
					prop="id"
					label="管理员工号"
					width="80"
				></el-table-column>
				<el-table-column
					prop="username"
					label="管理员账号"
				></el-table-column>
				<el-table-column
					prop="phone"
					label="手机号码"
				></el-table-column>
				<el-table-column prop="position" label="角色">
					<template slot-scope="s">
						<el-tag v-if="s.row.position === 0">律师</el-tag>
						<el-tag type="warning" v-else-if="s.row.position === 1">助理</el-tag>
						<el-tag type="danger" v-else-if="s.row.position === 2">管理员</el-tag>
						<el-tag type="blue" v-else-if="s.row.position === 3">征询员</el-tag>
					</template>
				</el-table-column>
				<el-table-column
					prop="roles[0].nameZh"
					label="权限"
				></el-table-column>
				<el-table-column prop="lastTime" label="最后登录时间">
					<template slot-scope="s">
						<span>{{ s.row.lastTime | timeformat }}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button
							size="mini"
							@click="rowEdit(scope.$index, scope.row)"
						>
							编辑
						</el-button>
						<el-button
							size="mini"
							type="danger"
							@click="rowDelete(scope.$index, scope.row)"
						>
							删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="table-pagination">
			<el-pagination
				@size-change="tableSizeChange"
				@current-change="tableCrtPageChange"
				:current-page="pageNum"
				:page-sizes="[10, 20, 30, 40]"
				:page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="totalSize"
			></el-pagination>
		</div>

		<!-- edit dialog -->
		<el-dialog title="编辑" :visible.sync="editDialogShow" width="30%">
			<div class="dialog-body">
				<el-form label-width="100px" :model="editDialog">
					<el-form-item label="帐号">
						<el-input v-model="editDialog.account"></el-input>
					</el-form-item>
					<el-form-item label="密码">
						<el-input
							v-model="editDialog.psw"
							clearable
							type="password"
						></el-input>
					</el-form-item>
					<el-form-item label="手机号码">
						<el-input v-model="editDialog.phone"></el-input>
					</el-form-item>
					<el-form-item label="所属角色">
						<el-select
							v-model="editDialog.role"
							placeholder="请选择所属角色"
						>
							<el-option label="律师" :value="0"></el-option>
							<el-option label="助理" :value="1"></el-option>
							<el-option label="管理员" :value="2"> </el-option>
							<el-option label="征询员" :value="3"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="权限">
						<el-select
							placeholder="请选择权限"
							v-model="editDialog.power"
						>
							<el-option
								v-for="item of powerlist"
								:key="item.id"
								:label="item.nameZh"
								:value="item.id"
							>
							</el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="editDialogShow = false">取消</el-button>
				<el-button type="primary" @click="submitEdit">确定</el-button>
			</span>
		</el-dialog>

		<!-- create dialog -->
		<el-dialog
			title="创建管理员"
			:visible.sync="createDialogShow"
			width="30%"
		>
			<div class="dialog-body">
				<el-form label-width="100px" :model="createDialog">
					<el-form-item label="帐号">
						<el-input v-model="createDialog.account"></el-input>
					</el-form-item>
					<el-form-item label="密码">
						<el-input
							v-model="createDialog.psw"
							type="password"
						></el-input>
					</el-form-item>
					<el-form-item label="手机号码">
						<el-input v-model="createDialog.phone"></el-input>
					</el-form-item>
					<el-form-item label="所属角色">
						<el-select
							v-model="createDialog.role"
							placeholder="请选择所属角色"
						>
							<el-option label="律师" :value="0"></el-option>
							<el-option label="助理" :value="1"></el-option>
							<el-option label="管理员" :value="2"> </el-option>
							<el-option label="征询员" :value="3"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="权限">
						<el-select
							placeholder="请选择权限"
							v-model="createDialog.power"
						>
							<el-option
								v-for="item of powerlist"
								:key="item.id"
								:label="item.nameZh"
								:value="item.id"
							>
							</el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="createDialogShow = false">取消</el-button>
				<el-button type="primary" @click="submitCreate">确定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import areas from "../../assets/area";

export default {
	props: {},

	data() {
		return {
			user: getItem("user"),
			tableLoading: false,
			editDialogShow: false,
			createDialogShow: false,
			// shortcuts
			pickerOptions: {
				shortcuts: [
					{
						text: "最近一周",
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(
								start.getTime() - 3600 * 1000 * 24 * 7
							);
							picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近一个月",
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(
								start.getTime() - 3600 * 1000 * 24 * 30
							);
							picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近三个月",
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(
								start.getTime() - 3600 * 1000 * 24 * 90
							);
							picker.$emit("pick", [start, end]);
						},
					},
				],
			},

			// table
			tableData: [],

			// query preset
			citylist: [],
			powerlist: [],

			// query bar
			queryBar: {
				worknum: null,
				account: null,
				phone: null,
				role: null,
				lastlogintime: null,
			},

			// dialog
			editDialog: {
				account: null,
				psw: "******",
				phone: null,
				role: null,
				power: null,
			},

			createDialog: {
				account: null,
				psw: null,
				phone: null,
				role: null,
				power: null,
			},

			// pagination
			pageNum: 1,
			pageSize: 10,
			totalSize: 0,
		};
	},

	computed: {},

	methods: {
		// query bar
		initCityList() {
			let area = [];
			for (let [key, value] of Object.entries(areas.province_list)) {
				let child = [];

				area.push({
					value: key,
					label: value,
					children: child,
				});

				for (let [innerKey, innerValue] of Object.entries(
					areas.city_list
				)) {
					if (innerKey.substring(0, 2) === key.substring(0, 2)) {
						child.push({
							value: innerKey,
							label: innerValue,
						});
					}
				}
			}
			this.citylist = area;
		},

		crtCityChange(e) {
			console.log(e);
		},

		servicetimeChange(e) {
			console.log(e);
		},

		query() {
			let opts = this.queryBar;

			this.tableLoading = true;
			let params = {
				pageSize: this.pageSize,
				pageNum: 1,
				id: opts.worknum,
				username: opts.account,
				position: opts.role,
				minLastTime: opts.lastlogintime
					? opts.lastlogintime[0].getTime()
					: null,
				maxLastTime: opts.lastlogintime
					? opts.lastlogintime[1].getTime()
					: null,
			};

			this.axios
				.get("adminUsers/getAdminList", {
					params,
				})
				.then((r) => {
					if (r.data.code === 0) {
						this.tableData = r.data.data.list;
						this.pageNum = 1;
						this.totalSize = r.data.data.total;
					}
					this.tableLoading = false;
				});
		},

		clearQuery() {
			for (let key of Object.keys(this.queryBar)) {
				this.queryBar[key] = null;
			}
		},

		// table
		tableSizeChange(e) {
			this.pageNum = 1;
			this.pageSize = e;
			this.getTableData();
		},

		tableCrtPageChange(page) {
			this.pageNum = page;
			this.getTableData();
		},

		rowEdit(index, row) {
			this.editDialog = {
				account: row.username,
				psw: "******",
                phone: row.phone,
                power: null,
				role: row.position,
				id: row.id,
			};

			try {
				this.editDialog.power = row.roles[0].id;
			} catch (error) {
				this.editDialog.power = '';
            }
            
			console.log(row);
			this.editDialogShow = true;
		},

		rowDelete(index, row) {
			this.$alert(`删除管理员帐号${row.username}，确定吗？`, {
				showCancelButton: true,
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				callback: (action) => {
					if (action === "confirm") {
						this.axios
							.post("adminUsers/deleteAdminUser", {
								id: row.id,
							})
							.then((r) => {
								if (r.data.code === 0) {
									this.$message.success("删除成功");
									this.getTableData();
								}
							});
					} else {
					}
				},
			});
		},

		submitEdit() {
			let d = this.editDialog;
            console.log(d);
			let params = {
				id: d.id,
				username: d.account,
				password: d.psw,
				phone: d.phone,
				position: d.role,
				roleIds: [d.power],
			};

			if (d.psw === "******") {
				delete params.password;
			}

			this.axios.post("adminUsers/updateAdminUser", params).then((r) => {
				if (r.data.code === 0) {
					this.getTableData();
					this.$message.success("修改已保存");
					this.editDialogShow = false;
				}
			});
		},

		submitCreate() {
			let d = this.createDialog;
			console.log(d);

			let params = {
				username: d.account,
				password: d.psw,
				phone: d.phone,
				position: d.role,
				roleIds: [d.power],
			};

			this.axios.post("adminUsers/addAdminUser", params).then((r) => {
				if (r.data.code === 0) {
					this.getTableData();
					this.$message.success("修改已保存");
					this.createDialogShow = false;
				}
			});
		},

		getTableData() {
			let opts = this.queryBar;

			this.tableLoading = true;
			let params = {
				pageSize: this.pageSize,
				pageNum: this.pageNum,
				id: opts.worknum,
				username: opts.account,
				position: opts.role,
				minLastTime: opts.lastlogintime
					? opts.lastlogintime[0].getTime()
					: null,
				maxLastTime: opts.lastlogintime
					? opts.lastlogintime[1].getTime()
					: null,
			};

			this.axios
				.get("adminUsers/getAdminList", {
					params,
				})
				.then((r) => {
					if (r.data.code === 0) {
						this.tableData = r.data.data.list;
						this.totalSize = r.data.data.total;
					}
					this.tableLoading = false;
				});
		},

		getRoleList() {
			this.axios.get("role/getRoleDropList").then((r) => {
                this.powerlist = r.data.data;
                console.log(this.powerlist);
			});
		},
	},

	created() {
		this.getTableData();
		this.getRoleList();

		this.initCityList();
	},

	mounted() {},

	destroyed() {},
};
</script>

<style scoped>
.container {
	width: 100%;
	position: relative;
	background-color: #fff;
	padding: 20px;
}

.table-query-bar {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.table-query-bar .el-input,
.table-query-bar .el-select,
.table-query-bar .el-cascader {
	width: 140px;
	margin-bottom: 20px;
	margin-right: 20px;
}

.e-date-wrap {
	margin-bottom: 20px;
	margin-right: 20px;
}

.e-date-label {
	font-size: 14px;
	color: #00567e;
	margin-right: 10px;
}

.query-btn-wrap {
	margin-bottom: 20px;
}

/* table */
.table-wrap {
	max-width: 100%;
}

.table-pagination {
	display: flex;
	justify-content: center;
	width: 100%;
	padding: 30px 0;
}

.dialog-body {
	padding: 0 10px;
}

.dialog-body .el-input,
.dialog-body .el-select {
	width: 100%;
}
</style>
