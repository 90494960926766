<template>
	<div class="container" v-loading="tableLoading" element-loading-text="请稍等" element-loading-background="rgba(0, 0, 0, 0.5)">
		<div class="header">
			<span class="pro-name">{{projectName}}</span>
			<span class="pro-city">项目地址： {{city}}{{communityName}}</span>
			<span class="pro-home">项目周期： {{prjectDetails.startTime}} ~ {{prjectDetails.endTime}}</span>
		</div>
		<!-- query bar -->
		<div class="table-query-bar">
			<el-input v-model="queryOpts.unitNumber" placeholder="请输入单元号" size="mini"></el-input>
			<el-input v-model="queryOpts.name" placeholder="请输入业主名称" size="mini"></el-input>
			<el-input v-model="queryOpts.phone" placeholder="请输入手机号码" size="mini"></el-input>
			<el-select v-model="queryOpts.voteMethod" clearable	placeholder="请选择表决方式" size="mini">
				<el-option label="全部" value=""></el-option>
				<el-option v-for="item in $keys.voteMethod" :key="item.key" :label="item.value" :value="item.key"></el-option>
			</el-select>
			<el-select v-model="queryOpts.voteFlag" clearable	placeholder="请选择表决状态" size="mini">
				<el-option label="全部" value=""></el-option>
				<el-option label="未表决" value="0"></el-option>
				<el-option label="已表决" value="1"></el-option>
			</el-select>
			<el-input v-model="queryOpts.consultUserName" placeholder="请输入征询员" size="mini"></el-input>
			<el-select v-model="queryOpts.voteNoticeStatus" clearable	placeholder="请选择短信回执状态" size="mini">
				<el-option label="全部" value=""></el-option>
				<el-option v-for="item in $keys.voteNoticeStatus" :key="item.key" :label="item.value" :value="item.key"></el-option>
			</el-select>
			<div class="e-date-wrap">
				<!-- <span class="e-date-label">表决时间</span> -->
				<el-date-picker size="mini" :default-time="['00:00:00', '23:59:59']" v-model="queryOpts.voteTime" type="daterange" range-separator="至" start-placeholder="表决开始日期" end-placeholder="表决结束日期" format='yyyy-MM-dd'></el-date-picker>
			</div>
			<div class="query-btn-wrap">
				<el-button type="primary" size="mini" @click="query">
					查询
				</el-button>
				<el-button size="mini" @click="clearQuery">重置</el-button>
			</div>
		</div>
		<!-- 按钮操作栏 -->
		<div class="filter-container">
			<el-form :inline="true" class="demo-form-inline">
			<el-row>
				<el-col :span="12">
				<div class="grid-content bg-purple">
					<el-form-item v-if="user.roles[0].name === 'ROLE_系统管理员'">
						<el-button type="primary" size="mini" @click="createDataFun">
							添加业主
						</el-button>
					</el-form-item>
					<el-form-item v-if="user.roles[0].name === 'ROLE_系统管理员'">
						<el-button type="primary" v-if="importBtnShow" size="mini" @click="handleImport">
							批量导入
						</el-button>
						<el-button type="primary" v-if="!importBtnShow" :disabled="true" size="mini">
							导入中...
						</el-button>
					</el-form-item>
					<el-form-item v-if="user.roles[0].name === 'ROLE_系统管理员'">
						<el-button type="primary" :disabled="multipleSelectionAll.length === 0 && !checkAll" size="mini" @click="deleteAll">
							批量删除
						</el-button>
					</el-form-item>
					<el-form-item v-if="user.roles[0].name === 'ROLE_系统管理员'">
						<el-button type="primary" :disabled="multipleSelectionAll.length === 0 && !checkAll" size="mini" @click="createPeopleFun">
							分配征询员
						</el-button>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" :disabled="multipleSelectionAll.length === 0 && !checkAll" size="mini" @click="sendMsgFun">
							发送短信
						</el-button>
					</el-form-item>		
				</div>
				</el-col>
				<el-col :span="12" style="text-align: right">
					<el-button type="primary" :loading="btnLoading" size="mini" @click="handleExport" :disabled="tableData.length === 0">
						导出表决明细
					</el-button>
				</el-col>
			</el-row>
			</el-form>
		</div>
		<div class="all-choose">
			<el-checkbox label="全选表格" :indeterminate="isIndeterminate" v-model="checkAll" @change="selAll"></el-checkbox>
		</div>
		<!-- table -->
		<div class="table-wrap">
			<el-table :data="tableData" ref="multipleTable" border stripe style="width: 99%" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55" fixed="left" />
				<el-table-column prop="unitNumber" label="单元号" width="120"></el-table-column>
				<el-table-column prop="name" label="业主名称" width="150">
					<template slot-scope="scope">
						<a href="javascript:;" @click="goOwner(scope.row)">{{scope.row.name}}</a>
					</template>
				</el-table-column>
				<el-table-column prop="phone" label="手机号1" width="120">
					<template slot-scope="scope">
						<el-tooltip placement="top">
							<div slot="content">点击复制</div>
							<span class="tag-read" :data-clipboard-text="scope.row.phone" @click="copy">{{scope.row.phone}}</span>  
						</el-tooltip>
					</template>
				</el-table-column>
				<el-table-column prop="sparePhone" label="手机号2" width="120">
					<template slot-scope="scope">
						<el-tooltip placement="top">
							<div slot="content">点击复制</div>
							<span class="tag-read" :data-clipboard-text="scope.row.sparePhone" @click="copy">{{scope.row.sparePhone}}</span>  
						</el-tooltip>
					</template>
				</el-table-column>
				<el-table-column prop="houseArea" label="房屋面积（m²）" width="130"></el-table-column>
				<el-table-column v-for="(item, index) in subList" :key="index" :label="item.name">
					<el-table-column v-for="(i, o) in item.formFields" :key="o" :label="i.fieldName" width="300">
						<template slot-scope="scope">
							<span v-if="!$NUtil.isEmpty(scope.row.subList[index].formFields[o].fieldValue)">{{scope.row.subList[index].formFields[o].fieldValue}}</span>
							<span v-else class="red">未表决</span>
						</template>
					</el-table-column>
				</el-table-column>
				<el-table-column prop="voteMethod" label="表决方式" width="150">
					<template slot-scope="scope">
						<span v-if="scope.row.voteMethod">{{$keys.getValue($keys.voteMethod, String(scope.row.voteMethod))}}</span>
						<span v-else>-</span>
					</template>
				</el-table-column>
				<el-table-column prop="voteTime" label="表决时间" width="150">
					<template slot-scope="scope">
						<span>{{ scope.row.voteTime | timeformat }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="voteNoticeStatus" label="表决通知短信发放情况" width="180">
					<template slot-scope="scope">
						<span v-if="scope.row.voteNoticeStatus">{{$keys.getValue($keys.voteNoticeStatus, String(scope.row.voteNoticeStatus))}}</span>
						<span v-else>-</span>
					</template>
				</el-table-column>
				<el-table-column prop="consultUserName" label="征询员" width="120"></el-table-column>
				<el-table-column label="操作" fixed="right" width="300">
					<template slot-scope="scope">
						<el-button size="mini" type="primary" @click="goOwner(scope.row)">
							表决记录
						</el-button>
						<el-button v-if="user.roles[0].name === 'ROLE_系统管理员'" size="mini" type="primary" @click="rowEdit(scope.$index, scope.row)">
							编辑业主
						</el-button>
						<el-button v-if="user.roles[0].name === 'ROLE_系统管理员'" size="mini" type="danger" @click="rowDelete(scope.$index, scope.row)">
							删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="table-pagination">
			<el-pagination @size-change="tableSizeChange" @current-change="tableCrtPageChange" :current-page="pageNum" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize"	layout="total, sizes, prev, pager, next, jumper" :total="totalSize"></el-pagination>
		</div>
		<!-- 新建业主弹框 -->
		<el-dialog :visible.sync="dialogForm.visible" :title="dialogForm.title">
			<el-form :model="dialogForm.form" ref="dialogForm" :rules="dialogForm.rules">
			<el-form-item label="单元号" :label-width="formLabelWidth" prop="unitNumber">
				<el-input v-model="dialogForm.form.unitNumber" size="mini" placeholder="如1-1011"></el-input>
			</el-form-item>
			<el-form-item label="业主名称" :label-width="formLabelWidth" prop="name">
				<el-input v-model="dialogForm.form.name" maxlength="20" size="mini" placeholder="请输入业主名称"></el-input>
			</el-form-item>
			<el-form-item label="手机号码1" :label-width="formLabelWidth" prop="phone">
				<el-input v-model="dialogForm.form.phone" size="mini" placeholder="请输入业主手机号码"></el-input>
			</el-form-item>
			<el-form-item label="手机号码2" :label-width="formLabelWidth" prop="sparePhone">
				<el-input v-model="dialogForm.form.sparePhone" size="mini" placeholder="请输入业主手机号码"></el-input>
			</el-form-item>
			<el-form-item label="房屋面积" :label-width="formLabelWidth" prop="houseArea">
				<el-input v-model="dialogForm.form.houseArea" size="mini" placeholder="100"></el-input>
			</el-form-item>
			<el-form-item label="征询员" :label-width="formLabelWidth" prop="consultUserId">
				<el-select v-model="dialogForm.form.consultUserId" clearable filterable placeholder="请选择征询员" size="mini">
					<el-option v-for="(item, index) in consultUser" :key="index" :label="item.username" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
			<el-button @click="dialogForm.visible = false" size="mini">取 消</el-button>
			<el-button type="primary" v-if="dialogForm.status" size="mini" @click="createData">确 定</el-button>
			<el-button type="primary" v-else @click="editData" size="mini">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 分配征询员弹框 -->
		<el-dialog :visible.sync="dialogForm2.visible" :title="dialogForm2.title">
			<el-form :model="dialogForm2.form" ref="dialogForm2" :rules="dialogForm2.rules">
			<el-form-item label="分配的征询员" :label-width="formLabelWidth" prop="consultUserId">
				<el-select v-model="dialogForm2.form.consultUserId" clearable filterable placeholder="请选择征询员" size="mini">
					<el-option v-for="(item, index) in consultUser" :key="index" :label="item.username" :value="item.id + '-' + item.username"></el-option>
				</el-select>
			</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
			<el-button @click="dialogForm2.visible = false">取 消</el-button>
			<el-button type="primary" @click="createConsultUser">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 导入业主弹框 -->
		<el-dialog :title="upload.title" :visible.sync="upload.open" width="400px" append-to-body>
			<el-upload el-upload ref="upload" :limit="1" accept=".xlsx, .xls" :headers="upload.headers" :action="upload.url + '?updateSupport=' + upload.updateSupport" :disabled="upload.isUploading" :on-progress="handleFileUploadProgress" :on-success="handleFileSuccess" :auto-upload="false" :on-change="fileChange" drag>
				<i class="el-icon-upload"></i>
				<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
				<div class="el-upload__tip text-center" slot="tip">
				<!-- <div class="el-upload__tip" slot="tip" style="margin-top:10px;margin-bottom:10px;">
					<el-checkbox v-model="upload.updateSupport" /> 是否更新支付通道<span style="color:red">(慎重勾选)</span>
				</div> -->
				<span>仅允许导入xls、xlsx格式文件。</span>
				<el-link type="primary" :underline="false" style="font-size:12px;vertical-align: baseline;" @click="importTemplate">下载模板</el-link>
				</div>
			</el-upload>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitFileForm">确 定</el-button>
				<el-button @click="upload.open = false">取 消</el-button>
			</div>
		</el-dialog>
		<!-- 发送短信弹框 -->
		<el-dialog :visible.sync="dialogForm3.visible" :title="dialogForm3.title">
			<el-form :model="dialogForm3.form" ref="dialogForm3" :rules="dialogForm3.rules">
				<div class="choose-box">
					<el-radio-group v-model="dialogForm3.form.tplCode" @change="tplCodeChange">
						<el-radio label="info">表决通知短信</el-radio>
						<el-radio label="noinfo">表决结果短信</el-radio>
					</el-radio-group>
				</div>
				<div class="message-box">
					<div class="msg-title">
						短信示例：
					</div>
					<div class="msg-content">
						<textarea v-model="message">
						</textarea>
					</div>
				</div>
				<div class="send-name">
					<div class="name-l">待发送业主列表</div>
					<div class="name-r">
						<div class="text">发送至：</div>
						<div class="checkbox">
							<el-checkbox-group v-model="dialogForm3.form.send">
								<el-checkbox label="sendPhoneMode">手机号1</el-checkbox>
								<el-checkbox label="sendSparePhoneMode">手机号2</el-checkbox>
							</el-checkbox-group>
						</div>
					</div>
				</div>
				<el-table :data="chooseMesgList" size="mini" border stripe style="width: 99%">
					<el-table-column prop="unitNumber" label="单元号" width="120"></el-table-column>
					<el-table-column prop="name" label="业主名称" width="120"></el-table-column>
					<el-table-column prop="phone" label="手机号1" width="120"></el-table-column>
					<el-table-column prop="sparePhone" label="手机号2" width="120"></el-table-column>
					<el-table-column prop="houseArea" label="房屋面积（m²）" width="120"></el-table-column>
					<el-table-column prop="voteNoticeStatus" label="表决通知短信发放情况" width="100">
						<template slot-scope="scope">
							{{$keys.getValue($keys.voteNoticeStatus, String(scope.row.voteNoticeStatus))}}
						</template>
					</el-table-column>
					<el-table-column label="操作" fixed="right" width="100">
						<template slot-scope="scope">
							<el-button size="mini" type="danger" v-if="scope.row.chooseShow" @click="cancelChoose(scope.$index, scope.row)">
								取消选中
							</el-button>
							<el-button size="mini" type="primary" v-if="!scope.row.chooseShow" @click="addChoose(scope.$index, scope.row)">
								选中
							</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="table-pagination">
					<el-pagination @size-change="tableSizeChange2" @current-change="tableCrtPageChange2" :current-page="pageNum2" :page-sizes="[5, 10, 15, 20]" :page-size="pageSize2"	layout="total, sizes, prev, pager, next, jumper" :total="totalSize2"></el-pagination>
				</div>
			</el-form>
			<div slot="footer" class="dialog-footer">
			<el-button @click="dialogForm3.visible = false">取 消</el-button>
			<el-button type="primary" @click="sendData" :loading="btnLoading">立即发送</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { setSelectRow, changePageCoreRecordData } from '@/utils/pageData'
import { getToken } from "@/utils/auth"
import { saveAs } from 'file-saver'
import { exportXls } from '@/utils/export'
import Clipboard from 'clipboard'

export default {
	props: {},
	data() {
		return {
			btnLoading: false,
			tableLoading: false,
			// table
			tableData: [],
			// query bar
			queryOpts: {
				unitNumber: null,
				name: null,
				phone: null,
				voteMethod: null,
				consultUserName: null,
				voteNoticeStatus: null,
				voteTime: null,
				voteFlag: null
			},
			// pagination
			pageNum: 1,
			pageSize: 10,
			totalSize: 0,
			pageNum2: 1,
			pageSize2: 5,
			totalSize2: 0,
			user: getItem("user"),
			formLabelWidth: '120px',
			dialogForm: {
				clickDis: true, // 新建按钮多次点击状态
				visible: false, // 显示隐藏
				status: true, // 是新增还是编辑 true:新增，false:编辑
				title: '添加业主',
				form: {
					unitNumber: '',
					name: '',
					phone: '',
					sparePhone: '',
					houseArea: '',
					consultUserId: ''
				},
				rules: {
					// 表单规则
					unitNumber: [
						{ required: true, message: '请填写单元号', trigger: 'change' }
					],
					name: [
						{ required: true, message: '请填写业主名称', trigger: 'change' }
					],
					phone: [
						{ required: true, message: '请填写手机号', trigger: 'change' }
					],
					houseArea: [
						{ required: true, message: '请填写房屋面积', trigger: 'change' }
					]
				}
			},
			dialogForm2: {
				clickDis: true, // 新建按钮多次点击状态
				visible: false, // 显示隐藏
				status: true, // 是新增还是编辑 true:新增，false:编辑
				title: '分配征询员',
				form: {
					consultUserId: ''
				},
				rules: {
					// 表单规则
					consultUserId: [
						{ required: true, message: '请选择征询员', trigger: 'change' }
					]
				}
			},
			dialogForm3: {
				clickDis: true, // 新建按钮多次点击状态
				visible: false, // 显示隐藏
				status: true, // 是新增还是编辑 true:新增，false:编辑
				title: '发送短信',
				form: {
					tplCode: 'info',
					send: ['sendPhoneMode']
				},
				rules: {
					// 表单规则
					tplCode: [
						{ required: true, message: '请选中短信模板', trigger: 'change' }
					],
					send: [
						{ required: true, message: '请选择发送手机号', trigger: 'change' }
					]
				}
			},
			upload: {
				// 是否显示弹出层（用户导入）
				open: false,
				// 弹出层标题（用户导入）
				title: "",
				// 是否禁用上传
				isUploading: false,
				// 是否更新已经存在的用户数据
				updateSupport: 0,
				// 设置上传的请求头部
				headers: { Authorization: "Bearer " + getToken() },
				//上传的文件
				file: null,
				// 上传的地址
				url: ""
			},
			id: '', // 项目id
			projectName: '', // 项目名称
			communityName: '', // 小区名字
			city: '',
			consultUser: [], // 业主列表
			consultUserId: '', // 业主Id
			key: 'id', // 标识列表数据中每一行的唯一键的名称
			all: false,
			subList: [],
			isIndeterminate: false, // 对el-checkbox控制不完整的全选状态
			checkAll: false, // 对el-checkbox控制全选状态
			selectedAssetsList: [], // 该数组对象
			assetsUidList: [], // 下标值
			newTabledata: [],
			chooseMesgList: [], // 选中发送短信列表
			items: [],
			tplContent: '',
			message: '',
			prjectDetails: '',
			multipleSelectionAll:[],//所有选中的数据包含跨页数据
        	selections: [], // 当前页选中的数据
			key: 'id', // 标识列表数据中每一行的唯一键的名称
			chooseIds: [], // 取消选中的ID
			importBtnShow: true, // 导入和导入中按钮显示和隐藏
			t: null
		};
	},
	computed: {},
	destroyed() { // vue离开页面清除定时器
		if (this.t) {
			clearTimeout(this.t)
		}
	},
	methods: {
		// 多选
		setSelect() {
			if (!this.multipleSelectionAll || this.multipleSelectionAll.length <= 0) {
				return
			}
			// 标识当前行的唯一键的名称
			let idKey = this.key;
			let selectAllIds = setSelectRow(this.multipleSelectionAll, idKey)
			this.$refs.multipleTable.clearSelection()
			for(var i = 0; i < this.tableData.length; i++) {
				if (selectAllIds.indexOf(this.tableData[i][idKey]) >= 0) {
				// 设置选中，记住table组件需要使用ref="table"
					this.$refs.multipleTable.toggleRowSelection(this.tableData[i], true)
				}
			}
		},
		// 记忆选择核心方法
		changePageData () {
			let selectionAll = changePageCoreRecordData(this.key,this.multipleSelectionAll,this.tableData,this.selections)
			this.multipleSelectionAll = selectionAll
			// console.log('multipleSelectionAll', this.multipleSelectionAll)
		},
		handleSelectionChange (v) {
			this.selections = v
			setTimeout(()=>{
				this.changePageData();
			}, 50)
			// console.log('selections', this.selections)
		},
		// 批量删除
		deleteAll () {
			if (this.multipleSelectionAll.length === 0) {
				this.$message.warning('请选择业主')
				return false
			}
			var data = []
			this.multipleSelectionAll.forEach((item) => {
				data.push(item.id)
			})
			this.$alert(`删除所选业主，确定吗？`, {
				showCancelButton: true,
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				callback: (action) => {
					if (action === "confirm") {
						this.axios.delete("/project/owner", {data}).then((r) => {
							if (r.data.code === 0) {
								this.$message.success("删除成功")
								this.getTableData()
							} else {
								this.$message.warning(r.data.message)
							}
						});
					} else {
					}
				},
			});
		},
		getData () {
			let opts = this.queryOpts
			let params = {
				adminUserId: this.user.id,
				projectId: this.id,
				pageNum: this.pageNum,
				pageSize: this.pageSize,
				unitNumber: opts.unitNumber,
				name: opts.name,
				phone: opts.phone,
				voteMethod: opts.voteMethod,
				consultUserName: opts.consultUserName,
				voteNoticeStatus: opts.voteNoticeStatus,
				minVoteTime: opts.voteTime ? opts.voteTime[0].getTime() : null,
				maxVoteTime: opts.voteTime ? opts.voteTime[1].getTime() : null
			}
			return JSON.stringify(params)
		},
		copy() {
			var clipboard = new Clipboard('.tag-read')  
            clipboard.on('success', e => {  
				this.$message.success("已复制到剪切板！")
                // 释放内存  
                clipboard.destroy()  
            })  
            clipboard.on('error', e => {  
				// 不支持复制  
				console.log('该浏览器不支持自动复制')  
				// 释放内存  
				clipboard.destroy()  
			})
		},
		tplCodeChange() {
			if (this.dialogForm3.form.tplCode === 'info') {
				this.message = this.tplContent
			} else {
				this.message = ''
			}
		},
		// 导出
		handleExport() {
			this.btnLoading = true
			let opts = this.queryOpts;
			let params = {
				adminUserId: this.user.id,
				projectId: this.id,
				pageNum: this.pageNum,
				pageSize: this.pageSize,
				unitNumber: opts.unitNumber,
				name: opts.name,
				phone: opts.phone,
				voteMethod: opts.voteMethod,
				voteFlag: opts.voteFlag,
				consultUserName: opts.consultUserName,
				voteNoticeStatus: opts.voteNoticeStatus,
				minVoteTime: opts.voteTime ? opts.voteTime[0].getTime() : null,
				maxVoteTime: opts.voteTime ? opts.voteTime[1].getTime() : null
			};
			// console.log(params)
			this.axios.get("/project/owner/export", { params }).then(res => {
				this.btnLoading = false
				console.log('res , ' + JSON.stringify(res))
				let date = new Date()
				let year = date.getFullYear()
				let month = date.getMonth() + 1
				let day = date.getDate()
				this.downloadFile('export', res.data.data, `【${this.communityName}】${year}-${month}-${day} .xlsx`);
				this.$message.success("导出成功！")
			});
		},
		// 发送短信
		sendData() {
			this.$refs['dialogForm3'].validate(valid => {
				if (valid) {
					this.btnLoading = true
					let form = this.dialogForm3.form
					let sendPhoneMode = false
					let sendSparePhoneMode = false
					this.dialogForm3.form.send.forEach(element => {
						if (element === 'sendPhoneMode') {
							sendPhoneMode = true
						} else {
							sendSparePhoneMode = true
						}
					})
					let ownerIds = []
					let data = {}
					if (!this.checkAll) {
						// 非全选
						this.chooseIds.forEach((item) => {
							this.multipleSelectionAll.forEach((it, index) => {
								if (item === it.id) {
									this.multipleSelectionAll.splice(index, 1)
								}
							})
						})
						if (this.multipleSelectionAll.length === 0) {
							this.$message.warning('请选择业主')
							return false
						}
						this.multipleSelectionAll.forEach(element => {
							ownerIds.push(element.id)
						})
						data = {
							tplCode: form.tplCode,
							tplContent: this.message,
							sendPhoneMode: sendPhoneMode,
							sendSparePhoneMode: sendSparePhoneMode,
							ownerIds: ownerIds,
							// excludeOwnerIds: this.chooseIds,
							projectOwner: {
								projectId: this.id
							}
						}
					} else {
						data = {
							tplCode: form.tplCode,
							tplContent: this.message,
							sendPhoneMode: sendPhoneMode,
							sendSparePhoneMode: sendSparePhoneMode,
							// ownerIds: ownerIds,
							excludeOwnerIds: this.chooseIds,
							projectOwner: {
								projectId: this.id
							}
						}
					}
					console.log(data)
					this.axios.post("/project/sms/record", data).then((r) => {
						if (r.data.code === 0) {
							this.btnLoading = false
							this.$message.success("发送短信成功！")
							this.dialogForm3.visible = false
							this.getTableData()
						} else {
							this.$message.warning(r.data.message)
							this.btnLoading = false
						}
					});
				}
			})
		},
		// 取消选中发短信的列表
		cancelChoose(index, rows) {
			rows.chooseShow = false
			this.chooseIds.push(rows.id)
			console.log(this.chooseIds)
			// this.chooseMesgList.splice(index, 1)
			// if (!this.checkAll) {
			// 	// 非全选
			// 	this.multipleSelectionAll.splice(index, 1)
			// 	this.pageNum2 = 1
			// 	this.pageSize2 = 5
			// 	this.getsendMsgTableData()
			// } else {
			// 	this.totalSize2--
			// 	console.log(this.chooseIds)
			// }
		},
		addChoose (index, rows) {
			rows.chooseShow = true
			this.chooseIds.forEach((item, index) => {
				if (item === rows.id) {
					this.chooseIds.splice(index, 1)
				}
			})
			console.log(this.chooseIds)
		},
		// 全选按键功能实现
		selAll() {
			// console.log(this.checkAll)
			// if (this.$refs.multipleTable.selection.length < this.newTabledata.length) {
			// 	// 判断勾选的数据是否是 大于 全选的数据  ，如果 小于则 让 全选 等于 true 否则为 false
			// 	this.checkAll = true
			// } else {
			// 	this.checkAll = false
			// }
			if (this.checkAll) {
				this.tableData.forEach(row => {
					this.$refs.multipleTable.toggleRowSelection(row, true)
				})
			} else {
				this.$refs.multipleTable.clearSelection()
				this.selectedAssetsList = []
				this.assetsUidList = []
			}
		},
		// 跳转业主详页面
		goOwner(row) {
			this.$router.push({ path: '/home/owner', query: { id: row.id, data: this.getData() } })
		},
		// 清空数据
		resetDialogForm () {
			// 将弹窗表单清空
			this.dialogForm.form = {
				unitNumber: '',
				name: '',
				phone: '',
				sparePhone: '',
				houseArea: '',
				consultUserId: ''
			}
			this.dialogForm2.form = {
				consultUserId: ''
			}
			this.dialogForm3.form = {
				tplCode: 'info',
				send: ['sendPhoneMode']
			}
		},
		// 添加业主弹框
		createDataFun() {
			this.dialogForm.clickDis = true
			this.dialogForm.visible = true
			this.dialogForm.status = true
			this.dialogForm.title = '添加业主'
			this.getUsersData()
			this.resetDialogForm()
			this.$nextTick(() => {
				this.$refs['dialogForm'].clearValidate()
			})
		},
		// 分配征询员弹框
		createPeopleFun() {
			this.dialogForm2.clickDis = true
			this.dialogForm2.visible = true
			this.dialogForm2.status = true
			this.dialogForm2.title = '分配征询员'
			this.resetDialogForm()
			this.getUsersData()
			this.$nextTick(() => {
				this.$refs['dialogForm2'].clearValidate()
			})
		},
		// 发送短信
		sendMsgFun() {
			this.dialogForm3.clickDis = true
			this.dialogForm3.visible = true
			this.dialogForm3.status = true
			this.dialogForm3.title = '发送短信'
			this.resetDialogForm()
			this.getsendMsgTableData()
			this.$nextTick(() => {
				this.$refs['dialogForm3'].clearValidate()
			})
		},
		// 添加业主
		createData() {
			this.$refs['dialogForm'].validate(valid => {
        		if (valid) {
					let params = {
						projectId: this.id,
						unitNumber: this.dialogForm.form.unitNumber,
						name: this.dialogForm.form.name,
						phone: this.dialogForm.form.phone,
						sparePhone: this.dialogForm.form.sparePhone,
						houseArea: this.dialogForm.form.houseArea,
						consultUserId: this.dialogForm.form.consultUserId
					}
					this.axios.post("/project/owner", params).then((r) => {
						if (r.data.code === 0) {
							this.$message.success("成功新增业主")
							this.dialogForm.visible = false
							this.getTableData()
						} else {
							this.$message.warning(r.data.message)
						}
					})
				}
			})
		},
		// 修改业主弹窗
		rowEdit(index, row) {
			this.axios.get("/project/owner/" + row.id).then((r) => {
				this.consultUserId = row.id
				this.getUsersData()
				this.dialogForm.status = false
				// console.log(r)
				let data = r.data.data
				this.dialogForm.form.unitNumber = data.unitNumber
				this.dialogForm.form.name = data.name
				this.dialogForm.form.phone = data.phone
				this.dialogForm.form.sparePhone = data.sparePhone
				this.dialogForm.form.houseArea = data.houseArea
				this.dialogForm.form.consultUserId = data.consultUserId
				setTimeout(() => {
					this.dialogForm.visible = true
				}, 100)
			})
		},
		// 修改业主数据
		editData () {
			this.$refs['dialogForm'].validate(valid => {
        		if (valid) {
					let params = {
						id: this.consultUserId,
						projectId: this.id,
						unitNumber: this.dialogForm.form.unitNumber,
						name: this.dialogForm.form.name,
						phone: this.dialogForm.form.phone,
						sparePhone: this.dialogForm.form.sparePhone,
						houseArea: this.dialogForm.form.houseArea,
						consultUserId: this.dialogForm.form.consultUserId
					}
					this.axios.put("/project/owner", params).then((r) => {
						if (r.data.code === 0) {
							this.$message.success("修改项目成功")
							this.dialogForm.visible = false
							this.getTableData()
						} else {
							this.$message.warning(r.data.message)
						}
					});
				}
			})
		},
		// 导入按钮操作
		handleImport() {
			this.upload.title = "批量导入"
			this.upload.open = true
		},
		// 文件上传中处理
		handleFileUploadProgress(event, file, fileList) {
			this.upload.isUploading = true;
		},
		// 文件上传成功处理
		handleFileSuccess(response, file, fileList) {
			this.upload.open = false;
			this.upload.isUploading = false;
			this.$refs.upload.clearFiles();
			//this.$alert(response.msg, "导入结果", { dangerouslyUseHTMLString: true });
			this.getTableData();
		},
		fileChange(file){
			this.upload.file = file
		},
		// 提交上传文件
		submitFileForm() {
			if(this.upload.file === null){
				this.$message.warning("请上传文件");
				return
			}
			const formData = new FormData()
			formData.append('file',  this.upload.file.raw)
			formData.append('updateSupport', this.upload.updateSupport)
			this.uploadAndDownload(this.upload.url,formData,this.upload.file.name)
			//this.$refs.upload.submit();
			this.upload.file = null
			this.$refs.upload.clearFiles();
		},
		// 下载模板操作
		importTemplate() {
			this.downloadFile('template', 'project_owner_import.xlsx', '表决业主名单批量导入模板.xlsx');
		},
		downloadFile(module, fileName, rename){
			this.axios.get("/project/owner/download?module=" + module + "&fileName=" + fileName, 
				{ headers: { 'Content-Type':  'multipart/form-data' },responseType: 'blob'}).then(res => {
				exportXls(res.data, rename)
			});
		},
		// 通用上传下载方法
		uploadAndDownload(url, params, filename) {
			return this.axios.post(url, params, { headers: { 'Content-Type':  'multipart/form-data' },responseType: 'blob'})
				.then(async (data) => {
					const blob = new Blob([data])
					// saveAs(blob, "Result_"+filename)
					this.getTableData()
					this.upload.open = false
					this.importBtnShow = false
					this.getImportStatus()
			}).catch((r) => {
				console.error(r)
				Message.error('上载文件出现错误，请联系管理员！')
			})
		},
		// 查询列表
		query() {
			let opts = this.queryOpts;
			let params = {
				adminUserId: this.user.id,
				projectId: this.id,
				pageNum: this.pageNum,
				pageSize: this.pageSize,
				unitNumber: opts.unitNumber,
				name: opts.name,
				phone: opts.phone,
				voteMethod: opts.voteMethod,
				voteFlag: opts.voteFlag,
				consultUserName: opts.consultUserName,
				voteNoticeStatus: opts.voteNoticeStatus,
				minVoteTime: opts.voteTime ? opts.voteTime[0].getTime() : null,
				maxVoteTime: opts.voteTime ? opts.voteTime[1].getTime() : null
			};
			this.tableLoading = true;
			this.axios.get("/project/owner/list", {	params }).then((r) => {
				if (r.data.code === 0) {
					this.tableData = r.data.rows;
					this.totalSize = r.data.total;
					this.subList = (this.tableData[0] && this.tableData[0]['subList']) || []
					setTimeout(()=>{
						this.setSelect()
					}, 50)
				}
				this.tableLoading = false;
			})
		},
		// 清除查询
		clearQuery() {
			for (let key of Object.keys(this.queryOpts)) {
				this.queryOpts[key] = null;
			}
		},
		// table event
		tableSizeChange(e) {
			this.pageNum = 1;
			this.pageSize = e; // pageSize
			this.getTableData();
		},
		tableCrtPageChange(page) {
			this.pageNum = page; // currentPage
			this.getTableData();
		},
		tableSizeChange2(e) {
			this.pageNum2 = 1;
			this.pageSize2 = e; // pageSize
			this.getsendMsgTableData();
		},
		tableCrtPageChange2(page) {
			this.pageNum2 = page; // currentPage
			this.getsendMsgTableData();
		},
		// 选中发送短信列表
		getsendMsgTableData() {
			if (!this.checkAll) {
				// 非全选
				this.multipleSelectionAll = this.multipleSelectionAll
				this.chooseMesgList = this.multipleSelectionAll.slice((this.pageNum2 - 1) * this.pageSize2, this.pageNum2 * this.pageSize2)
				this.chooseMesgList.forEach((item) => {
					this.$set(item, 'chooseShow', true)
					this.chooseIds.forEach((it) => {
						if (item.id === it) {
							this.$set(item, 'chooseShow', false)
						}
					})
				})
				this.totalSize2 = this.multipleSelectionAll.length
			}  else {
				// 全选
				let opts = this.queryOpts;
				let params = {
					adminUserId: this.user.id,
					projectId: this.id,
					pageNum: this.pageNum2,
					pageSize: this.pageSize2,
					unitNumber: opts.unitNumber,
					name: opts.name,
					phone: opts.phone,
					voteFlag: opts.voteFlag,
					voteMethod: opts.voteMethod,
					consultUserName: opts.consultUserName,
					voteNoticeStatus: opts.voteNoticeStatus,
					minVoteTime: opts.voteTime ? opts.voteTime[0].getTime() : null,
					maxVoteTime: opts.voteTime ? opts.voteTime[1].getTime() : null
				};
				this.tableLoading = true;
				this.axios.get("/project/owner/list", {	params }).then((r) => {
					if (r.data.code === 0) {
						this.chooseMesgList = r.data.rows;
						this.chooseMesgList.forEach((item) => {
							this.$set(item, 'chooseShow', true)
							this.chooseIds.forEach((it) => {
								if (item.id === it) {
									this.$set(item, 'chooseShow', false)
								}
							})
						})
						// console.log('chooseMesgList', this.chooseMesgList)
						this.totalSize2 = r.data.total;
					} else {
						this.$message.warning(r.data.message)
					}
					this.tableLoading = false;
				});
			}
			// console.log('chooseMesgList', this.chooseMesgList)
			// console.log('selectedAssetsList', this.selectedAssetsList)
		},
		rowDelete(index, row) {
			this.$alert(`删除业主${row.name}，确定吗？`, {
				showCancelButton: true,
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				callback: (action) => {
					if (action === "confirm") {
						let data = [row.id]
						this.axios.delete("/project/owner", {data}).then((r) => {
							if (r.data.code === 0) {
								this.$message.success("删除成功");
								this.getTableData();
							} else {
								this.$message.warning(r.data.message)
							}
						});
					} else {
					}
				},
			});
		},
		// table
		getTableData() {
			let opts = this.queryOpts;
			let params = {
				adminUserId: this.user.id,
				projectId: this.id,
				pageNum: this.pageNum,
				pageSize: this.pageSize,
				unitNumber: opts.unitNumber,
				name: opts.name,
				phone: opts.phone,
				voteMethod: opts.voteMethod,
				voteFlag: opts.voteFlag,
				consultUserName: opts.consultUserName,
				voteNoticeStatus: opts.voteNoticeStatus,
				minVoteTime: opts.voteTime ? opts.voteTime[0].getTime() : null,
				maxVoteTime: opts.voteTime ? opts.voteTime[1].getTime() : null
			};
			this.tableLoading = true;
			this.axios.get("/project/owner/list", {	params }).then((r) => {
				if (r.data.code === 0) {
					this.tableData = r.data.rows;
					this.totalSize = r.data.total;
					this.subList = (this.tableData[0] && this.tableData[0]['subList']) || []
					setTimeout(()=>{
						this.setSelect()
					}, 50)
				} else {
					this.$message.warning(r.data.message)
				}
				this.tableLoading = false;
			});
		},
		// 获取项目详情
		getProjectDetil(){
			this.axios.get("/project/" + this.id).then((r) => {
				if (r.data.code === 0) {
					this.projectName = r.data.data.projectName
					this.communityName = r.data.data.communityName
					this.items = r.data.data.items
					this.city = r.data.data.province + r.data.data.city
					this.tplContent = r.data.data.tplContent
					this.message = r.data.data.tplContent
					this.prjectDetails = r.data.data
				} else {
					this.$message.warning(r.data.message)
				}
			})
		},
		// 获取征询员列表
		getUsersData() {
			let params = {
				position: 3,
				pageNum: 1,
				pageSize: 30000
			};
			this.axios.get("/adminUsers/getAdminList", {	params }).then((r) => {
				if (r.data.code === 0) {
					this.consultUser = r.data.data.list
				} else {
					this.$message.warning(r.data.message)
				}
			});
		},
		createConsultUser () {
			this.$refs['dialogForm2'].validate(valid => {
				if (valid) {
					let consultUser = this.dialogForm2.form.consultUserId.split('-')
					let ownerIds = []
					this.multipleSelectionAll.forEach((item) => {
						ownerIds.push(item.id)
					})
					let opts = this.queryOpts
					let projectOwner = {
						projectId: this.id,
						unitNumber: opts.unitNumber,
						name: opts.name,
						phone: opts.phone,
						voteMethod: opts.voteMethod,
						consultUserName: opts.consultUserName,
						voteNoticeStatus: opts.voteNoticeStatus,
						minVoteTime: opts.voteTime ? opts.voteTime[0].getTime() : null,
						maxVoteTime: opts.voteTime ? opts.voteTime[1].getTime() : null
					}
					let data = {}
					if (this.checkAll === true){
						// 全选
						data = {
							all: true,
							consultUserId: consultUser[0],
							consultUserName: consultUser[1],
							projectOwner: projectOwner
						}
					} else {
						// 非全选
						data = {
							all: false,
							ownerIds: ownerIds,
							consultUserId: consultUser[0],
							consultUserName: consultUser[1],
							projectOwner: projectOwner
						}
					}
					this.axios.put("/project/owner/distribute", data).then((r) => {
						if (r.data.code === 0) {
							this.$message.success("成功分配征询员")
							this.dialogForm2.visible = false
							this.getTableData()
						} else {
							this.$message.warning(r.data.message)
						}
					});
				}
			})
		},
		getImportStatus () {
			this.axios.get("/project/" + this.id).then((r) => {
				if (r.data.code === 0) {
					let importStatus = r.data.data.importStatus
					console.log('importStatus', importStatus)
					if (r.data.data.importStatus === 0) {
						this.t = setTimeout(() => {
							this.getImportStatus()
						}, 3000)
					} else if (r.data.data.importStatus === 1) {
						this.importBtnShow = true
						clearTimeout(this.t)
						this.$message.warning("导入完成！")
					} else {
						this.importBtnShow = true
						clearTimeout(this.t)
						this.$message.warning("导入失败！")
					}
				} else {
					this.$message.warning(r.data.message)
				}
			})
		}
	},
	created() {
		this.id = this.$route.query.id
		if (!this.$NUtil.isEmpty(this.$route.query.data)) {
			let data = JSON.parse(this.$route.query.data)
			this.queryOpts = {
				unitNumber: data.unitNumber,
				name: data.name,
				phone: data.phone,
				voteMethod: data.voteMethod,
				consultUserName: data.consultUserName,
				voteNoticeStatus: data.voteNoticeStatus,
				voteTime: this.$NUtil.isEmpty(data.minVoteTime) ? null : [new Date(data.minVoteTime), new Date(data.maxVoteTime)]
			}
			console.log('queryOpts', this.queryOpts)
		}
		if (this.id) {
			this.getProjectDetil()
		}
		this.getTableData()
		this.upload.url = '/project/owner/' + this.id + '/import'
		// console.log('ROLE_系统管理员', this.user.roles[0].name)
	}
};
</script>

<style scoped lang="scss">
.message-box{
	background-color: #eee;
	border-radius: 5px;
	padding: 10px;
	margin: 20px auto 0;
}
.message-box .msg-title{
	font-size: 16px;
	font-weight: bold;
	margin-bottom: 10px;
}
.message-box .msg-content textarea{
	width: 100%;
	height: 200px;
	padding: 5px;
	border-radius: 5px;
	background-color: #eee;
}
.message-box .msg-content p{
	margin-top: 10px;
	color: red;
}
.container {
	width: 100%;
	position: relative;
	background-color: #fff;
	padding: 20px;
}

.table-query-bar {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.table-query-bar .el-input,
.table-query-bar .el-select,
.table-query-bar .el-cascader {
	width: 140px;
	margin-bottom: 20px;
	margin-right: 20px;
}

.e-date-wrap {
	margin-bottom: 20px;
	margin-right: 20px;
}

.e-date-label {
	font-size: 14px;
	color: #00567e;
	margin-right: 10px;
}

.query-btn-wrap {
	margin-bottom: 20px;
}

/* table */
.table-wrap {
	max-width: 100%;
}

.table-pagination {
	display: flex;
	justify-content: center;
	width: 100%;
	padding: 30px 0;
}

/* order dialog */
/deep/.el-dialog__body {
	padding: 10px 20px;
}

.order-read-only {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.or-wrap-left,
.or-wrap-right {
	width: 350px;
}

.or-label-wrap {
	display: flex;
	align-items: center;
	margin-bottom: 15px;
}

.or-label-content {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 20px;
}

.edit-bar {
	width: 100%;
	display: flex;
	margin-bottom: 15px;
}

.edit-bar-label {
	min-width: 70px;
	margin-right: 20px;
}

.askimg-bar {
	width: 100%;
	padding: 0 90px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.el-image {
	margin-right: 15px;
	margin-bottom: 15px;
}

.des-bar {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	height: 40px;
}

.des-bar-left {
	display: flex;
	align-items: center;
}

.des-bar-label {
	margin-right: 20px;
}

.des-bar-content {
	color: #ff5c5c;
}

/* tele dialog */
.tel-dialog-body {
	width: 100%;
	height: 100%;
}

.tel-dialog-body .el-form {
	display: flex;
	flex-wrap: wrap;
}

.tel-dialog-body .el-form-item {
	width: 50%;
	display: flex;
	/* justify-content: center; */
	padding-left: 40px;
}

.tel-dialog-body .el-select {
	max-width: 202px;
}

.tel-dialog-textarea {
	display: flex;
	width: 100%;
	padding-left: 40px;
	padding-right: 30px;
	margin-bottom: 20px;
}

.tel-dia-label {
	min-width: 68px;
}

.tel-dialog-textarea .el-textarea {
	max-width: 700px;
}
.title{
	margin: 15px 0;
	padding-left: 15px;
	border-left: 3px solid #66b1ff;
	overflow: hidden;
	line-height: 30px;
}
.name{
	padding: 15px 0;
	font-size: 14px;
}
.fr{
	float: right;
}
.fl{
	float: left;
}
.header{
	padding-bottom: 25px;
	.pro-name{
		font-size: 24px;
		margin-right: 20px;
	}
	.pro-city{
		font-size: 12px;
		color: #343434;
		margin-right: 20px;
	}
	.pro-home{
		font-size: 12px;
		color: #343434;
	}
}
.choose-box{
	text-align: center;
}
.all-choose{
	padding: 20px 0;
}
.red{
	color: #ff5c5c;
}
.send-name{
	display: flex;
	justify-content: space-between;
	padding: 30px 0 10px;
}
.send-name .name-r{
	display: flex;
	justify-content: flex-start;
}
</style>
