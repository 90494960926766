export function setSelectRow(selectionAll,idKey) { 
  let selectAllIds = []; 
  let that = this; 
  selectionAll.forEach(row=>{
     selectAllIds.push(row[idKey]);
  }) 
  return selectAllIds; 
 }
// 记忆选择核心方法 
// 标识当前行的唯一键的名称 idKey
// 所有选中的数据包含跨页数据 selectionAll
// 接口获取的数据 data
// 当前页选中的数据 selections
export function changePageCoreRecordData (idKey,selectionAll,data,selections) {
  // 如果总记忆中还没有选择的数据，那么就直接取当前页选中的数据，不需要后面一系列计算 
  if (selectionAll.length <= 0) {
    selectionAll = selections; 
    return selectionAll; 
  } 
  // 总选择里面的id集合 
  let selectAllIds = []
  selectionAll.forEach(row=>{
    selectAllIds.push(row[idKey])
  }) 
  let selectIds = [] 
  // 获取当前页选中的id 
  selections.forEach(row=>{
    selectIds.push(row[idKey])
    // 如果总选择里面不包含当前页选中的数据，那么就加入到总选择集合里
    if (selectAllIds.indexOf(row[idKey]) < 0) {
      selectionAll.push(row)
    }
  })
  let noSelectIds = [] 
  // 得到当前页没有选中的id 
  data.forEach(row=>{
    if (selectIds.indexOf(row[idKey]) < 0) {
      noSelectIds.push(row[idKey])
    }
  }) 
  noSelectIds.forEach(id=>{
    if (selectAllIds.indexOf(id) >= 0) {
      for(let i = 0; i< selectionAll.length; i ++) {
        if (selectionAll[i][idKey] == id) {
          // 如果总选择中有未被选中的，那么就删除这条
          selectionAll.splice(i, 1)
          break
        }
      }
    }
  })
  return selectionAll
}
