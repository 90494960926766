<template>
	<div class="page">
		<el-container>
			<side-menu @menu-change="menuChange"></side-menu>
			<div class="right-container">
				<e-header :title="crtTitle" :user="user"></e-header>
				<el-main>
					<router-view />
				</el-main>
				<!-- <e-footer></e-footer> -->
			</div>
		</el-container>
	</div>
</template>

<script>
import side from "../components/side-menu";
import header from "../components/header";

export default {
	name: "home",
	components: {
		"side-menu": side,
		"e-header": header,
	},
	data() {
		return {
			crtTitle: "",
			user: null,
		};
	},

	computed: {},

	methods: {
		menuChange(routeName) {
			let label = "";
			switch (routeName) {
				case "order":
					label = "工单管理";
					break;
				case "community":
					label = "小区管理";
					break;
				case "tele":
					label = "电话管理";
					break;
				case "users":
					label = "用户管理";
					break;
				case "roles":
					label = "账号管理";
					break;
				case "project":
					label = "表决管理";
					break;
				case "authority":
					label = "权限管理";
					break;
				default:
					break;
			}
			this.crtTitle = label;
		},
	},

	created() {
		this.user = getItem("user");
	},

	mounted() {},
};
</script>

<style scoped>
.el-header {
	background-color: #fff;
}

.el-main {
	background-color: #f7f7f7;
	flex-grow: 1;
	width: 100%;
	padding-bottom: 50px;
}

.el-container {
	height: 100vh;
}

.right-container {
	width: 87%;
	height: 100%;
	display: flex;
	flex-direction: column;
}
</style>
