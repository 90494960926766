<template>
    <div class="container">
        欢迎光临法库后台管理系统
    </div>
</template>

<script>
    export default {
        props: {},

        data(){
            return {
                
            }
        },

        computed: {},

        methods: {},

        created(){},

        mounted(){},

        destroyed(){},
    }
</script>

<style scoped>
.container {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>