<template>
  <div
    class="container"
    v-loading="tableLoading"
    element-loading-text="请稍等"
    element-loading-background="rgba(0, 0, 0, 0.5)"
  >
    <!-- query bar -->
    <div class="table-query-bar">
      <el-input
        v-model="queryBar.id"
        placeholder="请输入用户编号"
        size="mini"
      ></el-input>
      <el-input
        v-model="queryBar.name"
        placeholder="请输入用户名称"
        size="mini"
      ></el-input>
      <el-input
        v-model="queryBar.phone"
        placeholder="请输入手机号码"
        size="mini"
      ></el-input>
      <el-cascader
        v-model="queryBar.city"
        :options="citylist"
        placeholder="请选择省市"
        size="mini"
        :props="{ expandTrigger: 'hover' }"
      ></el-cascader>
      <el-select
        v-model="queryBar.refer"
        clearable
        placeholder="请选择来源渠道"
        size="mini"
      >
        <el-option label="官方渠道" :value="0"></el-option>
        <el-option label="万家社区" :value="1"></el-option>
        <el-option label="表决项目导入" :value="2"></el-option>
      </el-select>
      <div class="e-date-wrap">
        <span class="e-date-label">注册时间</span>
        <el-date-picker
          v-model="queryBar.createtime"
          type="datetimerange"
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          align="right"
          size="mini"
          @change="servicetimeChange"
        ></el-date-picker>
      </div>
      <div class="query-btn-wrap">
        <el-button type="primary" size="mini" @click="query">查询</el-button>
        <el-button size="mini" @click="queryClear">重置</el-button>
      </div>
    </div>

    <!-- table -->
    <div class="table-wrap">
      <el-table :data="tableData" border style="width: 99%">
        <el-table-column
          prop="id"
          label="用户编号"
          width="80"
        ></el-table-column>
        <el-table-column prop="avatar" label="用户头像" width="95">
          <template slot-scope="s">
            <el-image
              style="width: 70px; height: 70px"
              :src="s.row.avatar ? s.row.avatar : '../../assets/img/avator.png'"
              :preview-src-list="[s.row.avatar]"
              v-if="s.row.avatar"
            >
            </el-image>
            <img
              v-else
              src="../../assets/img/avator.png"
              style="width: 70px; height: 70px"
            />
          </template>
        </el-table-column>
        <el-table-column prop="nickname" label="用户昵称"></el-table-column>
        <el-table-column prop="phone" label="手机号码"></el-table-column>
        <el-table-column prop="city" label="所在城市">
          <template slot-scope="s">
            <span>{{ s.row.provinceName + s.row.cityName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="communityName"
          label="所在小区"
        ></el-table-column>

        <el-table-column prop="unitNumber" label="门牌号"></el-table-column>
        <el-table-column prop="houseArea" label="房屋面积（m²）"></el-table-column>
        <el-table-column prop="sparePhone" label="手机号码2"></el-table-column>

        <el-table-column prop="from" label="来源渠道" width="95">
          <template slot-scope="e">
            <el-tag v-if="e.row.from === 0">官方渠道</el-tag>
            <el-tag type="warning" v-if="e.row.from === 1">万家社区</el-tag>
            <el-tag type="blue" v-if="e.row.from === 2">表决项目导入</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="registereTime" label="注册时间">
          <template slot-scope="s">
            <span>{{ s.row.registereTime | timeformat }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="rowEdit(scope.$index, scope.row)">
              编辑
            </el-button>
            <el-button
              size="mini"
              type="danger"
              @click="rowDelete(scope.$index, scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="table-pagination">
      <el-pagination
        @size-change="tableSizeChange"
        @current-change="tableCrtPageChange"
        :current-page="pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalSize"
      ></el-pagination>
    </div>

    <!-- edit dialog -->
    <el-dialog title="编辑用户信息" :visible.sync="editDialogShow" width="30%">
      <div class="dialog-body">
        <el-form label-width="100px" :model="editDialog">
          <el-form-item label="用户昵称">
            <el-input v-model="editDialog.nickname"></el-input>
          </el-form-item>
          <el-form-item label="手机号码">
            <el-input v-model="editDialog.phone"></el-input>
          </el-form-item>
          <el-form-item label="所在城市">
            <el-cascader
              v-model="editDialog.city"
              :options="citylist"
              @change="crtCityChange"
              placeholder="请选择省市"
              :props="{ expandTrigger: 'hover' }"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="所在社区">
            <el-select
              v-model="editDialog.community"
              placeholder="请选择所在社区"
              v-loading="communityLoading"
            >
              <el-option
                v-for="item of communitylist"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogShow = false">取消</el-button>
        <el-button type="primary" @click="submitEdit">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import areas from "../../assets/area";

export default {
  props: {},

  data() {
    return {
      user: getItem("user"),
      editDialogShow: false,
      tableLoading: false,
      communityLoading: false,
      // shortcuts
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },

      // table
      tableData: [],

      // query preset
      citylist: [],
      communitylist: [],

      // query bar
      queryBar: {
        id: null,
        name: null,
        phone: null,
        city: null,
        refer: null,
        createtime: null,
      },

      //dialog
      editDialog: {
        nickname: null,
        phone: null,
        city: null,
        community: null,
      },

      // pagination
      pageNum: 1,
      pageSize: 10,
      totalSize: 0,
    };
  },

  computed: {},

  methods: {
    // query
    initCityList() {
      let area = [];
      for (let [key, value] of Object.entries(areas.province_list)) {
        let child = [];

        area.push({
          value: key,
          label: value,
          children: child,
        });

        for (let [innerKey, innerValue] of Object.entries(areas.city_list)) {
          if (innerKey.substring(0, 2) === key.substring(0, 2)) {
            child.push({
              value: innerKey,
              label: innerValue,
            });
          }
        }
      }
      this.citylist = area;
    },

    getCommunity() {
      this.axios.get("community/getCommunities").then((r) => {
        this.communitylist = r.data.data;
      });
    },

    crtCityChange(e) {
      // console.log(e[1]);
      this.communityLoading = true;
      this.communitylist = [];
      this.editDialog.community = null;

      let params = {
        cityCode: e[1].substring(0, 4),
      };

      this.axios
        .get("community/getCommunityListByCity", { params })
        .then((r) => {
          // console.log(r.data);
          this.communityLoading = false;
          this.communitylist = r.data.data;
        });
    },

    servicetimeChange(e) {
      console.log(e);
    },

    queryClear() {
      for (let key of Object.keys(this.queryBar)) {
        this.queryBar[key] = null;
      }
    },

    query() {
      let opts = this.queryBar;
      console.log(opts);
      let params = {
        adminUserId: this.user.id,
        pageNum: 1,
        pageSize: this.pageSize,
        id: opts.id,
        nickname: opts.name,
        phone: opts.phone,
        cityCode: opts.city ? opts.city[1].substring(0, 4) : null,
        from: opts.refer,
        minRegistereTime: opts.createtime ? opts.createtime[0].getTime() : null,
        maxRegistereTime: opts.createtime ? opts.createtime[1].getTime() : null,
      };

      this.tableLoading = true;
      this.axios
        .get("user/getUserList", {
          params,
        })
        .then((r) => {
          console.log(r.data);
          if (r.data.code === 0) {
            this.tableData = r.data.rows;
            this.pageNum = 1;
            this.totalSize = r.data.total;
          }
          this.tableLoading = false;
        });
    },

    // table event
    tableSizeChange(e) {
      this.pageNum = 1;
      this.pageSize = e;
      this.getTableData();
    },

    tableCrtPageChange(page) {
      this.pageNum = page;
      this.getTableData();
    },

    rowEdit(index, row) {
      console.log(row);
      let d = {
        nickname: row.nickname,
        phone: row.phone,
        city: [
          row.cityCode.substring(0, 2).padEnd(6, "0"),
          row.cityCode.padEnd(6, "0"),
        ],
        community: row.communityId,
        id: row.id,
      };
      this.editDialog = d;

      // 获取当前城市社区
      let params = {
        cityCode: row.cityCode,
      };

      this.axios
        .get("community/getCommunityListByCity", { params })
        .then((r) => {
          console.log(r.data);
          this.communitylist = r.data.data;
          this.editDialogShow = true;
        });
    },

    rowDelete(index, row) {
      this.$alert(`删除用户${row.nickname}，确定吗？`, {
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        callback: (action) => {
          if (action === "confirm") {
            this.axios
              .post("user/deleteUser", {
                id: row.id,
              })
              .then((r) => {
                this.$message.success("删除成功");
                this.getTableData();
              });
          } else {
          }
        },
      });
    },

    getTableData() {
      let opts = this.queryBar;
      console.log(opts);
      let params = {
        adminUserId: this.user.id,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        id: opts.id,
        nickname: opts.name,
        phone: opts.phone,
        cityCode: opts.city ? opts.city[1].substring(0, 4) : null,
        from: opts.refer,
        minRegistereTime: opts.createtime ? opts.createtime[0].getTime() : null,
        maxRegistereTime: opts.createtime ? opts.createtime[1].getTime() : null,
      };

      this.tableLoading = true;
      this.axios
        .get("user/getUserList", {
          params,
        })
        .then((r) => {
          console.log(r.data);
          if (r.data.code === 0) {
            this.tableData = r.data.rows;
            this.totalSize = r.data.total;
          }
          this.tableLoading = false;
        });
    },

    // dialog
    submitEdit() {
      let d = this.editDialog;
      console.log(d);
      this.axios
        .post("user/updateUserInfo", {
          nickname: d.nickname,
          communityId: d.community,
          id: d.id,
          cityCode: d.city[1].substring(0, 4),
        })
        .then((r) => {
          console.log(r.data);
          if (r.data.code === 0) {
            this.getTableData();
            this.$message.success("修改成功");
            this.editDialogShow = false;
          }
        });
    },
  },

  created() {
    this.initCityList();
    // this.getCommunity();
    this.getTableData();
  },

  mounted() {},

  destroyed() {},
};
</script>

<style scoped>
.container {
  width: 100%;
  position: relative;
  background-color: #fff;
  padding: 20px;
}

.table-query-bar {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.table-query-bar .el-input,
.table-query-bar .el-select,
.table-query-bar .el-cascader {
  width: 140px;
  margin-bottom: 20px;
  margin-right: 20px;
}

.e-date-wrap {
  margin-bottom: 20px;
  margin-right: 20px;
}

.e-date-label {
  font-size: 14px;
  color: #00567e;
  margin-right: 10px;
}

.query-btn-wrap {
  margin-bottom: 20px;
}

/* table */
.table-wrap {
  max-width: 100%;
}

.table-pagination {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 30px 0;
}

/* dialog */
.dialog {
  padding: 0 30px;
}

.dialog-body .el-input,
.dialog-body .el-select,
.dialog-body .el-cascader {
  width: 100%;
}
</style>
