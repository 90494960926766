<template>
	<div
		class="container"
		v-loading="tableLoading"
		element-loading-text="请稍等"
		element-loading-background="rgba(0, 0, 0, 0.5)"
	>
		<!-- query bar -->
		<div class="table-query-bar">
			<el-input
				v-model="queryBar.communitynum"
				placeholder="请输入小区编号"
				size="mini"
			></el-input>
			<el-input
				v-model="queryBar.communityname"
				placeholder="请输入小区名称"
				size="mini"
			></el-input>
			<el-cascader
				v-model="queryBar.city"
				:options="citylist"
				placeholder="请选择省市"
				size="mini"
				@change="crtCityChange"
				:props="{ expandTrigger: 'hover' }"
			></el-cascader>
			<el-select
				v-model="queryBar.serviceStatus"
				clearable
				placeholder="请选择服务状态"
				size="mini"
			>
				<el-option label="正常" :value="1"></el-option>
				<el-option label="失效" :value="0"></el-option>
			</el-select>
			<div class="e-date-wrap">
				<span class="e-date-label">服务有效期</span>
				<el-date-picker
					v-model="queryBar.servicetime"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					size="mini"
					@change="servicetimeChange"
				></el-date-picker>
			</div>
			<div class="query-btn-wrap">
				<el-button type="primary" size="mini" @click="query"
					>查询</el-button
				>
				<el-button size="mini" @click="clearQuery">重置</el-button>
				<el-button
					type="primary"
					size="mini"
					@click="createDialogShow = true"
					>创建小区</el-button
				>
			</div>
		</div>

		<!-- table -->
		<div class="table-wrap">
			<el-table :data="tableData" border style="width: 99%">
				<el-table-column
					prop="id"
					label="小区编号"
					width="80"
				></el-table-column>
				<el-table-column
					prop="name"
					label="小区名称"
					width="80"
				></el-table-column>
				<el-table-column prop="provinceName" label="所在省" width="80">
					<template slot-scope="scope">
						<span>{{
							scope.row.provinceName
								? scope.row.provinceName
								: "直辖市"
						}}</span>
					</template>
				</el-table-column>
				<el-table-column
					prop="cityName"
					label="所在市"
					width="80"
				></el-table-column>
				<el-table-column label="服务状态" width="80">
					<template slot-scope="scope">
						<el-tag type="success" v-if="scope.row.isEnable == 1">
							正常
						</el-tag>
						<el-tag type="danger" v-if="scope.row.isEnable == 0">
							失效
						</el-tag>
					</template>
				</el-table-column>
				<el-table-column label="服务有效期末">
					<template slot-scope="scope">
						<span>{{
							scope.row.serviceExpireTime | timeformat
						}}</span>
					</template>
				</el-table-column>
				<el-table-column label="创建时间">
					<template slot-scope="scope">
						<span>{{ scope.row.createTime | timeformat }}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button
							size="mini"
							@click="rowEdit(scope.$index, scope.row)"
						>
							编辑
						</el-button>
						<el-button
							size="mini"
							type="danger"
							@click="rowDelete(scope.$index, scope.row)"
						>
							删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="table-pagination">
			<el-pagination
				@size-change="tableSizeChange"
				@current-change="tableCrtPageChange"
				:current-page="pageNum"
				:page-sizes="[10, 20, 30, 40]"
				:page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="totalSize"
			></el-pagination>
		</div>

		<!-- edit dialog -->
		<el-dialog title="编辑小区" :visible.sync="eidtDialogShow" width="30%">
			<div class="dialog-body">
				<el-form label-width="100px" :model="editDialog">
					<el-form-item label="小区名称">
						<el-input v-model="editDialog.name"></el-input>
					</el-form-item>
					<el-form-item label="所在城市">
						<el-cascader
							v-model="editDialog.city"
							ref="editCity"
							:options="citylist"
							placeholder="请选择省市"
							@change="editDialogCityChange"
							:props="{ expandTrigger: 'hover' }"
						></el-cascader>
					</el-form-item>
					<el-form-item label="服务有效期至">
						<el-date-picker
							v-model="editDialog.serviceExpireTime"
							type="datetime"
							placeholder="选择日期时间"
						>
						</el-date-picker>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="eidtDialogShow = false">取消</el-button>
				<el-button type="primary" @click="submitEdit">确定</el-button>
			</span>
		</el-dialog>

		<!-- create dialog -->
		<el-dialog
			title="创建小区"
			:visible.sync="createDialogShow"
			width="30%"
		>
			<div class="dialog-body">
				<el-form label-width="100px" :model="createDialog">
					<el-form-item label="小区名称">
						<el-input v-model="createDialog.name"></el-input>
					</el-form-item>
					<el-form-item label="所在城市">
						<el-cascader
							v-model="createDialog.city"
							:options="citylist"
							ref="createCity"
							placeholder="请选择省市"
							:props="{ expandTrigger: 'hover' }"
						></el-cascader>
					</el-form-item>
					<el-form-item label="服务有效期至">
						<el-date-picker
							v-model="createDialog.serviceExpireTime"
							type="datetime"
							placeholder="选择日期时间"
						>
						</el-date-picker>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="createDialogShow = false">取消</el-button>
				<el-button type="primary" @click="submitCreate">确定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import areas from "../../assets/area";

export default {
	props: {},

	data() {
		return {
			tableLoading: false,
			eidtDialogShow: false,
			createDialogShow: false,
			user: getItem("user"),

			// shortcuts
			pickerOptions: {
				shortcuts: [
					{
						text: "最近一周",
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(
								start.getTime() - 3600 * 1000 * 24 * 7
							);
							picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近一个月",
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(
								start.getTime() - 3600 * 1000 * 24 * 30
							);
							picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近三个月",
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(
								start.getTime() - 3600 * 1000 * 24 * 90
							);
							picker.$emit("pick", [start, end]);
						},
					},
				],
			},

			// table
			tableData: [
				{
					num: 1022,
					name: "阳光小区",
					province: "北京",
					city: "北京",
					status: 0,
					endtime: "2020-12-20 23:59:59",
					createtime: "2020-12-20 23:59:59",
				},
				{
					num: 1022,
					name: "阳光小区",
					province: "北京",
					city: "北京",
					status: 1,
					endtime: "2020-12-20 23:59:59",
					createtime: "2020-12-20 23:59:59",
				},
			],

			// query preset
			editDialog: {
				name: null,
				city: null,
				serviceExpireTime: null,
			},

			createDialog: {
				name: null,
				city: null,
				serviceExpireTime: null,
			},

			citylist: [],

			// query bar
			queryBar: {
				communitynum: "",
				communityname: "",
				city: "",
				serviceStatus: "",
				servicetime: "",
			},

			// pagination
			pageNum: 1,
			pageSize: 10,
			totalSize: 0,
		};
	},

	computed: {},

	methods: {
		// query bar
		initCityList() {
			let area = [];
			for (let [key, value] of Object.entries(areas.province_list)) {
				let child = [];

				area.push({
					value: key,
					label: value,
					children: child,
				});

				for (let [innerKey, innerValue] of Object.entries(
					areas.city_list
				)) {
					if (innerKey.substring(0, 2) === key.substring(0, 2)) {
						child.push({
							value: innerKey,
							label: innerValue,
						});
					}
				}
			}
			this.citylist = area;
		},

		crtCityChange(e) {
			console.log(e);
		},

		servicetimeChange(e) {
			console.log(e);
		},

		clearQuery() {
			for (let key of Object.keys(this.queryBar)) {
				this.queryBar[key] = null;
			}
		},

		query() {
			let opts = this.queryBar;
			console.log(opts);

			let params = {
				adminUserId: this.user.id,
				pageNum: 1,
				pageSize: this.pageSize,
				id: opts.communitynum,
				name: opts.communityname,
				cityCode: opts.city ? opts.city[1].substring(0, 4) : null,
				isEnable: opts.serviceStatus,
				minServiceTime: opts.servicetime
					? opts.servicetime[0].getTime()
					: null,
				maxServiceTime: opts.servicetime
					? opts.servicetime[1].getTime()
					: null,
			};
			console.log("query", params);

			this.tableLoading = true;
			this.axios
				.get("community/getCommunityList", {
					params,
				})
				.then((r) => {
					console.log(r.data);
					if (r.data.code === 0) {
						this.tableData = r.data.rows;
						this.pageNum = 1;
						this.totalSize = r.data.total;
					}
					this.tableLoading = false;
				});
		},

		// table
		// table event
		tableSizeChange(e) {
			this.pageNum = 1;
			this.pageSize = e;
			this.getTableData();
		},

		tableCrtPageChange(page) {
			this.pageNum = page;
			this.getTableData();
		},

		rowEdit(index, row) {
			console.log(row);
			let d = {
				id: row.id,
				name: row.name,
				city: [
					String(row.cityCode)
						.substring(0, 2)
						.padEnd(6, "0"),
					String(row.cityCode).padEnd(6, "0"),
				],
				serviceExpireTime: new Date(row.serviceExpireTime),
			};
			this.editDialog = d;
			this.eidtDialogShow = true;
		},

		rowDelete(index, row) {
			this.$alert(`删除${row.name}，确定吗？`, {
				showCancelButton: true,
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				callback: (action) => {
					if (action === "confirm") {
						this.axios
							.post("community/delCommunity", {
								id: row.id,
							})
							.then((r) => {
								this.$message.success("删除成功");
								this.getTableData();
							});
					} else {
					}
				},
			});
		},

		createCommunity() {},

		getTableData() {
			let opts = this.queryBar;
			console.log(opts);

			let params = {
				adminUserId: this.user.id,
				pageNum: this.pageNum,
				pageSize: this.pageSize,
				id: opts.communitynum,
				name: opts.communityname,
				cityCode: opts.city ? opts.city[1].substring(0, 4) : null,
				isEnable: opts.serviceStatus,
				minServiceTime: opts.servicetime
					? opts.servicetime[0].getTime
					: null,
				maxServiceTime: opts.servicetime
					? opts.servicetime[1].getTime
					: null,
			};
			console.log("query", params);

			this.tableLoading = true;
			this.axios
				.get("community/getCommunityList", {
					params,
				})
				.then((r) => {
					console.log(r.data);
					if (r.data.code === 0) {
						this.tableData = r.data.rows;
						this.totalSize = r.data.total;
					}
					this.tableLoading = false;
				});
		},

		submitEdit() {
			let d = this.editDialog;
			let provinceName = this.$refs.editCity.getCheckedNodes()[0]
				.pathLabels[0];
			let cityName = this.$refs.editCity.getCheckedNodes()[0]
				.pathLabels[1];
			console.log(provinceName, cityName, d);
			this.axios
				.post("community/editCommunity", {
					id: d.id,
					name: d.name,
					cityCode: d.city[1].substring(0, 4),
					serviceExpireTime: d.serviceExpireTime.getTime(),
					provinceName,
					cityName,
				})
				.then((r) => {
					console.log(r.data);
					if (r.data.code === 0) {
						this.getTableData();
						this.$message.success("修改成功");
						this.eidtDialogShow = false;
					}
				});
		},

		submitCreate() {
			let e = this.createDialog;
			let provinceName = this.$refs.createCity.getCheckedNodes()[0]
				.pathLabels[0];
			let cityName = this.$refs.createCity.getCheckedNodes()[0]
				.pathLabels[1];
			this.axios
				.post("community/insertCommunity", {
					name: e.name,
					cityCode: e.city[1].substring(0, 4),
					serviceExpireTime: e.serviceExpireTime.getTime(),
					provinceName,
					cityName,
				})
				.then((r) => {
					console.log(r.data);
					if (r.data.code === 0) {
						this.getTableData();
						this.$message.success("创建小区成功");
						this.createDialogShow = false;
					}
				});
		},

		editDialogCityChange(e) {
			console.log(e);
		},
	},

	created() {
		this.getTableData();
		this.initCityList();
	},

	mounted() {},

	destroyed() {},
};
</script>

<style scoped>
.container {
	width: 100%;
	position: relative;
	background-color: #fff;
	padding: 20px;
}

.table-query-bar {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.table-query-bar .el-input,
.table-query-bar .el-select,
.table-query-bar .el-cascader {
	width: 140px;
	margin-bottom: 20px;
	margin-right: 20px;
}

.e-date-wrap {
	margin-bottom: 20px;
	margin-right: 20px;
}

.e-date-label {
	font-size: 14px;
	color: #00567e;
	margin-right: 10px;
}

.query-btn-wrap {
	margin-bottom: 20px;
}

/* table */
.table-wrap {
	max-width: 100%;
}

.table-pagination {
	display: flex;
	justify-content: center;
	width: 100%;
	padding: 30px 0;
}

/* dialog */
.dialog-body {
	padding: 0 30px;
}

.dialog-body .el-input,
.dialog-body .el-cascader {
	width: 100%;
}
</style>
