<template>
	<div
		class="container"
		v-loading="tableLoading"
		element-loading-text="请稍等"
		element-loading-background="rgba(0, 0, 0, 0.5)"
	>
		<!-- query bar -->
		<div class="table-query-bar">
			<el-input
				v-model="queryBar.powernum"
				placeholder="请输入权限编号"
				size="mini"
			></el-input>
			<el-input
				v-model="queryBar.powername"
				placeholder="请输入权限名称"
				size="mini"
			></el-input>
			<div class="query-btn-wrap">
				<el-button type="primary" size="mini" @click="query"
					>查询</el-button
				>
				<el-button size="mini" @click="clearQuery">重置</el-button>
				<el-button
					type="primary"
					size="mini"
					@click="createDialogShow = true"
					>创建权限</el-button
				>
			</div>
		</div>

		<!-- table -->
		<div class="table-wrap">
			<el-table :data="tableData" border style="width: 99%">
				<el-table-column
					prop="id"
					label="权限编号"
					width="80"
				></el-table-column>
				<el-table-column
					prop="nameZh"
					label="权限名称"
				></el-table-column>
				<el-table-column label="创建时间">
					<template slot-scope="s">
						<span>{{ s.row.createTime | timeformat }}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button
							size="mini"
							@click="rowEdit(scope.$index, scope.row)"
						>
							编辑
						</el-button>
						<el-button
							size="mini"
							type="danger"
							@click="rowDelete(scope.$index, scope.row)"
						>
							删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="table-pagination">
			<el-pagination
				@size-change="tableSizeChange"
				@current-change="tableCrtPageChange"
				:current-page="pageNum"
				:page-sizes="[10, 20, 30, 40]"
				:page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="totalSize"
			></el-pagination>
		</div>

		<!-- edit -->
		<el-dialog title="编辑权限" :visible.sync="editDialogShow" width="30%">
			<div class="dialog-body">
				<el-form label-width="100px" :model="editDialog">
					<el-form-item label="权限名称">
						<el-input v-model="editDialog.name"></el-input>
					</el-form-item>
					<el-form-item label="菜单权限">
						<el-select
							v-model="editDialog.menulist"
							multiple
							placeholder="请选择菜单权限"
						>
							<el-option
								v-for="item of menulist"
								:key="item.id"
								:label="item.name"
								:value="item.id"
							>
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="小区权限">
						<el-select
							v-model="editDialog.communitylist"
							multiple filterable
							placeholder="请选择小区"
						>
							<el-option
								v-for="item of communitylist"
								:key="item.id"
								:label="item.name"
								:value="item.id"
							></el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="editDialogShow = false">取消</el-button>
				<el-button type="primary" @click="submitEdit">确定</el-button>
			</span>
		</el-dialog>

		<!-- create -->
		<el-dialog
			title="创建权限"
			:visible.sync="createDialogShow"
			width="30%"
		>
			<div class="dialog-body">
				<el-form label-width="100px" :model="createDialog">
					<el-form-item label="权限名称">
						<el-input v-model="createDialog.name"></el-input>
					</el-form-item>
					<el-form-item label="菜单权限">
						<el-select
							v-model="createDialog.menulist"
							multiple
							placeholder="请选择菜单权限"
						>
							<el-option
								v-for="item of menulist"
								:key="item.id"
								:label="item.name"
								:value="item.id"
							>
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="小区权限">
						<el-select
							v-model="createDialog.communitylist"
							multiple filterable
							placeholder="请选择小区"
						>
							<el-option
								v-for="item of communitylist"
								:key="item.id"
								:label="item.name"
								:value="item.id"
							></el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="createDialogShow = false">取消</el-button>
				<el-button type="primary" @click="submitCreate">确定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
export default {
	props: {},

	data() {
		return {
			user: getItem("user"),
			tableLoading: false,
			editDialogShow: false,
			createDialogShow: false,

			// table
			tableData: [],
			communitylist: [],
			menulist: [],

			// query bar
			queryBar: {
				powernum: null,
				powername: null,
			},

			// dialog
			editDialog: {
				id: null,
				name: null,
				menulist: null,
				communitylist: null,
			},
			createDialog: {
				name: null,
				menulist: null,
				communitylist: null,
			},

			// pagination
			pageNum: 1,
			pageSize: 10,
			totalSize: 0,
		};
	},

	computed: {},

	methods: {
		// query bar
		query() {
			let d = this.queryBar;

			this.tableLoading = true;
			this.axios
				.get("role/getRoles", {
					params: {
						adminUserId: this.user.id,
						pageSize: this.pageSize,
						pageNum: 1,
						nameZh: d.powername,
						id: d.powernum,
					},
				})
				.then((r) => {
					if (r.data.code === 0) {
						this.pageNum = 1;
						this.totalSize = r.data.data.total;
						this.tableData = r.data.data.list;
					}
					this.tableLoading = false;
				});
		},

		clearQuery() {
			for (let key of Object.keys(this.queryBar)) {
				this.queryBar[key] = null;
			}
		},

		// table
		// table event
		tableSizeChange(e) {
			this.pageNum = 1;
			this.pageSize = e;
			this.getTableData();
		},

		tableCrtPageChange(page) {
			this.pageNum = page;
			this.getTableData();
		},

		rowEdit(index, row) {
			let menulist = [],
				communitylist = [];
			row.communityRoles.forEach((value, index) => {
				communitylist.push(value.id);
			});

			row.menuRoles.forEach((value, index) => {
				menulist.push(value.id);
			});

			this.editDialog = {
				id: row.id,
				name: row.nameZh,
				menulist,
				communitylist,
			};

			this.editDialogShow = true;
		},

		rowDelete(index, row) {
			this.$alert(`删除权限：${row.nameZh}编号：${row.id}，确定吗？`, {
				showCancelButton: true,
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				callback: (action) => {
					if (action === "confirm") {
						this.axios
							.post("role/deleteRole", {
								id: row.id,
							})
							.then((r) => {
								if (r.data.code === 0) {
									this.$message.success("删除成功");
									this.getTableData();
								}
							});
					} else {
					}
				},
			});
		},

		submitEdit() {
			let d = this.editDialog;
			this.axios
				.post("role/updateRole", {
					communityRolesId: d.communitylist,
					menuRolesId: d.menulist,
					nameZh: d.name,
					name: "ROLE_" + d.name,
					id: d.id,
				})
				.then((r) => {
					if (r.data.code === 0) {
						this.getTableData();
						this.editDialogShow = false;
						this.$message.success("修改已保存");
					}
				});
		},

		submitCreate() {
            let d = this.createDialog;
			this.axios
				.post("role/addRole", {
					communityRolesId: d.communitylist,
					menuRolesId: d.menulist,
					nameZh: d.name,
					name: "ROLE_" + d.name,
				})
				.then((r) => {
					if (r.data.code === 0) {
						this.getTableData();
						this.createDialogShow = false;
						this.$message.success("新增权限成功");
					}
				});
		},

		getTableData() {
			let d = this.queryBar;

			this.tableLoading = true;
			this.axios
				.get("role/getRoles", {
					params: {
						adminUserId: this.user.id,
						pageSize: this.pageSize,
						pageNum: this.pageNum,
						nameZh: d.powername,
						id: d.powernum,
					},
				})
				.then((r) => {
					if (r.data.code === 0) {
						this.totalSize = r.data.data.total;
						this.tableData = r.data.data.list;
					}
					this.tableLoading = false;
				});
		},

		getCommunity() {
			this.axios.get("community/getCommunities").then((r) => {
				this.communitylist = r.data.data;
			});
		},

		getMenulist() {
			this.axios.get("role/getMenus").then((r) => {
				this.menulist = r.data.data;
			});
		},
	},

	created() {
		this.getTableData();
		this.getCommunity();
		this.getMenulist();
	},

	mounted() {},

	destroyed() {},
};
</script>

<style scoped>
.container {
	width: 100%;
	position: relative;
	background-color: #fff;
	padding: 20px;
}

.table-query-bar {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.table-query-bar .el-input,
.table-query-bar .el-select,
.table-query-bar .el-cascader {
	width: 140px;
	margin-bottom: 20px;
	margin-right: 20px;
}

.e-date-wrap {
	margin-bottom: 20px;
	margin-right: 20px;
}

.e-date-label {
	font-size: 14px;
	color: #00567e;
	margin-right: 10px;
}

.query-btn-wrap {
	margin-bottom: 20px;
}

/* table */
.table-wrap {
	max-width: 100%;
}

.table-pagination {
	display: flex;
	justify-content: center;
	width: 100%;
	padding: 30px 0;
}

/* dialog */
.dialog-body {
	padding: 0 30px;
}

.dialog-body .el-input,
.dialog-body .el-select {
	width: 100%;
}
</style>
